import {
    DurationCalculatorDto,
    EndDateCalculatorDto,
} from 'components/PeepsView/GigPlanningCalculator/GigPlanningCalculator'
import { apiRoutes } from './apiRoutes'
import { msalRequest } from './authService'

interface FetchEndDateResponse {
    endDate: string
    skippedDays: string[]
    message: string
}

interface FetchDurationResponse {
    duration: number
    skippedDays: string[]
    message: string
}

export const fetchGigEndDate = async (
    request: EndDateCalculatorDto
): Promise<FetchEndDateResponse> => {
    const url =
        apiRoutes.getGigEndDate +
        `?startDate=${request.startDate}&durationInHours=${request.durationInHours}&userEmail=${request.userEmail}&includePartialDays=${request.includePartialDays}`
    const data = await msalRequest({ url })
    return data
}

export const fetchGigDuration = async (
    request: DurationCalculatorDto
): Promise<FetchDurationResponse> => {
    const url =
        apiRoutes.getGigDuration +
        `?startDate=${request.startDate}&endDate=${request.endDate}&userEmail=${request.userEmail}&includePartialDays=${request.includePartialDays}`
    const data = await msalRequest({ url })
    return data
}
