import { fetchBookableConsultants } from 'domain/budgetService'
import { BookableConsultant } from 'domain/models'
import { useEffect, useState } from 'react'
import styles from './ProjectBudgetEstimator.module.scss'
import { ProjectBudgetForm } from './ProjectBudgetForm/ProjectBudgetForm'
import { ProjectBudgetTable } from './ProjectBudgetTable/ProjectBudgetTable'

export interface ConsultantRow {
    name: string
    email: string
    rate: number
    startDate: Date
    endDate: Date
    totalCost: number
    skippedDays: string[]
    skippedEngagements: string[]
    message: string
    itemKey: string
}

export const ProjectBudgetEstimator = () => {
    const [consultantRows, setConsultantRows] = useState<ConsultantRow[]>([])
    const totalCosts = consultantRows.map((c) => c.totalCost).reduce((b, a) => b + a, 0)

    const [bookableConsultants, setBookableConsultants] = useState<BookableConsultant[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true)

        fetchBookableConsultants()
            .then(bookableConsultants => setBookableConsultants(bookableConsultants))
            .catch((e) => {
                console.error(e)
                setIsError(true)
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h4>Project Budget Estimator</h4>
            </div>
            {!isLoading ? (
                <div className={styles.projectBudgetForecaster}>
                <ProjectBudgetForm totalCosts={totalCosts} />
                    <div className={styles.consultants}>
                        <ProjectBudgetTable
                            consultantRows={consultantRows}
                            bookableConsultants={bookableConsultants}
                            setConsultantRows={setConsultantRows}
                        />
                    </div>
                </div>
            ) : (
                <i className='fa fa-spinner fa-pulse fa-fw fa-2x'></i>
            )}
            {isError && <p>An unknown error has occurred. Please contact your administrator.</p>}
        </div>
    )
}
