import * as H from 'history'
import queryString, { ParseOptions, StringifyOptions } from 'query-string'
import { getVisibleResourceGroups } from './filterHelpers'
import {
    AppStore,
    FilterState,
    UrlQueryFilterState,
    ResourceGroup,
    Squad,
    ViewType,
    defaultFilterState,
} from './models'

export const pushFiltersIntoUrl = (
    filters: FilterState,
    store: AppStore,
    history: H.History
): void => {
    const filterQuery: Partial<UrlQueryFilterState> = {
        viewType: replaceSpaceChar(filters.viewType) as ViewType,
        squads: allSquadsAreSelected(filters, store)
            ? [allValue]
            : filters.squads.map(replaceSpaceChar),
        resourceGroups: allResourceGroupsAreSelected(filters, store)
            ? [allValue]
            : filters.resourceGroups.map(replaceSpaceChar),
        showNonBillable: filters.showNonBillable || undefined,
        showTerminated: filters.showTerminated || undefined,
    }

    const filteredFilterQuery = filterUndefinedValues<Partial<UrlQueryFilterState>>(filterQuery)
    const query = queryString.stringify(filteredFilterQuery, queryStringStringifyOptions)
    history.push({ pathname: history.location.pathname, search: `?${query}` })
}

export const pullFiltersFromUrl = (
    filters: FilterState,
    store: AppStore,
    history: H.History
): Partial<UrlQueryFilterState> => {
    const query = queryString.parse(history.location.search, queryStringParseOptions)
    const allSquadNames = store.squads.map(extractName)
    const squadsQuery = normalizeStringArray(query.squads)

    const squads =
        query.squads === allValue
            ? allSquadNames
            : !!squadsQuery
            ? squadsQuery.map(replaceSpaceReplacementChar).filter((s) => allSquadNames.includes(s))
            : filters.squads

    const allVisibleResourceGroupNames = getVisibleResourceGroups(store, {
        ...filters,
        squads,
    }).map(extractName)

    const resourceGroupsQuery = normalizeStringArray(query.resourceGroups)

    const resourceGroups =
        query.resourceGroups === allValue
            ? allVisibleResourceGroupNames
            : !!resourceGroupsQuery
            ? resourceGroupsQuery
                  .map(replaceSpaceReplacementChar)
                  .filter((r) => allVisibleResourceGroupNames.includes(r))
            : filters.resourceGroups

    const viewTypeString = normalizeString(query.viewType)
    const viewType: ViewType | undefined = viewTypeString
        ? (replaceSpaceReplacementChar(viewTypeString) as ViewType)
        : undefined

    const filtersParsed: Partial<UrlQueryFilterState> = {
        viewType,
        showTerminated: !!query.showTerminated || defaultFilterState.showTerminated,
        showNonBillable: !!query.showNonBillable || defaultFilterState.showNonBillable,
        squads,
        resourceGroups,
    }

    const filteredFilters = filterUndefinedValues<Partial<UrlQueryFilterState>>(filtersParsed)
    return filteredFilters
}

const allValue = 'All'
const joinChar = '.'
const spaceChar = ' '
const spaceReplacementChar = '-'

const queryStringStringifyOptions: StringifyOptions = {
    arrayFormat: 'separator',
    arrayFormatSeparator: joinChar,
}

const queryStringParseOptions: ParseOptions = {
    arrayFormat: 'separator',
    arrayFormatSeparator: joinChar,
    parseBooleans: true,
}

const extractName = (obj: Squad | ResourceGroup): string => obj.name

const allSquadsAreSelected = (filters: FilterState, { squads }: AppStore): boolean =>
    filters.squads.length === squads.length

const allResourceGroupsAreSelected = (filters: FilterState, store: AppStore): boolean =>
    filters.resourceGroups.length === getVisibleResourceGroups(store, filters).length

const replaceSpaceChar = (s: string): string => s.split(spaceChar).join(spaceReplacementChar)

const replaceSpaceReplacementChar = (s: string): string =>
    s.split(spaceReplacementChar).join(spaceChar)

const filterUndefinedValues = <T>(obj: T) =>
    Object.keys(obj as any)
        .map((key) => key as keyof T)
        .filter((key) => obj[key] !== undefined)
        .reduce<Partial<T>>((acc, key) => {
            ;(acc[key] as any) = obj[key]
            return acc
        }, {})

const normalizeString = (string: string | (string | null)[] | null): string | undefined =>
    !!string ? (string as string) : undefined

const normalizeStringArray = (
    stringArray: string | (string | null)[] | null
): string[] | undefined =>
    !!stringArray && !Array.isArray(stringArray) && typeof stringArray === 'string'
        ? [stringArray] // queryString treats single value array as a string, so lets transform back to array
        : Array.isArray(stringArray)
        ? stringArray.filter((s) => !!s).map((s) => s as string)
        : undefined
