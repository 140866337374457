import { AppContext } from 'App'
import { HoverableTooltip } from 'components/Shared/HoverableTooltip/HoverableTooltip'
import { FilterState, isConsultant, PeepsCell, SideBarType, ViewType } from 'domain/models'
import { useContext } from 'react'
import { Popover } from 'react-bootstrap'
import { GigPlanningCalculator } from './GigPlanningCalculator/GigPlanningCalculator'
import styles from './PeepConsultantCell.module.scss'
import { PeepHeaderCell } from './PeepHeaderCell'

interface IPeepConsultantCell {
    peepsCell: PeepsCell
    filters: FilterState
}

export const PeepConsultantCell = (props: IPeepConsultantCell) => {
    const { uiState, setUiState } = useContext(AppContext)
    const consultant = props.peepsCell.cell

    // If the cell is not a consultant type, render as Project Header
    if (!isConsultant(consultant)) {
        return PeepHeaderCell(consultant)
    }

    const setUi = () => {
        setUiState({
            ...uiState,
            activeSideBar: uiState.activeSideBar ? null : { type: SideBarType.ConsultantInfoHelp },
        })
    }

    const openHelpLink = (
        <button className={styles.openHelpLink} onClick={() => setUi()}>
            more info {'>'}
        </button>
    )

    const isFullView = [ViewType.SoftBookings, ViewType.Full].includes(props.filters.viewType)
    const isProjectsGroupedView = props.filters.viewType === ViewType.ProjectsGrouped
    const showAttributes = isFullView || isProjectsGroupedView

    const consultantAttributes = (
        <div className={styles.consultantAttributes} title='Role'>
            {consultant.name && <div>{consultant.role}</div>}
            <div title='Squad'>{consultant.squad}</div>
            {!isProjectsGroupedView && (
                <>
                    {consultant.resourceGroup === 'Unassigned' && (
                        <div title='Booking role'>{consultant.bookingRole}</div>
                    )}
                    <div title='Resource group / capability'>{consultant.resourceGroup}</div>
                    {!consultant.isActive && <div>Inactive</div>}
                </>
            )}
        </div>
    )

    const consultantName = consultant.name ? (
        <GigPlanningCalculator linkText={consultant.name} userEmail={consultant.email} />
    ) : (
        <div className={styles.subtext}>{consultant.bookingRole}</div>
    )

    return (
        <div className={styles.container}>
            <div className={styles.consultantName}>
                {!showAttributes ? (
                    <HoverableTooltip
                        tool={consultantName}
                        tip={
                            <Popover>
                                <div className={styles.tip}>
                                    {consultantAttributes}
                                    {openHelpLink}
                                </div>
                            </Popover>
                        }
                    />
                ) : (
                    consultantName
                )}
            </div>
            {showAttributes && (
                <HoverableTooltip
                    tool={consultantAttributes}
                    tip={
                        <Popover>
                            <div className={styles.tip}>{openHelpLink}</div>
                        </Popover>
                    }
                />
            )}
        </div>
    )
}
