import { useMountEffect } from 'domain/hooks/useMountEffect'
import { defaultUiState, UiState } from 'domain/models'
import { recallUiState, rememberUiState } from 'domain/rememberService'
import { useState } from 'react'

export const useUiState = () => {
    const [uiState, _setUiState] = useState<UiState>(defaultUiState)

    // Wrap _setUiState with side effects
    const setUiState = (newUiState: UiState): void => {
        _setUiState(newUiState)
        rememberUiState(newUiState)
    }

    // Do once on mount - setup initial uiState
    useMountEffect(() => {
        _setUiState({
            ...uiState,
            ...recallUiState(),
        })
    })

    return { uiState, setUiState }
}
