import { dateFromDateKey } from 'domain/dateHelpers'
import { filterRows } from 'domain/filterRows'
import {
    AppStore,
    Consultant,
    EngagementEntry,
    FilterState,
    isConsultant,
    ProjectBudget,
} from 'domain/models'
import stringHash from 'string-hash'

let allConsultantsHashCache: number | null = null
export let allProjectSnapshotsCache: ProjectSnapshotWithBudget[] = []

export const makeProjectTableRows = (
    store: AppStore,
    filters: FilterState
): ProjectSnapshotWithBudget[] => {
    const hasAllProjectSnapshotsCache = allProjectSnapshotsCache.length > 0
    if (!hasAllProjectSnapshotsCache) return []

    const filteredConsultants = filterRows(store.consultants, filters)
    const filteredConsultantNames = filteredConsultants
        .filter((c) => isConsultant(c.cell))
        .map((c) => isConsultant(c.cell) && c.cell.name)
        .filter((name) => !!name)
    const filteredSquads = filters.squads

    const search = filters.search.toLowerCase()
    const isProjectSearch = search.startsWith('pr:')
    const isClientSearch = search.startsWith('cl:')
    const isSalesRepNameSearch = search.startsWith('s:')
    const isProjectCoordinatorNameSearch = search.startsWith('pc:')
    const isProgramManagerNameSearch = search.startsWith('pm:')

    const filteredProjects = allProjectSnapshotsCache
        .filter((project) =>
            project.consultants.reduce<boolean>(
                (acc, { name }) => (acc ? acc : filteredConsultantNames.includes(name)),
                false
            )
        )
        .filter(({ projectCoordinator, programManager, consultants }) =>
            [
                ...consultants.map(({ squad }) => squad),
                projectCoordinator.squad,
                programManager.squad,
            ].reduce<boolean>((acc, squad) => acc || filteredSquads.includes(squad), false)
        )
        .filter((project) => {
            if (isProjectSearch) {
                const searchProjectName = search.replace('pr:', '').trim()
                const projectName = project.projectName?.toLowerCase() || ''
                const isMatch = projectName.startsWith(searchProjectName)
                return isMatch
            } else {
                return true
            }
        })
        .filter((project) => {
            if (isClientSearch) {
                const searchClientName = search.replace('cl:', '').trim()
                const clientName = project.clientName?.toLowerCase() || ''
                const isMatch = clientName.startsWith(searchClientName)
                return isMatch
            } else {
                return true
            }
        })
        .filter((project) => {
            if (isSalesRepNameSearch) {
                const searchSalesRepName = search.replace('s:', '').trim()
                const salesRepName = project.salesRep.name?.toLowerCase() || ''
                const isMatch = salesRepName.startsWith(searchSalesRepName)
                return isMatch
            } else {
                return true
            }
        })
        .filter((project) => {
            if (isProjectCoordinatorNameSearch) {
                const searchProjectCoordinatorName = search.replace('pc:', '').trim()
                const projectCoordinatorName = project.projectCoordinator.name?.toLowerCase() || ''
                const isMatch = projectCoordinatorName.startsWith(searchProjectCoordinatorName)
                return isMatch
            } else {
                return true
            }
        })
        .filter((project) => {
            if (isProgramManagerNameSearch) {
                const searchProgramManagerName = search.replace('pm:', '').trim()
                const programManagerName = project.programManager.name?.toLowerCase() || ''
                const isMatch = programManagerName.startsWith(searchProgramManagerName)
                return isMatch
            } else {
                return true
            }
        })

    return filteredProjects
}

export const buildAllProjectSnapshotsCache = async (store: AppStore): Promise<void> => {
    const { consultants, projectBudgets } = store
    const allConsultantsHash = hashConsultants(consultants)
    const hasCache = !!allConsultantsHashCache && allConsultantsHashCache === allConsultantsHash
    allConsultantsHashCache = allConsultantsHash
    if (!hasCache) {
        allProjectSnapshotsCache = await uniqueProjectSnapshotsFromConsultants(
            consultants,
            projectBudgets
        )
    }
}

export interface ProjectSnapshotWithBudget extends ProjectSnapshot {
    hasBudget: boolean
    tcv: number
    timesheeted: number
    futureBookedFromSlipProjections: number
    remainingTcvFromSlipProjections: number
    futureBookedFromBookingsByDay: number
    remainingTcvFromBookingsByDay: number
    nonTimeSlips: number
    futureBookedDifference: number
}

const round = (num: number) => Math.round(num)
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
const difference = (a: number, b: number) => Math.abs(round(a) - round(b))

const hashConsultants = (consultants: Consultant[]): number =>
    stringHash(JSON.stringify(consultants))

export interface ProjectConsultantSnapshot {
    name: string
    squad: string
}

interface ProjectSnapshot {
    projectId: number
    projectName: string
    clientId: number
    clientName: string
    source: string
    consultants: ProjectConsultantSnapshot[]
    projectCoordinator: ProjectConsultantSnapshot
    programManager: ProjectConsultantSnapshot
    salesRep: ProjectConsultantSnapshot
    startDate: Date
    endDate: Date
}

const projectSnapshotFromEngagementEntry = (
    entry: EngagementEntry,
    consultantName: string,
    consultantSquad: string
): ProjectSnapshot => ({
    projectId: entry.projectId,
    projectName: entry.project,
    clientId: entry.clientId,
    clientName: entry.client,
    source: entry.source,
    consultants: [{ name: formatConsultantName(consultantName), squad: consultantSquad }],
    projectCoordinator: {
        name: formatConsultantName(entry.projectCoordinatorName),
        squad: entry.projectCoordinatorSquad,
    },
    programManager: {
        name: formatConsultantName(entry.programManagerName),
        squad: entry.programManagerSquad,
    },
    salesRep: {
        name: formatConsultantName(entry.salesRepName),
        squad: entry.salesRepSquad,
    },
    startDate: dateFromDateKey(entry.startDate),
    endDate: dateFromDateKey(entry.endDate),
})

const uniqueProjectSnapshotsFromConsultants = (
    consultants: Consultant[],
    projectBudgets: ProjectBudget[]
): Promise<ProjectSnapshotWithBudget[]> => {
    return new Promise((resolve, reject) => {
        // Extract all projects from consultants[n].engagements[date].entries
        const projects = consultants.reduce<ProjectSnapshot[]>((acc, consultant) => {
            const projects: ProjectSnapshot[] = Object.values(consultant.engagements)
                .filter(({ entries }) => entries && entries.length > 0)
                .reduce<ProjectSnapshot[]>((acc, engagement) => {
                    const projects: ProjectSnapshot[] = engagement.entries
                        .filter(
                            (entry: EngagementEntry) =>
                                !!entry.projectId && entry.projectId !== 4 && entry.projectId !== 6
                        ) // Filter out empty, "Internal" (4) and "Leave" (6)
                        .map((entry: EngagementEntry) =>
                            projectSnapshotFromEngagementEntry(
                                entry,
                                consultant.name || consultant.email,
                                consultant.squad
                            )
                        )
                    return acc.concat(projects)
                }, [])
            return acc.concat(projects)
        }, [])

        // `projects` will contain many duplicates, so lets de-duplicate...
        const uniqueProjects = deduplicateByProperty(projects, 'projectId')
        // Add a complete list of consultant names to each unique project...
        // Add startDate and endDate, from earliest startDate and latest endDate
        const uniqueProjectsWithConsultants = uniqueProjects.map((project) => {
            const matchingProjects = projects.filter((p) => p.projectId === project.projectId)

            const consultants = matchingProjects.reduce<ProjectConsultantSnapshot[]>(
                (acc, proj) => acc.concat(proj.consultants),
                []
            )

            const uniqueConsultants = deduplicateByProperty(consultants, 'name')
            const startDates = matchingProjects.map(({ startDate }) => +startDate)
            const earliestStartDate = Math.min(...startDates)

            const endDates = matchingProjects.map(({ endDate }) => +endDate)
            const latestEndDate = Math.max(...endDates)

            return {
                ...project,
                consultants: uniqueConsultants,
                startDate: new Date(earliestStartDate),
                endDate: new Date(latestEndDate),
            }
        })

        resolve(enrichProjectSnapshotsWithBudgets(uniqueProjectsWithConsultants, projectBudgets))
    })
}

const formatConsultantName = (name: string): string => {
    return name.includes(', ') ? name.split(', ').reverse().join(' ') : name
}

const enrichProjectSnapshotsWithBudgets = (
    projectSnapshots: ProjectSnapshot[],
    budgets: ProjectBudget[]
): ProjectSnapshotWithBudget[] => {
    const projectsWithBudgets: ProjectSnapshotWithBudget[] = projectSnapshots.map((project) => {
        const hasBudget = budgets.some(({ projectId }) => projectId === project.projectId)

        const budget: ProjectBudget = budgets.find(
            ({ projectId }) => projectId === project.projectId
        ) || {
            projectId: project.projectId,
            projectName: project.projectName,
            clientName: project.clientName,
            tcv: 0,
            timesheeted: 0,
            futureBookedFromSlipProjections: 0,
            remainingTcvFromSlipProjections: 0,
            futureBookedFromBookingsByDay: 0,
            remainingTcvFromBookingsByDay: 0,
            nonTimeSlips: 0,
            consultants: [],
        }

        const tcv = round(budget.tcv)
        const remainingTcvFromSlipProjections = round(budget.remainingTcvFromSlipProjections)
        const timesheeted = round(budget.timesheeted)
        const futureBookedFromSlipProjections = round(budget.futureBookedFromSlipProjections)
        const futureBookedFromBookingsByDay = round(budget.futureBookedFromBookingsByDay)
        const remainingTcvFromBookingsByDay = round(budget.remainingTcvFromBookingsByDay)
        const nonTimeSlips = round(budget.nonTimeSlips)

        const futureBookedDifference = difference(
            futureBookedFromSlipProjections,
            futureBookedFromBookingsByDay
        )

        const projectWithBudget: ProjectSnapshotWithBudget = {
            ...project,
            tcv,
            timesheeted,
            futureBookedFromSlipProjections,
            remainingTcvFromSlipProjections,
            futureBookedFromBookingsByDay,
            remainingTcvFromBookingsByDay,
            hasBudget,
            nonTimeSlips,
            futureBookedDifference,
        }
        return projectWithBudget
    })

    return projectsWithBudgets
}

function deduplicateByProperty<TModel>(items: TModel[], key: keyof TModel) {
    const values = items.map((i) => i[key])
    return items.filter((i, index) => !values.includes(i[key], index + 1))
}
