import classNames from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/types'
import styles from './InfoTooltip.module.scss'

interface InfoTooltipProps {
    tip: string
    placement?: Placement
    className?: string
}

export const InfoTooltip = ({ tip, placement, className }: InfoTooltipProps) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{tip}</Tooltip>}>
        <span className={classNames(styles.tool, className)}>
            <span>i</span>
        </span>
    </OverlayTrigger>
)
