import { App } from 'App'
import { SplashView } from 'components/SplashView/SplashView'
import { loggedInUser, loginError, signInUser } from 'domain/authService'
import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

export const AuthWrapper = () => {
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(true)

    useEffect(() => {
        signInUser().then(() => {
            setIsLoggingIn(false)
        })
    }, [])

    return isLoggingIn ? (
        <SplashView message={'Logging in...'} />
    ) : (
        <BrowserRouter>
            <App loggedInUser={loggedInUser} loginError={loginError} />
        </BrowserRouter>
    )
}
