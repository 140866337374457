import { AppContext } from 'App'
import { SideBarType, SourceToggleSource } from 'domain/models'
import { useContext } from 'react'
import { ColourBlindToggle } from './ColourBlindToggle/ColourBlindToggle'
import { SourceToggle } from './SourceToggle/SourceToggle'
import { ReleaseNotesToggle } from './ReleaseNotesToggle/ReleaseNotesToggle'

export const NavBarActions = () => {
    const { uiState, setUiState } = useContext(AppContext)
    return (
        <>
            <ColourBlindToggle
                isColourBlindMode={uiState.isColourBlindMode}
                toggleColourBlindMode={() =>
                    setUiState({
                        ...uiState,
                        isColourBlindMode: !uiState.isColourBlindMode,
                    })
                }
            />
            <SourceToggle
                showSource={uiState.showNetSuite}
                source={SourceToggleSource.NetSuite}
                toggleShowSource={() =>
                    setUiState({ ...uiState, showNetSuite: !uiState.showNetSuite })
                }
            />
            <ReleaseNotesToggle
                isReleaseNotesShown={uiState.showReleaseNotes}
                toggleShowReleaseNotes={() =>
                    setUiState({ ...uiState, 
                        showReleaseNotes: !uiState.showReleaseNotes,
                        activeSideBar: {
                            type: SideBarType.RelaseNotes,
                        }, })
                }
            />
        </>
    )
}
