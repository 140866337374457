import moment from 'moment'
import { ConsultantAuditRowId } from './models'

const dateIsoFormat = 'YYYY-MM-DD' // ISO 8601 date format as the global standard
const dateKeyFormat = 'D/M/YYYY' // Engagements payload uses this format for dateKeys (often, not always)

export const formatDateKey = (date: Date): string => moment(date).format('D/M/YYYY')

export const formatDateIso = (date: Date) => moment(date).format(dateIsoFormat)
export const formatOptionalDateIso = (date: Date | undefined) => date ? moment(date).format(dateIsoFormat) : ''

export const formatDateShort = (date: Date): string => moment(date).format('DD/MM/YY')

export const formatDateTimeForDto = (date: Date): string =>
    moment(date).format(dateIsoFormat) + 'T00:00:00'

export function dateFromIsoString(dateIsoString: string): Date
export function dateFromIsoString(dateIsoString: string | undefined): Date | undefined
export function dateFromIsoString(dateIsoString: string | undefined): Date | undefined
{
    if (!dateIsoString)
        return undefined
    
    return moment(dateIsoString, dateIsoFormat).toDate()
}

export const dateFromDateKey = (dateKey: string): Date => moment(dateKey, dateKeyFormat).toDate()

export function getTodayDate(): Date {
    // this can be changed to debug against historical data
    const today = new Date()
    return moment(today).format('dddd') === 'Saturday'
        ? moment(today).add(2, 'day').toDate()
        : moment(today).format('dddd') === 'Sunday'
            ? moment(today).add(1, 'day').toDate()
            : moment(today).toDate()
}

export const getPeepsDateRange = (): Date[] =>
    getWorkingDaysInRange(getPeepsStartDate(), getPeepsEndDate())

export const getPeepsStartDate = () => moment(getTodayDate()).subtract(1, 'month').startOf('month').toDate()
export const getPeepsEndDate = () => moment(getTodayDate()).add(6, 'month').endOf('month').toDate()

export const getWorkingDaysInRange = (startDate: Date, endDate: Date): Date[] => {
    const tzoffset = new Date().getTimezoneOffset() * 60000
    const list: Date[] = []
    let currentDate = startDate
    while (currentDate <= endDate) {
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            list.push(moment(currentDate).subtract(tzoffset).toDate())
        }
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return list
}

const getWorkingDaysInRangeWithoutTz = (startDate: Date, endDate: Date): Date[] => {
    const list: Date[] = []
    let currentDate = new Date(startDate)
    while (currentDate <= endDate) {
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
            list.push(moment(currentDate).toDate())
        }
        currentDate.setDate(currentDate.getDate() + 1)
    }
    return list
}

export const getWorkingConsultantAuditRowIdsInRange = (rowIds: ConsultantAuditRowId[]): ConsultantAuditRowId[] => {
    const min = rowIds[0]
    const max = rowIds[rowIds.length - 1]
    const allDateIds = getWorkingDaysInRangeWithoutTz(min.date, max.date).map<ConsultantAuditRowId>(date => ({ date: date, rowNumber: 1 }));

    const list: ConsultantAuditRowId[] = []
    let i = 0
    let j = 0
    while (i < rowIds.length && j < allDateIds.length) {
        if (rowIds[i].date < allDateIds[j].date) {
            list.push(rowIds[i])
            i++
        } else if (rowIds[i].date > allDateIds[j].date) {
            list.push(allDateIds[j])
            j++
        } else {
            list.push(rowIds[i])
            i++
            j++
        }
    }
    while (i < rowIds.length) {
        list.push(rowIds[i])
        i++
    }
    while (j < allDateIds.length) {
        list.push(allDateIds[j])
        j++
    }
    return list
}

export const getWeekCommencementDate = (date: Date) => {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    let result = new Date(date);
    result.setDate(diff)
    return result;
}
