import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import styles from './ProjectBudgetForm.module.scss'

interface IProjectBudgetFormProps {
    totalCosts: number
}

export interface IProjectForecasterFormState {
    projectName: string
    tcv: number
}

const initialProjectForecasterFormState: IProjectForecasterFormState = {
    projectName: '',
    tcv: 0,
}

export const ProjectBudgetForm = (props: IProjectBudgetFormProps) => {
    const [form, setForm] = useState<IProjectForecasterFormState>(initialProjectForecasterFormState)
    const [tcv, setTcv] = useState<number>(0)
    const handleProjectDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))
    }

    const remainingTcv = tcv - props.totalCosts

    return (
        <div className={styles.projectDetails}>
            <div className={styles.formGroup}>
                <label htmlFor='projectName'>Project Name</label>
                <input
                    type='text'
                    name='projectName'
                    placeholder='Project Name'
                    value={form.projectName}
                    onChange={handleProjectDetailsChange}
                    autoComplete='off'
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor='tcv'>TCV</label>
                <NumericFormat
                    name='rate'
                    thousandsGroupStyle='thousand'
                    value={tcv || ''}
                    placeholder={'$0'}
                    prefix='$'
                    decimalSeparator='.'
                    displayType='input'
                    type='text'
                    thousandSeparator={true}
                    allowNegative={false}
                    onValueChange={(values) => setTcv(values.floatValue || 0)}
                />
            </div>
            <div className={styles.formGroup}>
                <label htmlFor='remainingTcv'>Remaining TCV</label>
                <NumericFormat
                    className={`${styles.remainingTcv} ${
                        remainingTcv >= 0 ? styles.positive : styles.negative
                    }`}
                    thousandsGroupStyle='thousand'
                    value={remainingTcv}
                    prefix='$'
                    name='remainingTcv'
                    decimalSeparator='.'
                    placeholder='Remaining TCV'
                    displayType='input'
                    type='text'
                    disabled
                    decimalScale={2}
                    thousandSeparator={true}
                    allowNegative={true}
                />
            </div>
        </div>
    )
}
