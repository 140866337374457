import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const instrumentationKey =
    process.env.REACT_APP_APPINSIGHTS_INSTRUMENTION_KEY || '#{APPINSIGHTSINSTRUMENTATIONKEY}#'
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: instrumentationKey,
    },
})

appInsights.loadAppInsights()
appInsights.trackPageView()

export { appInsights }
