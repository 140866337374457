import { InfoGuide } from '../InfoGuide'
import styles from './NavBarColorGuide.module.scss'

export const NavBarColorGuide = () => (
    <InfoGuide label='Colours' width={480} height={120}>
        <div className={styles.guide}>
            <div className={styles.column}>
                <i className='fa fa-stop hard-booking-color'>
                    <span>Hard Booking</span>
                </i>
                <i className='fa fa-stop soft-booking-color'>
                    <span>Soft Booking</span>
                </i>
                <i className='fa fa-stop sales-color'>
                    <span>Sales</span>
                </i>
                <i className='fa fa-stop telstra-internal-color'>
                    <span>Telstra Internal</span>
                </i>
                <i className='fa fa-stop internal-team-project-color'>
                    <span>Internal Team Project</span>
                </i>
            </div>
            <div className={styles.column}>
                <i className='fa fa-stop planned-pd-color'>
                    <span>Planned PD</span>
                </i>
                <i className='fa fa-stop planned-unplanned-color'>
                    <span>Un-Planned PD/Heritage Readify Internal Client</span>
                </i>
                <i className='fa fa-stop public-holiday-color'>
                    <span>Public Holiday</span>
                </i>
                <i className='fa fa-stop leave-color'>
                    <span>Leave</span>
                </i>
                <i className='fa fa-stop netsuite-leave-color'>
                    <span>NetSuite Leave</span>
                </i>
            </div>
        </div>
    </InfoGuide>
)
