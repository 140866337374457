import { ProjectBudgetSeverity } from "./models"

export const getProjectBudgetSeverityIcon = (severity?: ProjectBudgetSeverity) => {
    let icon: string | undefined = undefined
    switch (severity) {
        case ProjectBudgetSeverity.Info:
            icon = "ℹ️"
            break
        case ProjectBudgetSeverity.Warning:
            icon = "⚠️"
            break
        case ProjectBudgetSeverity.Error:
            icon = "❌"
            break
        default:
            break
    }
    return icon
}
