import classNames from 'classnames'
import { msalDownloadRequest } from 'domain/authService'
import { ReactNode, useEffect, useRef, useState } from 'react'
import styles from './DownloadButton.module.scss'

interface ISecureDownloadProps {
    url: string
    children?: ReactNode
    disabled?: boolean
    className?: string
    onStatusChange?: (isDownloading: boolean) => void
}

export const DownloadButton = ({ ...props }: ISecureDownloadProps) => {
    const linkRef = useRef<HTMLAnchorElement>(null)
    const [disabled, setDisabled] = useState(false)
    const [isDownloading, setDownloading] = useState(false)

    useEffect(() => {
        setDisabled(!!props.disabled)
    }, [props.disabled])

    const getIt = async () => {
        if (disabled) return

        setDisabled(true)
        setDownloading(true)
        props.onStatusChange && props.onStatusChange(true)
        try {
            const { fileName, fileContent } = await msalDownloadRequest({ url: props.url })

            linkRef.current!.download = fileName || 'attachment'
            linkRef.current!.href = window.URL.createObjectURL(fileContent)
            linkRef.current!.click()
        } finally {
            setDisabled(false)
            setDownloading(false)
            props.onStatusChange && props.onStatusChange(false)
        }
    }

    return (
        <div className={classNames(styles.container, props.className)}>
            <button
                className={styles.downloadButton}
                type='button'
                onClick={getIt}
                disabled={disabled}
            >
                {isDownloading && (
                    <span>
                        <i className='fa fa-spinner fa-pulse fa-fw'></i>
                        &nbsp;Processing...
                    </span>
                )}
                {!isDownloading && props.children}
            </button>

            {/* NOTE: The <a/> is not a real link you can manually click. 
              It's a sneaky hidden link that is used to just download the blob... by programmatically clicking it...
              We need to do this so that the fileName is preserved. 
            */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a ref={linkRef} target='_blank'>
                {props.children}
            </a>
        </div>
    )
}
