import { AppContext, ShowSplashView } from 'App'
import classNames from 'classnames'
import { PageLayout } from 'components/Shared/PageLayout/PageLayout'
import { defaultProjectBudgetAuditResponse, fetchProjectBudgetAudit } from 'domain/budgetService'
import {
    dateFromIsoString,
    formatDateIso,
    formatDateShort,
    getWorkingConsultantAuditRowIdsInRange,
} from 'domain/dateHelpers'
import { useMountEffect } from 'domain/hooks/useMountEffect'
import {
    ConsultantAuditRowId,
    ProjectBudgetAuditConsultant,
    ProjectBudgetAuditConsultantRow,
    ProjectBudgetAuditResponse,
    ProjectBudgetRowStatus,
    ProjectBudgetSeverity,
    SideBarType,
} from 'domain/models'
import { useContext, useRef, useState, Fragment, useMemo, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import styles from './ProjectView.module.scss'
import { ProjectBudgetTotals } from './ProjectBudgetTotals/ProjectBudgetTotals'
import { ProjectBurnupExportPopover } from '../ProjectBudgetAuditView/ProjectBurnupExportPopover'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { getProjectBudgetSeverityIcon } from 'domain/auditHelper'

export const ProjectView = () => {
    const { uiState, setUiState } = useContext(AppContext)
    const { projectId: projectIdString } = useParams<{ projectId: string }>()
    const projectId = projectIdString ? parseInt(projectIdString) : undefined

    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [projectBudgetAudit, setProjectBudgetAudit] = useState<ProjectBudgetAuditResponse>(
        defaultProjectBudgetAuditResponse
    )

    useMountEffect(() => {
        if (!projectId) return

        fetchProjectBudgetAudit(projectId).then((projectBudgetAudit) => {
            setProjectBudgetAudit(projectBudgetAudit)
            setIsInitialized(true)
        })
    })

    // BUG #5985 - Browsers get Out Of Memory on large projects (in this function) but can't render
    // all the rows for some projects anyway. It'd probably be better to limit to a subset of
    // recent dates if Consultants x Dates > 100000.
    const dateArray = useMemo(
        () => makeDateArray(projectBudgetAudit.consultants),
        [projectBudgetAudit.consultants]
    )

    const ref = useRef<HTMLDivElement>(null)
    const onScroll = () => {
        if (!ref.current) return
        var classStringBuilder: string[] = ['container-fluid', styles.container]

        if (ref.current.scrollTop >= 80) {
            classStringBuilder.push(styles.removePaddingTop)
        }

        if (ref.current.scrollLeft >= 15) {
            classStringBuilder.push(styles.removePaddingLeft)
        }

        ref.current.className = classNames(classStringBuilder)
    }

    const projectStartDate = dateArray[0]?.date
    const projectEndDate = dateArray.slice(-1)?.pop()?.date

    const TableHeader = () => {
        return (
            <thead>
                <tr>
                    <>
                        <th className={classNames(styles.stickyDateColumn, styles.emptyField)}></th>
                        {projectBudgetAudit.consultants.map((consultantBudget, index) => {
                            return (
                                <th key={index} colSpan={4} align='center'>
                                    <h4>{consultantBudget.userName}</h4>
                                    <p className={styles.consultantRole}>
                                        {consultantBudget.bookingRole || <>&nbsp;</>}
                                    </p>
                                </th>
                            )
                        })}
                    </>
                </tr>

                <tr>
                    <th className={classNames(styles.stickyDateColumn, styles.emptyField)}>
                        <>&nbsp;</>
                    </th>
                    {projectBudgetAudit.consultants.map((_, index) => {
                        return (
                            <Fragment key={index}>
                                <th colSpan={2} className={classNames(styles.slips)}>
                                    Timesheeted
                                </th>
                                <th colSpan={2} className={classNames(styles.bookingsByDay)}>
                                    Bookings
                                </th>
                            </Fragment>
                        )
                    })}
                </tr>
                <tr>
                    <th className={styles.stickyDateColumn}>date</th>
                    {projectBudgetAudit.consultants.map((_, index) => {
                        return (
                            <Fragment key={index}>
                                <th className={classNames(styles.slips)}>hours</th>
                                <th className={classNames(styles.slips)}>total</th>
                                <th className={classNames(styles.bookingsByDay)}>hours</th>
                                <th className={classNames(styles.bookingsByDay)}>total</th>
                            </Fragment>
                        )
                    })}
                </tr>
            </thead>
        )
    }

    const severityToCellStyle = (severity?: ProjectBudgetSeverity) => {
        let style: string | undefined = undefined
        switch (severity) {
            case ProjectBudgetSeverity.Error:
                style = styles.errorCell
                break
            case ProjectBudgetSeverity.Warning:
                style = styles.warningCell
                break
            default:
                break
        }
        return style
    }

    const TableRow = ({ date, rowNumber }: { date: Date; rowNumber: number }) => {
        return (
            <tr>
                <>
                    <td className={classNames(styles.stickyDateColumn)}>
                        <CellValue
                            className={classNames(styles.cellValue, styles.cellValueWithSuffix)}
                        >
                            {formatDateShort(date)}
                            {rowNumber > 1 && <span>{` (${rowNumber})`}</span>}
                        </CellValue>
                    </td>
                    {projectBudgetAudit.consultants.map((consultantAudit, index) => {
                        const row = getConsultantAuditRowByDate(date, rowNumber, consultantAudit)

                        const isSlipGreyed = row?.slipStatus === ProjectBudgetRowStatus.Excluded
                        const isBookingGreyed =
                            row?.bookingStatus === ProjectBudgetRowStatus.Excluded

                        const tooltipForSlip = row?.slipStatusTooltip
                        const tooltipForBooking = row?.bookingStatusTooltip

                        const severityStylingForSlip = severityToCellStyle(row?.slipStatusIcon)
                        const severityStylingForBooking = severityToCellStyle(
                            row?.bookingStatusIcon
                        )

                        const slipClasses = [
                            severityStylingForSlip,
                            isSlipGreyed ? styles.isGreyed : styles.slips,
                        ]
                        const bookingClasses = [
                            severityStylingForBooking,
                            isBookingGreyed ? styles.isGreyed : styles.bookingsByDay,
                        ]

                        const tooltipIconForSlip = getProjectBudgetSeverityIcon(row?.slipStatusIcon)
                        const tooltipIconForBooking = getProjectBudgetSeverityIcon(
                            row?.bookingStatusIcon
                        )

                        return (
                            <Fragment key={index}>
                                {/* slips */}
                                <td className={classNames(slipClasses)}>
                                    <CellValue error={tooltipForSlip}>{row?.slipHours}</CellValue>
                                </td>
                                <td className={classNames([slipClasses, styles.bRight])}>
                                    <CellValue error={tooltipForSlip}>
                                        {row?.slipTotal && (
                                            <CurrencyFormat amount={row.slipTotal} />
                                        )}
                                        &nbsp;
                                        {tooltipForSlip && tooltipIconForSlip}
                                    </CellValue>
                                </td>
                                {/* bookingByDay */}
                                <td className={classNames(bookingClasses)}>
                                    <CellValue error={tooltipForBooking}>
                                        {row?.bookingHours}
                                    </CellValue>
                                </td>
                                <td className={classNames([bookingClasses, styles.bRight])}>
                                    <CellValue error={tooltipForBooking}>
                                        {row?.bookingTotal && (
                                            <CurrencyFormat amount={row?.bookingTotal} />
                                        )}
                                        &nbsp;
                                        {tooltipForBooking && tooltipIconForBooking}
                                    </CellValue>
                                </td>
                            </Fragment>
                        )
                    })}
                </>
            </tr>
        )
    }

    const TableBody = () => {
        return (
            <tbody>
                {dateArray.map((row, i) => {
                    return <TableRow key={i} date={row.date} rowNumber={row.rowNumber} />
                })}
            </tbody>
        )
    }

    const ConsultantBudgetTable = useCallback(() => {
        return (
            <table className={styles.table}>
                <TableHeader />
                <TableBody />
            </table>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectBudgetAudit.consultants])

    return (
        <ShowSplashView showSplashView={!isInitialized}>
            <PageLayout>
                {
                    <div
                        ref={ref}
                        className={classNames('container-fluid', styles.container)}
                        onScroll={onScroll}
                    >
                        {!isInitialized && <h2>Loading Project View...</h2>}
                        {isInitialized && (
                            <>
                                <h2>Project Overview: {projectBudgetAudit.projectName} </h2>
                                <button
                                    className={styles.openHelpLink}
                                    onClick={() =>
                                        setUiState({
                                            ...uiState,
                                            activeSideBar: { type: SideBarType.ProjectBudgetAuditHelp },
                                        })
                                    }
                                >
                                    How are budgets calculated?
                                </button>

                                <ProjectBudgetTotals
                                    projectBudgetAudit={projectBudgetAudit}
                                    openSidePanel={() =>
                                        setUiState({
                                            ...uiState,
                                            activeSideBar: {
                                                type: SideBarType.ProjectBudgetAdjustor,
                                                projectId,
                                            },
                                        })
                                    }
                                />
                                
                                <h5 className={styles.addMarginTop}>Tools</h5>
                                <p>
                                    <a
                                        className={styles.linkButton}
                                        href='http://updates.purple.tech'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Weekly Updates
                                    </a>
                                </p>

                                {!!projectStartDate && !!projectEndDate && (
                                    <ProjectBurnupExportPopover
                                        projectId={projectId!}
                                        projectStartDate={projectStartDate}
                                        projectEndDate={projectEndDate}
                                    />
                                )}

                                <h5 className={styles.addMarginTop}>Consultant Time Details
                                    &nbsp;
                                    <Link
                                        className={styles.linkButton}
                                        to={`/project/budget/audit/${projectId}`}
                                        target='_blank'
                                    >(Audit)
                                    </Link>
                                </h5>
                                <ConsultantBudgetTable />
                            </>
                        )}
                    </div>
                }
            </PageLayout>
        </ShowSplashView>
    )
}

const makeDateArray = (
    consultantAudits: Array<ProjectBudgetAuditConsultant>
): Array<ConsultantAuditRowId> => {
    const allRowIds = consultantAudits
        .flatMap((c) => c.rows)
        .map((row) => ({ date: row.date, rowNumber: row.rowNumber }))
        .map((i) => JSON.stringify(i))

    const uniqueRowIds = Array.from(new Set(allRowIds))
        .sort()
        .map((s) => JSON.parse(s))
        .map<ConsultantAuditRowId>((i) => ({
            date: dateFromIsoString(i.date),
            rowNumber: i.rowNumber,
        }))

    if (allRowIds.length <= 0) return []

    const rowIdsWithFullWeeks = getWorkingConsultantAuditRowIdsInRange(uniqueRowIds)
    return rowIdsWithFullWeeks
}

const getConsultantAuditRowByDate = (
    date: Date,
    rowNumber: number,
    consultantAudit: ProjectBudgetAuditConsultant
): ProjectBudgetAuditConsultantRow | undefined => {
    const dateIso = formatDateIso(date)
    return consultantAudit.rows.find((row) => row.date === dateIso && row.rowNumber === rowNumber)
}

const CellValue = ({
    children,
    error,
    className,
}: {
    children?: React.ReactNode
    error?: string
    className?: string | string[]
}) => {
    const errorArray = error?.trim().split('\n') ?? []

    const tooltip = (
        <Tooltip>
            <div className={errorArray.length > 1 ? styles.tooltipContainer : ''}>
                {errorArray.length > 1
                    ? errorArray?.map((text, index) => <li key={index}>{text}</li>)
                    : errorArray}
            </div>
        </Tooltip>
    )

    return error ? (
        <OverlayTrigger overlay={tooltip}>
            <div className={styles.cellValue}>{children || <>&nbsp;</>}</div>
        </OverlayTrigger>
    ) : (
        <div className={classNames(className ? className : styles.cellValue)}>
            {children || <>&nbsp;</>}
        </div>
    )
}
