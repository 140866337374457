import { AppContext } from 'App'
import classNames from 'classnames'
import { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMeasure } from 'react-use'
import styles from './NavBar.module.scss'
import { NavBarActions } from './NavBarActions/NavBarActions'
import { NavBarFilters } from './NavBarFilters/NavBarFilters'
import { NavBarColorGuide } from './NavBarInfoGuides/NavBarColorGuide/NavBarColorGuide'
import { NavBarIconGuide } from './NavBarInfoGuides/NavBarIconGuide/NavBarIconGuide'
import { NavBarStatusIndicator } from './NavBarStatusIndicator/NavBarStatusIndicator'
import { ToolsMenu } from './ToolsMenu/ToolsMenu'

export const navRowHeight = 45
document.documentElement.style.setProperty('--nav-row-height', navRowHeight + 'px') // Set value of CSS var --nav-row-height

export const NavBar = () => {
    const { uiState, setUiState } = useContext(AppContext)
    const { search, pathname } = useLocation()
    const showFiltersAndUiToggles = !pathname.startsWith('/project/')

    const [ref, { height }] = useMeasure<HTMLDivElement>()
    useEffect(() => {
        setUiState({ ...uiState, navHeight: height })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height])

    const isOneRow = height <= navRowHeight
    const isTwoRow = height > navRowHeight && height <= navRowHeight * 2
    const isThreeRow = height > navRowHeight * 2

    // Careful, be sure (marginLeft + marginRight) always equals the same number for 1, 2, and 3 row format.
    // Failing to do so will trigger recursive re-renders...
    const uiToggleStyles = {
        marginLeft: isOneRow ? '15px' : isTwoRow ? '45px' : isThreeRow ? '15px' : '30px',
        marginRight: isOneRow ? '45px' : isTwoRow ? '15px' : isThreeRow ? '45px' : '30px',
    }

    const marginBottom: number = !uiState.navIsCollapsed
        ? 0
        : isOneRow
        ? 0
        : isTwoRow
        ? -navRowHeight
        : isThreeRow
        ? -(navRowHeight * 2)
        : 0

    const linkTo = (to: string) => `${to}${search}`

    return (
        <div className={styles.navBar} ref={ref} style={{ marginBottom }}>
            <div className={styles.header}>
                <div className={styles.brandAndNav}>
                    <Link to={linkTo('/')} title='PurplePeeps' className={styles.brand}>
                        PurplePeeps
                    </Link>
                    <ul className={styles.nav}>
                        <li>
                            <Link to={linkTo('/')} title='Peeps' className={styles.link}>
                                Peeps
                            </Link>
                        </li>
                        <li>
                            <Link to={linkTo('/projects')} title='Projects' className={styles.link}>
                                Projects
                            </Link>
                        </li>

                        <li>
                            <ToolsMenu toggleClass={styles.link} />
                        </li>
                    </ul>
                </div>
                {showFiltersAndUiToggles && (
                    <div
                        className={styles.filersAndUiToggles}
                        style={{ backgroundColor: !isOneRow ? '#eee' : 'transparent' }}
                    >
                        <div className={styles.filters}>
                            <NavBarFilters />
                        </div>
                        <div className={styles.uiToggles} style={uiToggleStyles}>
                            <NavBarColorGuide />
                            <NavBarIconGuide />
                            <NavBarActions />
                        </div>
                    </div>
                )}
                {!isOneRow && (
                    <button
                        className={styles.collapsedMenuToggle}
                        onClick={() =>
                            setUiState({
                                ...uiState,
                                navIsCollapsed: !uiState.navIsCollapsed,
                            })
                        }
                    >
                        <i
                            className={classNames(
                                'fa fa-regular',
                                uiState.navIsCollapsed
                                    ? 'fa-angle-double-down'
                                    : 'fa-angle-double-up'
                            )}
                        ></i>
                    </button>
                )}
                <div className={styles.statusIndicator}>
                    <NavBarStatusIndicator />
                </div>
            </div>
        </div>
    )
}
