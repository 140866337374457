import { apiRoutes } from './apiRoutes'
import { msalRequest } from './authService'
import { EngagementResponse } from './models'

const useDevCacheApp = process.env.REACT_APP_USE_DEV_CACHE_APP === 'true'
if (useDevCacheApp) {
    // See README.md for more info on how to use the `dev-cache-app`
    console.log('Using dev-cache-app (REACT_APP_USE_DEV_CACHE_APP)')
}

export const fetchAppData = async (): Promise<EngagementResponse> => {
    const url = useDevCacheApp ? 'http://localhost:3005/api/engagements' : apiRoutes.getEngagements
    const data = await msalRequest({ url })
    return data
}
