import { useEffect } from 'react'

export const useMountEffect = (fn: () => void) =>
    useEffect(() => {
        fn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

export const useMountEffectAsync = (fn: () => Promise<void>) =>
    useEffect(() => {
        ;(async () => {
            await fn()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
