import { apiRoutes } from './apiRoutes'
import { msalRequest } from './authService'
import { AddConsultantDto, BookableConsultant, FetchConsultantCostResponse, ProjectBudget, ProjectBudgetAuditResponse } from './models'

const useDevCacheApp = process.env.REACT_APP_USE_DEV_CACHE_APP === 'true'
if (useDevCacheApp) {
    // See README.md for more info on how to use the `dev-cache-app`
    console.log('Using dev-cache-app (REACT_APP_USE_DEV_CACHE_APP)')
}

export const defaultProjectBudgetAuditResponse: ProjectBudgetAuditResponse = {
    projectId: 0,
    projectName: '',
    clientName: '',
    tcv: undefined,
    timesheeted: undefined,
    futureBookedFromSlipProjections: undefined,
    futureBookedFromBookingsByDay: undefined,
    remainingTcvFromSlipProjections: undefined,
    remainingTcvFromBookingsByDay: undefined,
    nonTimeSlipProjections: 0,
    nonTimeSlips: 0,
    warnings: [],
    consultants: [],
    nonTimeFees: [],
}

export const fetchConsultantCost = async (
    request: AddConsultantDto
): Promise<FetchConsultantCostResponse> => {
    const url =
        apiRoutes.getTotalConsultantCost +
        `?userEmail=${request.email}&startDate=${request.startDate}&endDate=${request.endDate}&rate=${request.rate}`
    const data = await msalRequest({ url })
    return data
}

export const fetchConsultantDefaultRate = async (email: string): Promise<number> => {
    const url = apiRoutes.getDefaultConsultantRate + `?userEmail=${email}`
    const data = await msalRequest({ url })
    return data
}

export const fetchConsultantRate = async (projectId: number, email: string): Promise<number> => {
    const url = `${apiRoutes.getConsultantRate}?projectId=${projectId}&userEmail=${email}`
    const data = await msalRequest({ url })
    return data
}

export const fetchProjectBudgetAudit = async (
    projectId: number
): Promise<ProjectBudgetAuditResponse> => {
    const url = useDevCacheApp
        ? 'http://localhost:3005/api/projects/audit/1234' // cache will only return only one data set, probably not related to the project selected.
        : `${apiRoutes.getProjectBudgetAudit}/${projectId}`
    return await msalRequest({ url })
}

export const fetchGigDetails = async (projectId: number): Promise<ProjectBudget> => {
    const url = apiRoutes.getGigDetails + `?projectId=${projectId}`
    const data = await msalRequest({ url })
    return data
}

export const fetchBookableConsultants = async () : Promise<BookableConsultant[]> => {
    const data = await msalRequest({ url: apiRoutes.getBookableConsultants}) as BookableConsultant[]
    
    // Fill the role with 'Unknown' if none provided. This is particularly sensitive since the UI control
    // is checking JS object properties and the back end skips properties with default/blank values. It
    // feels wrong that it skips properties, but it's safer to handle it here, closer to where it matters.
    const bookableConsultants = data.map(c => Object.assign({role: 'Unknown'}, c))
    return bookableConsultants
}
