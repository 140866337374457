import { DownloadButton } from 'components/Shared/DownloadButton/DownloadButton'
import { apiRoutes } from 'domain/apiRoutes'
import { dateFromIsoString, formatDateIso } from 'domain/dateHelpers'
import { useState } from 'react'
import styles from './ProjectBurnupExportPopover.module.scss'

export interface ProjectBurnupExportPopoverProps {
    projectId: number
    projectStartDate: Date
    projectEndDate: Date
}

interface ProjectBurnupExportPopoverState {
    firstIterationStartDate: Date
    iterationLength: number
}

export const ProjectBurnupExportPopover = (props: ProjectBurnupExportPopoverProps) => {
    const [state, setState] = useState<ProjectBurnupExportPopoverState>({
        firstIterationStartDate: props.projectStartDate,
        iterationLength: 14,
    })

    const exportProjectBurnupUrl =
        `${apiRoutes.exportProjectBurnup}/${props.projectId}?` +
        new URLSearchParams({
            firstIterationStartDate: formatDateIso(state.firstIterationStartDate),
            iterationLength: state.iterationLength.toString(),
        })

    return (
        <div className={styles.projectBurnupExport}>
            <h6>Export For Burnup</h6>
            <label htmlFor='first-iteration-start-date'>First Iteration Start Date</label>
            <input
                id='first-iteration-start-date'
                type='date'
                name='startDate'
                max={formatDateIso(props.projectEndDate)}
                value={formatDateIso(state.firstIterationStartDate)}
                onChange={(e) => {
                    const value = e.target.value
                    if (value) {
                        setState((prev) => ({
                            ...prev,
                            firstIterationStartDate: dateFromIsoString(value),
                        }))
                    }
                }}
            />
            <p>
                If first iteration doesn't start from Project Day 1, then
                <br />
                all the days before first iteration will be taken in Iteration 0
            </p>

            <label htmlFor='iteration-length'>Iteration Length</label>
            <input
                id='iteration-length'
                type='number'
                min={1}
                value={state.iterationLength.toString()}
                onChange={(e: any) =>
                    setState((prev) => ({
                        ...prev,
                        iterationLength: e.target.value,
                    }))
                }
                required
            ></input>
            <DownloadButton className={styles.downloadButton} url={exportProjectBurnupUrl}>
                Export
            </DownloadButton>
        </div>
    )
}
