import { dateFromIsoString, formatDateIso, getPeepsEndDate, getTodayDate } from 'domain/dateHelpers'
import { getAutoSelectedResourceGroups, getDefaultSelectedSquads } from 'domain/filterHelpers'
import { AppStore, defaultFilterState, FilterState } from 'domain/models'
import { recallFilterState, rememberFilterState } from 'domain/rememberService'
import { pullFiltersFromUrl, pushFiltersIntoUrl } from 'domain/urlQueryService'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

export const useFilters = (store: AppStore) => {
    const history = useHistory()
    const todayDate = getTodayDate()
    const todayDateString = formatDateIso(todayDate) // convert to date only string, for use in useMemo
    const endDate = getPeepsEndDate()
    const endDateString = formatDateIso(endDate) // convert to date only string, for use in useMemo
    const isStoreInitialized = store.isInitialized

    // Init filters
    const initialFilterState: FilterState = useMemo(
        () => ({
            ...defaultFilterState,
            startDate: dateFromIsoString(todayDateString),
            endDate: dateFromIsoString(endDateString),
        }),
        [todayDateString, endDateString]
    )

    const [filters, _setFilters] = useState<FilterState>(initialFilterState)
    // Wrap _setFilters with side effects
    const setFilters = (newFilters: FilterState, force?: true): void => {
        const squadsHaveChanged = newFilters.squads !== filters.squads
        const viewTypeHasChanged = newFilters.viewType !== filters.viewType

        const updatedFilters = {
            ...newFilters,
            resourceGroups:
                squadsHaveChanged || viewTypeHasChanged || force
                    ? getAutoSelectedResourceGroups(store, newFilters)
                    : newFilters.resourceGroups,
        }

        _setFilters(updatedFilters)
        rememberFilterState(updatedFilters)
        pushFiltersIntoUrl(updatedFilters, store, history)
    }

    // Set initial filters when isStoreInitialized
    useEffect(() => {
        if (!isStoreInitialized) return
        // Layer in filters from sources: initial, remembered, and URL.
        let filters: FilterState = {
            ...initialFilterState,
            ...recallFilterState(),
            squads: getDefaultSelectedSquads(store),
        }
        filters = {
            ...filters,
            resourceGroups: getAutoSelectedResourceGroups(store, filters),
        }
        filters = { ...filters, ...pullFiltersFromUrl(filters, store, history) }
        _setFilters(filters)
        pushFiltersIntoUrl(filters, store, history)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStoreInitialized])

    const resetFilters = () =>
        setFilters(
            {
                ...initialFilterState,
                squads: getDefaultSelectedSquads(store),
            },
            true
        )

    return {
        filters,
        setFilters,
        resetFilters,
    }
}
