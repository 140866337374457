import styles from './ReleaseNotes.module.scss'
import { releaseNotesContent } from './ReleaseNotesModel'
import ReactMarkDown from 'react-markdown'

export const ReleaseNotes = () => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h4>What's new?</h4>
            </div>
            <div className='alert alert-info' role='alert'>
                For feedback and help, please check out the&nbsp;
                <a
                    href='https://web.yammer.com/main/org/team.telstra.com/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMjU1NDk4NTQ3MiJ9/all'
                    target='_blank'
                    rel='noreferrer'
                >
                    TechOps Yammer group
                </a>
                .
            </div>
            <ReactMarkDown children={releaseNotesContent.content}></ReactMarkDown>
        </div>
    )
}
