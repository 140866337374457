import classNames from 'classnames'
import { AdvancedAutoComplete } from 'components/Shared/AutoComplete/AdvancedAutoComplete'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { BudgetInfoPopover } from 'components/Shared/PageLayout/SideBar/ProjectBudgetAdjustor/BudgetAdjustmentCalculator/BudgetInfoPopover/BudgetInfoPopover'
import { ConsultantRow } from 'components/Shared/PageLayout/SideBar/ProjectBudgetEstimator/ProjectBudgetEstimator'
import { fetchConsultantCost, fetchConsultantDefaultRate } from 'domain/budgetService'
import { formatDateTimeForDto, formatDateIso, dateFromIsoString } from 'domain/dateHelpers'
import { useDebouncedCallback } from 'domain/hooks/useDebouncedCallback'
import { BookableConsultant } from 'domain/models'
import moment from 'moment'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import styles from './EditConsultantRowForm.module.scss'

interface IEditConsultantRowFormProps {
    consultantRow: ConsultantRow
    onDelete: (row: ConsultantRow) => void
    onChange: (row: ConsultantRow) => void
    bookableConsultants: BookableConsultant[]
}

export const EditConsultantRowForm = ({
    consultantRow,
    onDelete,
    onChange,
    bookableConsultants
}: IEditConsultantRowFormProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [formState, setFormState] = useState<ConsultantRow>(consultantRow)

    const populateTotalCost = (formState: ConsultantRow): React.ReactNode => {
        if (
            formState.totalCost === 0 &&
            formState.skippedDays.length === 0 &&
            formState.skippedEngagements.length === 0
        ) {
            return <></>
        }
        const generateTotalCost = (
            <div className={styles.totalCost}>
                <CurrencyFormat amount={formState.totalCost} />
                {(formState.skippedDays.length > 0 || formState.skippedEngagements.length > 0) && (
                    <BudgetInfoPopover
                        title='Days Ignored in Budget'
                        skippedDays={formState.skippedDays}
                        skippedEngagements={formState.skippedEngagements}
                    />
                )}
                {formState.message && (
                    <BudgetInfoPopover title='Info' message={formState.message} />
                )}
            </div>
        )
        return generateTotalCost
    }

    const getConsultantCost = async (formState: ConsultantRow): Promise<ConsultantRow> => {
        return fetchConsultantCost({
            name: formState.name,
            email: formState.email,
            rate: formState.rate,
            startDate: formatDateTimeForDto(formState.startDate),
            endDate: formatDateTimeForDto(formState.endDate),
        }).then((res): ConsultantRow => {
            const updatedRow: ConsultantRow = {
                ...formState,
                totalCost: !res.totalCost || res.totalCost === null ? 0 : res.totalCost,
                skippedDays: res.skippedDays,
                skippedEngagements: res.skippedEngagements,
                message: res.message,
            }
            return updatedRow
        })
    }

    const getConsultantTotalCost = async (formState: ConsultantRow) => {
        setIsLoading(true)

        // Recalculate the Total Cost
        return getConsultantCost(formState)
            .then((r) => {
                setFormState((prev) => ({
                    ...prev,
                    totalCost: r.totalCost,
                    skippedDays: r.skippedDays,
                    skippedEngagements: r.skippedEngagements,
                    message: r.message,
                }))
                onChange(r)
                return r
            })
            .catch((error) => {
                console.debug(error)
            })
            .finally(() => setIsLoading(false))
    }

    const getConsultantDefaultRate = async (formState: ConsultantRow) => {
        setIsLoading(true)

        // Recalculate the Total Cost
        return fetchConsultantDefaultRate(formState.email)
            .then((rate) => {
                const newFormState = { ...formState, rate: rate }
                setFormState(newFormState)
                handleOnChangeForTotalCost(newFormState)
            })
            .catch((error) => {
                console.debug(error)
            })
            .finally(() => setIsLoading(false))
    }

    const handleOnChangeForTotalCost = useDebouncedCallback(getConsultantTotalCost, 1000)
    const handleOnChangeForDefaultRate = useDebouncedCallback(getConsultantDefaultRate, 1000)

    return (
        <tr className={styles.editConsultantRow}>
            <td className={styles.colDelete}>
                <button
                    className={classNames(styles.deleteBtn, 'btn-close')}
                    aria-label='Delete'
                    onClick={() => {
                        if (onDelete) {
                            onDelete(formState)
                        }
                    }}
                ></button>
            </td>
            <td>
                <AdvancedAutoComplete
                    placeholder='Search Consultants'
                    dataSource={bookableConsultants}
                    name='name'
                    valueField={'[email]'}
                    labelField={'[name] ([role])'}
                    defaultValue={
                        formState
                            ? bookableConsultants.find(
                                  (value) => formState.email === value.email
                              )
                            : null
                    }
                    onChange={(option) => {
                        if (option) {
                            const newFormState = {
                                ...formState,
                                email: option.email,
                                name: option.name,
                            }
                            setFormState(newFormState)

                            handleOnChangeForDefaultRate(newFormState)
                        }
                    }}
                />
            </td>
            <td>
                <input
                    type='date'
                    name='startDate'
                    disabled={!formState.email}
                    min={formatDateIso(moment().toDate())}
                    value={formatDateIso(formState.startDate)}
                    placeholder='DD/MM/YYYY'
                    onChange={(e) => {
                        const value = e.target.value
                        if (value) {
                            const newFormState = {
                                ...formState,
                                startDate: dateFromIsoString(value),
                            }
                            setFormState(newFormState)
                            handleOnChangeForTotalCost(newFormState)
                        }
                    }}
                    autoComplete='off'
                />
            </td>
            <td className={styles.endDateColumn}>
                <input
                    type='date'
                    name='endDate'
                    disabled={!formState.email}
                    min={formatDateIso(formState.startDate)}
                    value={formatDateIso(formState.endDate)}
                    placeholder='DD/MM/YYYY'
                    onChange={(e) => {
                        const value = e.target.value
                        if (value) {
                            const newFormState = {
                                ...formState,
                                endDate: dateFromIsoString(value),
                            }
                            setFormState(newFormState)
                            handleOnChangeForTotalCost(newFormState)
                        }
                    }}
                    autoComplete='off'
                />
            </td>
            <td className={styles.rateColumn}>
                <NumericFormat
                    name='rate'
                    thousandsGroupStyle='thousand'
                    disabled={!formState.email}
                    value={formState.rate || ''}
                    placeholder={'$0'}
                    prefix='$'
                    decimalSeparator='.'
                    displayType='input'
                    type='text'
                    autoComplete='off'
                    thousandSeparator={true}
                    allowNegative={false}
                    onValueChange={(values, sourceInfo) => {
                        if (sourceInfo.source === 'prop') return
                        const newFormState = { ...formState, rate: values.floatValue || 0 }
                        setFormState(newFormState)
                        handleOnChangeForTotalCost(newFormState)
                    }}
                />
            </td>
            <td>
                {!isLoading && populateTotalCost(formState)}

                {isLoading && (
                    <span>
                        <i className='fa fa-spinner fa-pulse fa-fw'></i>
                        Loading
                    </span>
                )}
            </td>
        </tr>
    )
}
