import { NumericFormat } from 'react-number-format'

export interface CurrencyFormatProps {
    amount: number | '-'
    className?: string
}

export const CurrencyFormat = ({ amount, className }: CurrencyFormatProps) => (
    <NumericFormat
        className={className}
        value={amount}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
        decimalSeparator='.'
        decimalScale={2}
    />
)
