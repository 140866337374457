import classNames from 'classnames'
import { AdvancedAutoComplete } from 'components/Shared/AutoComplete/AdvancedAutoComplete'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { BudgetInfoPopover } from 'components/Shared/PageLayout/SideBar/ProjectBudgetAdjustor/BudgetAdjustmentCalculator/BudgetInfoPopover/BudgetInfoPopover'
import { IBudgetFormState } from 'components/Shared/PageLayout/SideBar/ProjectBudgetAdjustor/ProjectBudgetAdjustor'
import { apiRoutes } from 'domain/apiRoutes'
import { msalPostRequest } from 'domain/authService'
import { fetchConsultantRate } from 'domain/budgetService'
import { formatDateTimeForDto, formatDateIso, dateFromIsoString, formatOptionalDateIso } from 'domain/dateHelpers'
import { useDebouncedCallback } from 'domain/hooks/useDebouncedCallback'
import { BookableConsultant, ProposedBudget } from 'domain/models'
import moment from 'moment'
import { useState } from 'react'
import { NumericFormat } from 'react-number-format'
import styles from './EditBudgetConsultantRowForm.module.scss'

interface IEditBudgetConsultantRowFormProps {
    editProposedBudget: IEditBudgetFormState
    onBudgetAdjustmentChange: (row: IEditBudgetFormState) => void
    onDeleteRow?: (itemKey: string) => void
    bookableConsultants: BookableConsultant[]
}
interface IBudgetRequest {
    projectId: number
    userDetails: {
        userEmail: string
        currentEndDate: string
        proposedEndDate: string
        rate: number | undefined
    }[]
}
export interface IEditBudgetFormState extends IBudgetFormState {
    projectId: number
    proposedEndDate: Date | undefined
    proposedBudget: ProposedBudget | undefined
    ItemKey: string
    isCalculating: boolean
}

function updateProposedBudgetState(
    prevFormState: IEditBudgetFormState,
    proposedBudget: ProposedBudget | undefined
): IEditBudgetFormState {
    return {
        ...prevFormState,
        proposedBudget: proposedBudget,
    }
}

function populateBudgetInfo(formState: IEditBudgetFormState): React.ReactNode {
    let proposedBudget = formState.proposedBudget

    if (
        !proposedBudget ||
        (proposedBudget.budgetAdjustmentAmount === 0 &&
            proposedBudget.skippedDays.length === 0 &&
            proposedBudget.skippedEngagements.length === 0)
    )
        return

    const generateProposedBudget = (
        <>
            {
                <div className={styles.amount}>
                    <CurrencyFormat amount={proposedBudget.budgetAdjustmentAmount} />
                    {(proposedBudget.skippedDays.length > 0 ||
                        proposedBudget.skippedEngagements.length > 0) && (
                        <BudgetInfoPopover
                            title='Days Ignored in Budget'
                            skippedDays={proposedBudget.skippedDays}
                            skippedEngagements={proposedBudget.skippedEngagements}
                        />
                    )}
                    {proposedBudget.message && (
                        <BudgetInfoPopover title='Info' message={proposedBudget.message} />
                    )}
                </div>
            }
        </>
    )

    return generateProposedBudget
}

export const EditBudgetConsultantRowForm = ({
    editProposedBudget,
    onBudgetAdjustmentChange,
    onDeleteRow,
    bookableConsultants
}: IEditBudgetConsultantRowFormProps) => {
    const [formState, setFormState] = useState<IEditBudgetFormState>(editProposedBudget)
    const [isLoadingConsultantRate, setLoadingConsultantRate] = useState<boolean>(false)

    const isNewConsultant = formState.endDate ? true : false

    const handleOnChange = useDebouncedCallback(async (formState: IEditBudgetFormState) => {
        await updateProposedBudget(formState)
    }, 1000)

    async function updateProposedBudget(formState: IEditBudgetFormState) {
        let newFormState: IEditBudgetFormState

        // No need to load the value if the proposed start date same with end date
        if (formState.endDate === formState.proposedEndDate) {
            newFormState = updateProposedBudgetState(formState, undefined)
            setFormState(newFormState)
            onBudgetAdjustmentChange(newFormState)
            return
        }

        // nor if we don't have current start date (which prolly means this consultant isn't yet booked at all)
        if (!formState.email || !formState.startDate) return

        // it is possible to not have an end date (if this is a newly added consultant)
        const currentEndDate = formState.endDate
            ? formatDateTimeForDto(formState.endDate)
            : formatDateTimeForDto(formState.startDate)

        const proposedEndDate = formState.proposedEndDate
            ? formatDateTimeForDto(formState.proposedEndDate)
            : currentEndDate

        const request: IBudgetRequest = {
            projectId: formState.projectId,
            userDetails: [
                {
                    userEmail: formState.email,
                    currentEndDate,
                    proposedEndDate,
                    rate: formState.rate,
                },
            ],
        }

        newFormState = { ...formState, isCalculating: true }
        setFormState(newFormState)

        onBudgetAdjustmentChange(newFormState)

        return msalPostRequest(
            {
                url: apiRoutes.getProposedBudget,
            },
            request
        )
            .then((r: ProposedBudget[]) => {
                if (r.length === 1) {
                    const proposedBudget = r[0]
                    newFormState = updateProposedBudgetState(formState, proposedBudget)

                    setFormState(newFormState)

                    onBudgetAdjustmentChange(newFormState)
                } else {
                    Promise.reject(`Unable to load the proposed budget for ${formState.name}.`)
                }
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() =>
                setFormState((prevFormState) => ({
                    ...prevFormState,
                    isCalculating: false,
                }))
            )
    }

    const getConsultantRate = async (formState: IEditBudgetFormState) => {
        setLoadingConsultantRate(true)

        // Recalculate the Total Cost
        return fetchConsultantRate(formState.projectId, formState.email)
            .then((rate) => {
                const newFormState = { ...formState, rate: rate }
                setFormState(newFormState)
                handleOnChange(newFormState)
            })
            .catch((error) => {
                console.debug(error)
            })
            .finally(() => setLoadingConsultantRate(false))
    }

    const handleOnChangeForConsultantRate = useDebouncedCallback(getConsultantRate, 1000)

    return (
        <tr className={styles.editConsultantRow}>
            <td className={styles.colDelete}>
                {!formState.endDate && (
                    <button
                        className={classNames(styles.deleteBtn, 'btn-close')}
                        aria-label='Delete'
                        onClick={() => {
                            if (onDeleteRow) {
                                onDeleteRow(formState.ItemKey)
                            }
                        }}
                    ></button>
                )}
            </td>
            <td>
                <AdvancedAutoComplete
                    placeholder='Search Consultants'
                    dataSource={bookableConsultants}
                    name='name'
                    valueField={'[email]'}
                    labelField={'[name] ([role])'}
                    defaultValue={
                        formState
                            ? bookableConsultants.find(
                                  (value) => formState.email === value.email
                              )
                            : null
                    }
                    disabled={isNewConsultant}
                    onChange={(option) => {
                        if (option) {
                            const newFormState = {
                                ...formState,
                                email: option.email,
                                name: option.name,
                            }
                            setFormState(newFormState)

                            handleOnChangeForConsultantRate(newFormState)
                        }
                    }}
                />
            </td>
            <td className={styles.dateColumn}>
                <input
                    type='date'
                    name='startDate'
                    disabled={isNewConsultant}
                    min={formatDateIso(moment().toDate())}
                    value={formatOptionalDateIso(formState.startDate)}
                    placeholder='DD/MM/YYYY'
                    required
                    pattern='\d{4}-\d{2}-\d{2}'
                    onChange={async (e) => {
                        const value = e.target.value
                        if (value) {
                            const newFormState = {
                                ...formState,
                                startDate: dateFromIsoString(value),
                            }
                            setFormState(newFormState)
                            handleOnChange(newFormState)
                        }
                    }}
                    autoComplete='off'
                />
            </td>
            <td className={styles.dateColumn}>
                {formState.endDate && (
                    <input
                        type='date'
                        name='endDate'
                        disabled={true}
                        min={formatDateIso(moment().toDate())}
                        value={formatOptionalDateIso(formState.endDate)}
                        placeholder='DD/MM/YYYY'
                        pattern='\d{4}-\d{2}-\d{2}'
                        autoComplete='off'
                    />
                )}
            </td>
            <td className={classNames(styles.dateColumn, styles.proposedEndDate)}>
                <input
                    type='date'
                    name='proposedEndDate'
                    min={formatDateIso(moment().toDate())}
                    value={
                        formState.proposedEndDate ? formatDateIso(formState.proposedEndDate) : ''
                    }
                    required
                    pattern='\d{4}-\d{2}-\d{2}'
                    onChange={async (e) => {
                        const value = e.target.value
                        if (value) {
                            const newFormState = {
                                ...formState,
                                proposedEndDate: dateFromIsoString(value),
                            }
                            setFormState(newFormState)
                            handleOnChange(newFormState)
                        }
                    }}
                />
            </td>
            <td className={styles.rateColumn}>
                {!isLoadingConsultantRate && (
                    <NumericFormat
                        name='rate'
                        thousandsGroupStyle='thousand'
                        value={formState.rate || ''}
                        placeholder={'$0'}
                        disabled={isNewConsultant}
                        prefix='$'
                        decimalSeparator='.'
                        displayType='input'
                        type='text'
                        autoComplete='off'
                        thousandSeparator={true}
                        allowNegative={false}
                        onValueChange={(values, sourceInfo) => {
                            if (sourceInfo.source === 'prop') return
                            const newFormState = {
                                ...formState,
                                rate: values.floatValue || 0,
                            }
                            setFormState(newFormState)
                            handleOnChange(newFormState)
                        }}
                    />
                )}

                {isLoadingConsultantRate && (
                    <span>
                        <i className='fa fa-spinner fa-pulse fa-fw'></i>
                        Loading
                    </span>
                )}
            </td>
            <td className={styles.budgetAdjustmentColumn}>
                {!formState.isCalculating && populateBudgetInfo(formState)}
                {formState.isCalculating && (
                    <span>
                        <i className='fa fa-spinner fa-pulse fa-fw'></i>
                        Loading
                    </span>
                )}
            </td>
        </tr>
    )
}
