import { BookableConsultant } from 'domain/models'
import { Table } from 'react-bootstrap'
import styles from './BudgetConsultantTable.module.scss'
import {
    IEditBudgetFormState,
    EditBudgetConsultantRowForm,
} from './components/BudgetConsultantRow/EditBudgetConsultantRowForm'

interface IBudgetConsultantTableProps {
    consultantDetails: IEditBudgetFormState[]
    onBudgetConsultantChange: (row: IEditBudgetFormState) => void
    onDeleteRow?: (itemKey: string) => void
    bookableConsultants: BookableConsultant[]
}

export const BudgetConsultantTable = (props: IBudgetConsultantTableProps) => {
    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <td className={styles.colDelete}></td>
                    <td>Consultant</td>
                    <td>Start Date</td>
                    <td>End Date</td>
                    <td>Proposed End Date</td>
                    <td>Rate</td>
                    <td>Budget Adjustments</td>
                </tr>
            </thead>
            <tbody>
                {props.consultantDetails.map((gigDetail, i) => (
                    <EditBudgetConsultantRowForm
                        key={gigDetail.ItemKey}
                        onBudgetAdjustmentChange={props.onBudgetConsultantChange}
                        onDeleteRow={props.onDeleteRow}
                        editProposedBudget={gigDetail}
                        bookableConsultants={props.bookableConsultants}
                    />
                ))}
                {props.consultantDetails.length === 0 && (
                    <tr>
                        <td colSpan={7}>No future bookings were found for this engagement</td>
                    </tr>
                )}
            </tbody>
        </Table>
    )
}
