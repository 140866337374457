import classNames from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styles from './ColourBlindToggle.module.scss'

type ColourBlindToggleProps = {
    isColourBlindMode: boolean
    toggleColourBlindMode: () => void
}

export const ColourBlindToggle = ({
    isColourBlindMode,
    toggleColourBlindMode,
}: ColourBlindToggleProps) => (
    <OverlayTrigger
        placement='bottom'
        overlay={
            <Tooltip id='colour-blind-tooltip'>
                <div>Colour blind mode is {isColourBlindMode ? 'ON' : 'OFF'}</div>
            </Tooltip>
        }
    >
        <div
            className={classNames(styles.colourBlindToggle, isColourBlindMode && styles.on)}
            onClick={toggleColourBlindMode}
        >
            <div
                className={classNames(
                    styles.icon,
                    isColourBlindMode ? 'fa fa-eye' : 'fa fa-eye-slash'
                )}
            ></div>
        </div>
    </OverlayTrigger>
)
