import { RememberedFilterState, RememberedUiState, UiState } from './models'

const uiStateKey = 'REMEMBERED_UI_STATE'
const filterStateKey = 'REMEMBERED_FILTER_STATE'

export const rememberUiState = (uiState: UiState): void => {
    const rememberedUiState: RememberedUiState = {
        showNetSuite: uiState.showNetSuite,
        isColourBlindMode: uiState.isColourBlindMode,
        navIsCollapsed: uiState.navIsCollapsed,
        showProjectBudgetAuditTools: uiState.showProjectBudgetAuditTools,
        releaseNotesVersion: uiState.releaseNotesVersion,
    }
    localStorage.setItem(uiStateKey, JSON.stringify(rememberedUiState))
}

export const recallUiState = (): Partial<RememberedUiState> => {
    const uiStateJson = localStorage.getItem(uiStateKey)
    const uiState: Partial<RememberedUiState> = !!uiStateJson ? JSON.parse(uiStateJson) : {}
    return uiState
}

export const rememberFilterState = (filterState: RememberedFilterState): void => {
    const rememberedFilterState: RememberedFilterState = {
        viewType: filterState.viewType,
    }
    localStorage.setItem(filterStateKey, JSON.stringify(rememberedFilterState))
}

export const recallFilterState = (): Partial<RememberedFilterState> => {
    const filterStateJson = localStorage.getItem(filterStateKey)
    const filterState: Partial<RememberedFilterState> = !!filterStateJson
        ? JSON.parse(filterStateJson)
        : {}
    return filterState
}
