import { Fragment, InputHTMLAttributes, useMemo } from 'react'
import BootstrapTable from 'react-bootstrap/Table'
import {
    Table,
    Column,
    ColumnDef,
    FilterFn,
    flexRender,
    getCoreRowModel,
    useReactTable,
    ColumnFiltersState,
    getFilteredRowModel,
    SortingState,
    getSortedRowModel,
    SortingFn,
    sortingFns,
    VisibilityState,
    Row,
} from '@tanstack/react-table'
import { AppContext, ShowSplashView } from 'App'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { InfoTooltip } from 'components/Shared/InfoTooltip/InfoTooltip'
import { PageLayout } from 'components/Shared/PageLayout/PageLayout'
import { formatDateShort } from 'domain/dateHelpers'
import { SideBarType } from 'domain/models'
import { useContext, useEffect, useState } from 'react'
import { ProjectTableMenu } from './ProjectTableMenu/ProjectTableMenu'
import styles from './ProjectsView.module.scss'
import {
    makeProjectTableRows,
    ProjectSnapshotWithBudget,
    buildAllProjectSnapshotsCache,
    allProjectSnapshotsCache,
    ProjectConsultantSnapshot,
    delay,
} from './projectsViewHelpers'
import { rankItem } from '@tanstack/match-sorter-utils'
import moment from 'moment'
import classNames from 'classnames'
import { useEffectOnce } from 'react-use'
import { Link } from 'react-router-dom'

export const ProjectsView = () => {
    const { store, filters, setFilters, uiState, setUiState, startStoreLoad } = useContext(AppContext)
    const hasAllProjectSnapshotsCache = allProjectSnapshotsCache.length > 0
    const [data, setData] = useState<ProjectSnapshotWithBudget[]>([])
    const [isInitialized, setIsInitialized] = useState<boolean>(hasAllProjectSnapshotsCache)

    useEffectOnce(() => {
        startStoreLoad()
    })

    useEffect(() => {
        if (!store.isInitialized) return
        delay(100).then(async () => {
            await buildAllProjectSnapshotsCache(store)
            setIsInitialized(true)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.isInitialized])

    useEffect(() => {
        setData(makeProjectTableRows(store, filters))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, isInitialized])

    const columns = useMemo<ColumnDef<ProjectSnapshotWithBudget>[]>(
        () => [
            {
                accessorKey: 'projectId',
                header: 'Project ID',
                footer: 'Row Count',
                filterFn: filterText,
            },
            {
                accessorKey: 'projectName',
                header: 'Project Name',
                filterFn: filterText,
                sortingFn: sortAplha,
            },
            {
                accessorKey: 'startDate',
                header: 'Start',
                filterFn: filterDate,
            },

            {
                accessorKey: 'endDate',
                header: 'End',
                filterFn: filterDate,
            },

            {
                accessorKey: 'clientName',
                header: 'Client Name',
                filterFn: filterText,
            },
            {
                accessorKey: 'programManager',
                header: 'Program Manager',
                sortingFn: sortAplhaWithObjectProperty,
                sortDescFirst: false,
            },
            {
                accessorKey: 'salesRep',
                header: 'Sales Rep',
                sortingFn: sortAplhaWithObjectProperty,
                sortDescFirst: false,
            },

            {
                accessorKey: 'projectCoordinator',
                header: 'Project Coordinator',
                sortingFn: sortAplhaWithObjectProperty,
                sortDescFirst: false,
            },
            {
                accessorKey: 'consultants',
                header: 'Consultants',
            },
            {
                accessorKey: 'tcv',
                header: 'TCV',
            },
            {
                accessorKey: 'timesheeted',
                header: 'Timesheets',
            },
            {
                accessorKey: 'futureBookedFromBookingsByDay',
                header: 'Future Bookings',
            },
            {
                accessorKey: 'futureBookedFromSlipProjections',
                header: () => (
                    <FormattedHeader title='Future Bookings' subTitle='(By Slip Projections)' />
                ),
            },
            {
                accessorKey: 'futureBookedDifference',
                header: 'Future Bookings Difference',
            },
            {
                accessorKey: 'remainingTcvFromBookingsByDay',
                header: 'Remaining TCV',
            },
            {
                accessorKey: 'remainingTcvFromSlipProjections',
                header: () => (
                    <FormattedHeader title='Remaining TCV' subTitle='(By Slip Projections)' />
                ),
            },
            {
                accessorKey: 'nonTimeSlips',
                header: 'Non-Time Fees',
            },
            {
                accessorKey: 'actions',
                accessorFn: (row: ProjectSnapshotWithBudget) => row.projectId,
                header: '',
            },
        ],
        []
    )

    const searchPrefixes = {
        project: 'pr',
        client: 'cl',
        consultant: 'co',
        salesRep: 's',
        projectCoordinator: 'pc',
        programManager: 'pm',
    }

    const searchFieldClick = (searchValue: string, prefix: string) => {
        const search = `${prefix}: ${searchValue}`
        setFilters({
            ...filters,
            search: filters.search !== search ? search : '',
        })
    }

    const toggleShowProjectBudgetAuditTools = () => {
        const showProjectBudgetAuditTools = !uiState.showProjectBudgetAuditTools
        setColumnVisibility(makeColumnVisibility(showProjectBudgetAuditTools))
        setUiState({
            ...uiState,
            showProjectBudgetAuditTools,
        })
    }

    const makeColumnVisibility = (showBudgetAuditTools: boolean): VisibilityState => ({
        futureBookedFromSlipProjections: showBudgetAuditTools,
        remainingTcvFromSlipProjections: showBudgetAuditTools,
        futureBookedDifference: showBudgetAuditTools,
    })

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [sorting, setSorting] = useState<SortingState>([
        {
            id: 'projectId',
            desc: true,
        },
    ])
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        makeColumnVisibility(uiState.showProjectBudgetAuditTools)
    )

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        filterFns: {
            fuzzy: filterText,
        },
        state: {
            columnFilters,
            sorting,
            columnVisibility,
        },
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        enableSortingRemoval: false,
        onColumnVisibilityChange: setColumnVisibility,
    })

    const rows = table.getRowModel().rows
    const rowCount = rows.length
    const totalTcv = rows.reduce<number>((acc, row) => acc + row.getValue<number>('tcv'), 0)

    return (
        <ShowSplashView showSplashView={!store.isInitialized}>
            <PageLayout>
                <div className='container-fluid'>
                    <div
                        className={classNames(styles.header, {
                            [styles.isInitialized]: isInitialized,
                        })}
                    >
                        <h2>Projects</h2>
                        {!hasAllProjectSnapshotsCache ? (
                            <p>Building project data cache...</p>
                        ) : (
                            <p>
                                Displaying {rowCount} Projects &nbsp;
                                <InfoTooltip
                                    placement='bottom'
                                    tip='This table uses the header filters too'
                                />
                            </p>
                        )}
                        <div>
                            <button
                                className={styles.resetButton}
                                onClick={() => setColumnFilters([])}
                            >
                                Reset Table Filters
                            </button>
                            <ProjectTableMenu
                                showProjectBudgetAuditTools={uiState.showProjectBudgetAuditTools}
                                toggleShowProjectBudgetAuditTools={
                                    toggleShowProjectBudgetAuditTools
                                }
                            />
                        </div>
                    </div>
                    {isInitialized && (
                        <BootstrapTable
                            striped
                            bordered
                            hover
                            responsive
                            size='sm'
                            className={styles.table}
                        >
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <th key={header.id} className={styles[header.id]}>
                                                {header.id !== 'actions' ? (
                                                    <button
                                                        className={styles.sortButton}
                                                        onClick={() =>
                                                            header.column.toggleSorting()
                                                        }
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        <span
                                                            className={classNames(
                                                                styles.sortControls,
                                                                {
                                                                    [styles.sortAsc]:
                                                                        header.column.getIsSorted() ===
                                                                        'asc',
                                                                    [styles.sortDesc]:
                                                                        header.column.getIsSorted() ===
                                                                        'desc',
                                                                }
                                                            )}
                                                        />
                                                    </button>
                                                ) : (
                                                    flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )
                                                )}
                                                {[
                                                    'projectId',
                                                    'projectName',
                                                    'clientName',
                                                ].includes(header.column.id) && (
                                                    <TextFilter
                                                        column={header.column}
                                                        table={table}
                                                    />
                                                )}
                                                {['startDate', 'endDate'].includes(
                                                    header.column.id
                                                ) && (
                                                    <DateFilter
                                                        column={header.column}
                                                        table={table}
                                                    />
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                            {table.getRowModel().rows.map((row) => {
                                let projectId: number | undefined
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell, i) => {
                                            if (cell.column.id === 'projectId') {
                                                projectId = cell.getValue<number>()
                                            }
                                            return cell.column.id === 'projectId' ? (
                                                <td key={cell.id}>{cell.getValue<string>()}</td>
                                            ) : cell.column.id === 'projectName' ? (
                                                <td key={cell.id}>
                                                    <Link
                                                        className={styles.linkButton}
                                                        to={
                                                            projectId ? `/project/${projectId}` : ''
                                                        }
                                                        target='_blank'
                                                    >
                                                        {cell.getValue<string>()}
                                                    </Link>
                                                </td>
                                            ) : ['startDate', 'endDate'].includes(
                                                  cell.column.id
                                              ) ? (
                                                <DateCell key={i} value={cell.getValue<Date>()} />
                                            ) : cell.column.id === 'clientName' ? (
                                                <LinkedCell
                                                    key={cell.id}
                                                    value={cell.getValue<string>()}
                                                    onClick={(value) =>
                                                        searchFieldClick(
                                                            value,
                                                            searchPrefixes.client
                                                        )
                                                    }
                                                />
                                            ) : cell.column.id === 'programManager' ? (
                                                <ConsultantCell
                                                    key={cell.id}
                                                    consultant={cell.getValue<ProjectConsultantSnapshot>()}
                                                    onClick={(value) =>
                                                        searchFieldClick(
                                                            value,
                                                            searchPrefixes.programManager
                                                        )
                                                    }
                                                />
                                            ) : cell.column.id === 'salesRep' ? (
                                                <ConsultantCell
                                                    key={cell.id}
                                                    consultant={cell.getValue<ProjectConsultantSnapshot>()}
                                                    onClick={(value) =>
                                                        searchFieldClick(
                                                            value,
                                                            searchPrefixes.salesRep
                                                        )
                                                    }
                                                />
                                            ) : cell.column.id === 'projectCoordinator' ? (
                                                <ConsultantCell
                                                    key={cell.id}
                                                    consultant={cell.getValue<ProjectConsultantSnapshot>()}
                                                    onClick={(value) =>
                                                        searchFieldClick(
                                                            value,
                                                            searchPrefixes.projectCoordinator
                                                        )
                                                    }
                                                />
                                            ) : cell.column.id === 'consultants' ? (
                                                <ConsultantsCell
                                                    key={cell.id}
                                                    value={cell.getValue<
                                                        ProjectConsultantSnapshot[]
                                                    >()}
                                                    onClick={(value) =>
                                                        searchFieldClick(
                                                            value,
                                                            searchPrefixes.consultant
                                                        )
                                                    }
                                                />
                                            ) : [
                                                  'tcv',
                                                  'timesheeted',
                                                  'futureBookedFromBookingsByDay',
                                                  'futureBookedFromSlipProjections',
                                                  'remainingTcvFromBookingsByDay',
                                                  'remainingTcvFromSlipProjections',
                                                  'nonTimeSlips',
                                                  'futureBookedDifference',
                                              ].includes(cell.column.id) ? (
                                                <td key={cell.id}>
                                                    <CurrencyFormat
                                                        amount={cell.getValue<number>()}
                                                    />
                                                </td>
                                            ) : cell.column.id === 'actions' ? (
                                                <td key={cell.id} className={styles.actions}>
                                                    <i className={`fa fa-pencil ${styles.clickable}`}
                                                       onClick={() =>
                                                        setUiState({
                                                            ...uiState,
                                                            activeSideBar: {
                                                                type: SideBarType.ProjectBudgetAdjustor,
                                                                projectId
                                                            }})
                                                        }>
                                                    </i>                                                    
                                               </td>
                                            ) : (
                                                <td key={cell.id}>{cell.getValue<string>()}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                                {table.getFooterGroups().map((footerGroup) => (
                                    <tr key={footerGroup.id}>
                                        {footerGroup.headers.map((header) => (
                                            <th key={header.id}>
                                                {header.id === 'projectId' ? (
                                                    <span>Row Count</span>
                                                ) : header.id === 'projectName' ? (
                                                    <span>{rowCount}</span>
                                                ) : header.id === 'tcv' ? (
                                                    <span>
                                                        Total TCV:{' '}
                                                        <CurrencyFormat amount={totalTcv} />
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </tfoot>
                        </BootstrapTable>
                    )}
                </div>
            </PageLayout>
        </ShowSplashView>
    )
}

const FormattedHeader = ({ title, subTitle }: { title: string; subTitle?: string }) => (
    <div>
        <div className={styles.headerTitle}>{title}</div>
        <div className={styles.headerSubTitle}>{subTitle}</div>
    </div>
)

const LinkedCell = ({ value, onClick }: { value: string; onClick: (name: string) => void }) => (
    <td key={value}>
        <LinkedValue value={value} onClick={() => onClick(value)} />
    </td>
)

const DateCell = ({ value }: { value: Date }) => (
    <td className={styles.noLineBreak}>{formatDateShort(value)}</td>
)

const ConsultantCell = ({
    consultant,
    onClick,
}: {
    consultant: ProjectConsultantSnapshot
    onClick: (name: string) => void
}) => (
    <td key={consultant.name}>
        <LinkedValue
            value={consultant.name}
            onClick={() => onClick(consultant.name)}
            title={consultant.squad}
        />
    </td>
)

const ConsultantsCell = ({
    value,
    onClick,
}: {
    value: ProjectConsultantSnapshot[]
    onClick: (name: string) => void
}) => (
    <td>
        {value.map(({ name, squad }, i) => (
            <Fragment key={name}>
                <LinkedValue value={name} onClick={() => onClick(name)} title={squad} />
                {i !== value.length - 1 && <>, </>}
            </Fragment>
        ))}
    </td>
)

export const LinkedValue = ({
    value,
    onClick,
    title,
}: {
    value: string
    onClick: () => void
    title?: string
}) => (
    <button className={styles.linkButton} onClick={onClick} title={title}>
        {value}
    </button>
)

const sortAplha: SortingFn<any> = (rowA, rowB, columnId) =>
    sortingFns.alphanumeric(rowA, rowB, columnId)

const sortAplhaWithObjectProperty: SortingFn<any> = (rowA, rowB, columnId) => {
    return sortingWithObjectProperty(rowA, rowB, columnId)
}

const sortingWithObjectProperty = (rowA: Row<any>, rowB: Row<any>, columnId: string): number => {
    const rowAVal = rowA.getValue<ProjectConsultantSnapshot>(columnId).name
    const rowBVal = rowB.getValue<ProjectConsultantSnapshot>(columnId).name

    if (!rowAVal) {
        return 1
    }
    if (!rowBVal) {
        return -1
    }
    return rowAVal.localeCompare(rowBVal)
}

const filterDate: FilterFn<any> = (row, columnId, value) => {
    const columnValue = row.getValue<string>(columnId)
    const date = moment(columnValue, 'YYYY-MM-DD')
    return !value
        ? true
        : value === 'future'
        ? date.isAfter()
        : value === 'past'
        ? date.isBefore()
        : true
}

const filterText: FilterFn<any> = (row, columnId, value, addMeta) => {
    const columnValue = row.getValue<string>(columnId).toString()
    const itemRank = rankItem(columnValue, value)
    addMeta({
        itemRank,
    })
    return itemRank.passed
}

const DateFilter = ({ column }: { column: Column<any, unknown>; table: Table<any> }) => (
    <select
        className={styles.dateFilter}
        onChange={(e) => column.setFilterValue(e.target.value)}
        value={(column.getFilterValue() ?? '') as string}
    >
        <option value={''}></option>
        <option value={'future'}>Future</option>
        <option value={'past'}>Past</option>
    </select>
)

const TextFilter = ({ column }: { column: Column<any, unknown>; table: Table<any> }) => (
    <DebouncedInput
        type='text'
        value={(column.getFilterValue() ?? '') as string}
        onChange={column.setFilterValue}
        placeholder={'Search'}
        list={column.id + 'list'}
    />
)

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <input
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={styles.textFilter}
        />
    )
}
