import { AppContext } from 'App'
import { HoverableTooltip } from 'components/Shared/HoverableTooltip/HoverableTooltip'
import { apiRoutes } from 'domain/apiRoutes'
import { msalRequest } from 'domain/authService'
import { SideBarType } from 'domain/models'
import { useContext } from 'react'
import { Popover } from 'react-bootstrap'
import styles from './ToolsMenu.module.scss'

interface ToolsMenuProps {
    toggleClass: string
}

export const ToolsMenu = ({ toggleClass }: ToolsMenuProps) => {
    const { uiState, setUiState } = useContext(AppContext)

    const generateIcs = (generateUrl: string) => {
        return msalRequest({ url: generateUrl }).then((data) => (window.location = data))
    }

    return (
        <HoverableTooltip
            tool={
                <button className={toggleClass} title='Tools'>
                    Tools
                </button>
            }
            tip={
                <Popover id='tools-sub-nav-popover'>
                    <ul className={styles.container}>
                        <li>
                            <button
                                title='iCal'
                                onClick={() => generateIcs(apiRoutes.getCalendar)}
                                className={styles.link}
                            >
                                iCal
                            </button>
                        </li>

                        <li>
                            <button
                                title='Budget Estimator'
                                onClick={() =>
                                    setUiState({
                                        ...uiState,
                                        activeSideBar: {
                                            type: SideBarType.ProjectBudgetEstimator,
                                        },
                                    })
                                }
                                className={styles.link}
                            >
                                Budget Estimator
                            </button>
                        </li>
                    </ul>
                </Popover>
            }
        />
    )
}
