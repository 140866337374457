import './SplashView.scss'

interface SplashViewProps {
    message?: string
}

export const SplashView = ({ message }: SplashViewProps) => (
    <div className='app-loading'>
        <div className='logo'>
            <svg className='spinner' viewBox='25 25 50 50'>
                <circle
                    className='path'
                    cx='50'
                    cy='50'
                    r='20'
                    fill='none'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                />
            </svg>
        </div>
        <div className='message'>{message || ''}</div>
    </div>
)
