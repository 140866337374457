const stripTrailingSlash = (str: string): string => str.replace(/\/$/, '')

const host = process.env.REACT_APP_API_URL
    ? stripTrailingSlash(process.env.REACT_APP_API_URL)
    : 'http://localhost:61489'

const projectUpdatesHost = process.env.REACT_APP_READIUPDATES_URL
    ? stripTrailingSlash(process.env.REACT_APP_READIUPDATES_URL)
    : 'https://updates.purple.tech'

export const apiRoutes = {
    getEngagements: host + '/api/engagements',
    getCalendar: host + '/api/ics/generate',
    projectUpdatesDashboard: projectUpdatesHost + '/ManageProjects/RenderProjectDashboard/',
    getGigEndDate: host + '/api/gigplanning/enddate',
    getGigDuration: host + '/api/gigplanning/duration',
    getGigDetails: host + '/api/gigplanning/details',
    getProposedBudget: host + '/api/gigplanning/proposed-budget',
    getTotalConsultantCost: host + '/api/gigplanning/consultant-cost',
    getConsultantRate: host + '/api/gigplanning/consultant-rate',
    getDefaultConsultantRate: host + '/api/gigplanning/default-rate',
    getBookableConsultants: host + '/api/gigplanning/bookable-consultants',
    getProjectBudgetAudit: host + '/api/projects/audit',
    exportProjectBudgetAudit: host + '/api/projects/auditExport',
    exportProjectBurnup: host + '/api/projects/burnupExport',
}
