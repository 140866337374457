import { AccountInfo } from '@azure/msal-common'
import { fetchAppData } from 'domain/appDataService'
import { AppStore, defaultAppStore } from 'domain/models'
import { useState } from 'react'

export const useStore = (loggedInUser: AccountInfo | null) => {
    const [store, setStore] = useState<AppStore>(defaultAppStore)

    // load the main engagements data
    const startStoreLoad = async () => {
        if (!store.isInitialized)
            await loadStore()
    }

    // when called from the refresh timer only re-load the data if it's already been requested/loaded by the current
    // page
    const refreshStore = async () => {
        if (store.isInitialized)
            await loadStore();
    }

    const loadStore = async () => {
        setStore({ ...store, isLoading: true })
        try {
            const data = await fetchAppData()

            const consultants = data.userEngagements
            const loggedInConsultant = data.userEngagements.find(
                (c) => c.email === loggedInUser?.username
            )
            const updatedStore: AppStore = {
                ...store,
                isInitialized: true,
                isLoading: false,
                consultants,
                loggedInConsultant: loggedInConsultant || null,
                projectBudgets: data.projectBudgets,
                holidays: data.holidays,
                resourceGroups: data.resourceGroups,
                squads: data.squads,
                lastDataChange: new Date(data.lastDataChange),
                lastRefreshAttempt: new Date(),
            }
            setStore(updatedStore)
        } catch (e) {
            if (!store.isInitialized) {
                // If initial load fails, set error...
                setStore({
                    ...store,
                    isInitialized: true,
                    isLoading: false,
                    lastRefreshAttempt: new Date(),
                    error: e instanceof Error ? e : new Error('Unknown Error'),
                })
            } else {
                // If polling fails, fail silently
                setStore({
                    ...store,
                    isLoading: false,
                    lastRefreshAttempt: new Date(),
                })
            }
        }
    }

    return { store, startStoreLoad, refreshStore }
}
