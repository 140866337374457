import { AppContext } from 'App'
import { ViewType } from 'domain/models'
import { useContext } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './ResetFiltersButton.module.scss'

type ResetFiltersButtonProps = {
    resetFilters: () => void
}

const FinePrint = ({ children }: { children: React.ReactNode }) => (
    <span className={styles.finePrint}>{children}</span>
)

export const ResetFiltersButton = ({ resetFilters }: ResetFiltersButtonProps) => {
    const {
        store: { loggedInConsultant },
    } = useContext(AppContext)

    return (
        <OverlayTrigger
            placement='bottom'
            overlay={
                <Popover id='reset-button-tooltip'>
                    <div className={styles.info}>
                        <p>Peeps pushes your filters to the URL.</p>
                        <p>This button resets them to their defaults, which are:</p>
                        <ul>
                            <li>
                                Squads <FinePrint>= Your home squad</FinePrint>
                            </li>
                            <li>
                                Resource Groups{' '}
                                <FinePrint>
                                    = All the resource groups that pertain to your home squad
                                </FinePrint>
                            </li>
                            <li>
                                View Type <FinePrint>= {ViewType.Full.toString()}</FinePrint>
                            </li>
                            <li>
                                Show Terminated <FinePrint>= False</FinePrint>
                            </li>
                            <li>
                                Show Non-Billable <FinePrint>= True</FinePrint>
                            </li>
                        </ul>
                        {!loggedInConsultant?.squad && (
                            <p>
                                <b>NOTE:</b> Your home squad could not be determined, so by default
                                all squads will be selected.
                            </p>
                        )}
                    </div>
                </Popover>
            }
        >
            <button type='button' className={styles.button} onClick={resetFilters}>
                Reset
            </button>
        </OverlayTrigger>
    )
}
