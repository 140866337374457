import styles from './ConsultantInfoHelp.module.scss'

export const ConsultantInfoHelp = () => (
    <div className={styles.container}>
        <h1>About Consultant Attributes</h1>
        <p>
            Consultant attributes are provided by a combination of OpenAir and NetSuite fields. If
            you notice an error you may have to contact{' '}
            <a href='https://it.purple.tech' target='_blank' rel='noreferrer'>
                it.purple.tech
            </a>{' '}
            or <a href='mailto:nasopenair@team.telstra.com'>nasopenair@team.telstra.com</a> to have
            it resolved.
        </p>

        <p>
            <b>For TPPL peeps:</b> Squad, Role & Capability come from a combination of the NetSuite
            employee fields for &apos;Vertical&apos;, &apos;Geography&apos;, &apos;Job Title&apos; &
            &apos;Department&apos;. If any of these fields are blank or unassigned, the data will
            come from their corresponding OpenAir fields. It&apos;s possible this metadata could
            also be incorrect if the persons bookings have not been loaded. That can happen if a
            squad is added/restructured without updating the data filters within peeps, but that
            should be a rare occurrence.
        </p>

        <p>
            <b>For TCORP peeps:</b> Squad & Role come from OpenAir &apos;Job Code&apos; (capability
            is always &apos;Default&apos;.)
        </p>

        <p>
            <b>For all peeps:</b> The default billing rate (used in project budget tools) is based
            on the OpenAir &apos;Job Code&apos; and the &apos;Purple Standard Sell Rates&apos; for
            each Job Code. If the rate is wrong for TPPL peeps it&apos;s possible they have the
            incorrect Job Code in OpenAir, despite peeps showing the correct role (from NetSuite.)
        </p>

        <p>
            Michelle Turner and Roma Bautista are the most knowledgeable SMEs for this data. These
            information sources may change with the migration to Workday beginning late 2022.
        </p>
    </div>
)
