import { ReactElement, useEffect, useRef, useState } from 'react'
import { Placement } from 'react-bootstrap/esm/types'
import { useHover } from 'react-use'
import styles from './HoverableTooltip.module.scss'
import * as Popper from '@popperjs/core'
import { OverlayTrigger } from 'react-bootstrap'
import { delay } from 'components/ProjectsView/projectsViewHelpers'
import { useDebouncedCallback } from 'domain/hooks/useDebouncedCallback'

export type Modifiers = Popper.Options['modifiers']

type HoverableTooltipProps = {
    tool: ReactElement
    tip: ReactElement
    placement?: Placement
    modifiers?: Modifiers
}

export const HoverableTooltip = (props: HoverableTooltipProps) => {
    const toolRef = useRef<any>(null)
    const [show, setShow] = useState(false)
    const [toolHoverable, isToolHovered] = useHover(
        <div className={styles.root} ref={toolRef}>
            {props.tool}
        </div>
    )
    const [tipHoverable, isTipHovered] = useHover(props.tip)

    /**
     * This is a solution for the scenario where the overlay is hidden when the mouse leaves the trigger area but moves to the overlay
     * The overloay should be considered a part of the trigger but is not.
     * we want the overlay to remain open so the user can interact with it.
     */
    const debounceHoverState = useDebouncedCallback(() => {
        if (!isToolHovered && !isTipHovered) {
            setShow(false)
        }
    }, 500)

    useEffect(() => {
        debounceHoverState()
    }, [isToolHovered, isTipHovered, debounceHoverState])

    return (
        <>
            <OverlayTrigger
                show={show}
                target={toolRef.current}
                delay={{ show: 700, hide: 0 }}
                onToggle={(show) => {
                    if (show) {
                        setShow(true)
                    }
                }}
                placement={props.placement || 'auto-start'}
                popperConfig={{
                    modifiers: props.modifiers,
                }}
                overlay={tipHoverable}
            >
                {toolHoverable}
            </OverlayTrigger>
        </>
    )
}
