import styles from './ProjectBudgetAuditHelp.module.scss'

const openAirDatabaseName = 'OpenAir'

export const ProjectBudgetAuditHelp = () => (
    <div className={styles.container}>
        <h1>How Project Budgets are Calculated</h1>

        <p>Peeps shows the following project budget numbers:</p>

        <ul>
            <li>TCV</li>
            <li>Timesheeted (TS)</li>
            <li>Slips</li>
            <li>Future Bookings</li>
            <li>Remaining TCV</li>
        </ul>

        <h3>TCV</h3>
        <ul>
            <li>
                <b>TCV stands for the &quot;Total Contract Value&quot; of the project</b>
            </li>
            <li>
                The data for this comes from the <code>Projects</code> table in the{' '}
                <code>{openAirDatabaseName}</code> database, from the <code>TCV</code> column
            </li>
        </ul>

        <h3>Timesheeted (TS)</h3>
        <ul>
            <li>
                Is the total value of all timesheets submitted by consultants in OpenAir for the
                Project
            </li>
            <li>
                The data for this comes from the <code>Tasks</code> table in the{' '}
                <code>{openAirDatabaseName}</code> database
            </li>
        </ul>

        <h3>Slips</h3>
        <ul>
            <li>
                When a timesheet is submitted it can take a day or more before the corresponding
                Slips (Billing entries) are generated
            </li>
            <li>
                The data for this comes from the <code>Slips</code> table in the{' '}
                <code>{openAirDatabaseName}</code> database, queried by{' '}
                <code>Project+Consultant</code> combination
            </li>
            <li>
                The <code>Slips</code> table contains rows which include columns <code>Date</code>,{' '}
                <code>Hours</code>, <code>Rate</code>, and <code>Total</code>
            </li>
            <li>
                The <code>Timesheeted</code> value is the sum of the <code>Total</code>s column from
                all the queried rows
            </li>
            <li>
                <b>NOTE:</b> Only submitted timesheets appear in the <code>Slips</code> table -
                saved, but not submitted timesheets do NOT appear in the <code>Slips</code> table
            </li>
        </ul>

        <h3>Future Bookings</h3>
        <ul>
            <li>
                <b>
                    FutureBookings is the total value of all consultant bookings on the project that
                    are not yet timesheeted
                </b>
            </li>
            <li>
                The data for this calculation has 2 parts: <code>Hours</code> (per booked day) and{' '}
                <code>Rate</code> for each consultant
                <ul>
                    <li>
                        <code>Hours</code> comes from the <code>BookingsByDay</code> table in the{' '}
                        <code>{openAirDatabaseName}</code> database, queried by{' '}
                        <code>Project+Consultant</code> combination, where the{' '}
                        <code>BookingsByDay.Date</code> is greater than the latest{' '}
                        <code>Slips.Date</code> record for each consultant,{' '}
                        <b>across all projects</b>
                    </li>
                    <li>
                        <code>Rate</code> comes from one of two places, in order of preference:
                        <ol>
                            <li>
                                <code>SlipProjections.Rate</code>, the first available non-zero
                                value
                                <ul>
                                    <li>
                                        <b>NOTE:</b> When a <code>Rate</code> value is available for
                                        a <code>Project+Consultant</code> combination in{' '}
                                        <code>SlipProjections</code>, that rate is consistent across
                                        all such rows, and thus the first non-zero value can be
                                        reliably used
                                    </li>
                                    <li>
                                        <b>NOTE:</b> Sometimes <code>SlipProjections</code> contains
                                        rows with zero <code>Rate</code> value. It is unclear why
                                        these zero values occur, but for Peeps purposes such rows
                                        are ignored. For more info on the{' '}
                                        <code>SlipProjections</code> table, see the &quot;OpenAir
                                        Database&quot; section below
                                    </li>
                                    <li>
                                        When a project <code>Rate</code> cannot be found for a given
                                        <code>Project+Consultant</code> combination in{' '}
                                        <code>SlipProjections</code>, an attempt is made to obtain a
                                        value from <code>Slips.Rate</code>...
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <code>Slips.Rate</code>, the first available non-zero value
                                <ul>
                                    <li>
                                        <b>NOTE:</b> In rare cases there are multiple rates found
                                        for a <code>Project+Consultant</code> combination, and in
                                        which case, the first available rate is used
                                    </li>
                                    {/* -- TODO - this could cause issues, but seems to be
                                        unavoidable */}
                                    <li>
                                        If a <code>Rate</code> value is not able to be obtained from
                                        either <code>SlipProjections</code> or <code>Slips</code>, the
                                        default rate for the consultants' role is used and a warning
                                        is shown
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ul>
                <li>
                    The <code>FutureBookings</code> value is the sum of the <code>Total</code>s (
                    <code>Hours</code> x <code>Rate</code>) for all <b>future</b>{' '}
                    <code>BookingsByDay.Date</code> rows, for all consultant on the project
                </li>
            </li>
        </ul>

        <h3>Remaining TCV</h3>
        <ul>
            <li>
                <b>RemainingTCV is TCV not accounted for in Timesheets or FutureBookings</b>
            </li>
            <li>
                This is calculated as <code>TCV - Timesheeted - FutureBookings = RemainingTCV</code>
            </li>
            <li>
                <b>NOTE:</b> The ideal case is that &quot;Remaining TCV&quot; should be zero,
                meaning the full budget has been utilized, without waste or overrun
                <ul>
                    <li>
                        A positive RemainingTCV means that <code>Timesheeted + FutureBookings</code>{' '}
                        does not fully utilize the available project budget and we could consider
                        booking more time for this client
                    </li>
                    <li>
                        A negative RemainingTCV means that <code>Timesheeted + FutureBookings</code>{' '}
                        exceeds the available TCV and our consultants are booked to work more than
                        the approved project budget
                    </li>
                </ul>
            </li>
        </ul>

        <h3>FutureBookings (By SlipProjections)</h3>
        <ul>
            <li>
                <b>
                    Like FutureBookings above, FutureBookingsBySlipProjections is a total value of
                    all consultant bookings on the project that are not yet timesheeted
                </b>
            </li>
            <li>
                The data for this comes from the <code>SlipProjections</code> table in the{' '}
                <code>{openAirDatabaseName}</code> database, queried by{' '}
                <code>Project+Consultant</code> combination
            </li>
            <li>
                The <code>SlipProjections</code> table contains rows which include columns{' '}
                <code>Date</code>, <code>Hours</code>, <code>Rate</code>, and <code>Total</code>
            </li>
            <li>
                The <code>FutureBookingsBySlipProjections</code> value is the sum of the{' '}
                <code>Total</code>s for all of the <code>SlipProjections.Date</code> rows, for all
                consultants on the project
            </li>
            <li>
                <code>FutureBookingsBySlipProjections</code> is compared with{' '}
                <code>FutureBookings</code> to determine whether or not a budget may be considered
                accurate
            </li>
            <li>
                For more info on the <code>SlipProjections</code> table, see the &quot;OpenAir
                Database&quot; section below
            </li>
        </ul>

        <h2>OpenAir Database</h2>
        <p>
            Peeps derives project budget numbers from tables in the{' '}
            <code>{openAirDatabaseName}</code> database
        </p>
        <ul>
            <li>
                <code>Projects</code> table contains project information entered by Workforce
                Planners when adding/editing projects via the OpenAir UI
            </li>
            <li>
                <code>Slips</code> table contains timesheet information entered by consultants via
                the OpenAir UI
            </li>
            <li>
                <code>BookingsByDay</code> table contains consultant project bookings by day,
                populated when Workforce Planners add/edit bookings via the OpenAir UI
            </li>
            <li>
                <code>SlipProjections</code> table contains an automatically generated forecast of
                Slips that are updated when OpenAir billing rules run (usually scheduled nightly)
                <ul>
                    <li>
                        We have found that the data in this table is not always consistent and
                        reliable, which is why we don&apos;t rely on it for the main{' '}
                        <code>FutureBookings</code> number, preferring instead to use{' '}
                        <code>BookingsByDay</code>
                    </li>
                </ul>
            </li>
        </ul>

        <h2>Additional Notes</h2>

        <ul>
            <li>
                <code>Project+Consultant</code> combination refers to querying by{' '}
                <code>ProjectId</code> and <code>UserId</code>, where <code>UserId</code> is the
                &quot;Consultant ID&quot;
            </li>
            <li>
                When we refer to <code>Hours</code> column above, we are actually referring to a
                combining of <code>Hour</code> + <code>Minute</code> columns in the relevant tables
            </li>
            <li>
                Previously (before June 2022), Peeps (like <b>Purple Insights</b>) relied
                exclusively on {openAirDatabaseName} SlipProjections to calculate Future Bookings,
                but those are often incorrect. Peeps now calculates primarily based on bookings, but
                warns if there&apos;s a risk the calculations may be inaccurate
            </li>
        </ul>
    </div>
)
