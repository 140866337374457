import { NavBar } from 'components/Shared/PageLayout/NavBar/NavBar'
import { ReactNode } from 'react'
import styles from './PageLayout.module.scss'
import { SideBar } from './SideBar/SideBar'

interface PageProps {
    children: ReactNode
}

export const PageLayout = ({ children }: PageProps) => (
    <>
        <NavBar />
        <div className={styles.page}>{children}</div>
        <SideBar />
    </>
)
