import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { PageLayout } from 'components/Shared/PageLayout/PageLayout'
import { useMountEffect } from 'domain/hooks/useMountEffect'

interface ErrorViewProps {
    error: Error | null
    isLoginError?: boolean
}

export const ErrorView = ({ error, isLoginError }: ErrorViewProps) => {
    const getErrorMessage = (error: Error | null, isLoginError?: boolean) => {
        if (isLoginError) {
            if (error instanceof InteractionRequiredAuthError) {
                // The signed-in user '<email-address>' is not assigned to a role for the application '<app-id>' (<app-name>)
                if (error.errorMessage.indexOf('AADSTS50105') !== -1) {
                    return "You haven't been given access to PurplePeeps."
                }
            }
            return 'An error occurred while trying to log you in to PurplePeeps.'
        } else {
            if (error instanceof Error) {
                return error.message
            } else {
                return 'Unknown error.'
            }
        }
    }

    useMountEffect(() => {
        console.error(error)
    })

    return (
        <PageLayout>
            <div className={'container-fluid'}>
                <h1>An error occurred</h1>
                {isLoginError && (
                    <p>
                        Please make sure you are using either your <code>purple.telstra.com</code>{' '}
                        or <code>team.telstra.com</code> account.
                    </p>
                )}
                <p>
                    If the problem persists, please open a service request at{' '}
                    <a href='https://it.purple.tech'>https://it.purple.tech</a> and include the
                    error message below:
                </p>
                <pre>{getErrorMessage(error, isLoginError)}</pre>
            </div>
        </PageLayout>
    )
}
