import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styles from '../DropdownSelect.module.scss'

interface DropdownMultiselectProps {
    name: string
    options: string[]
    selected: string[]
    onChange: (selected: string[]) => void
    buttonClass?: string
    entityTypePluralName?: string
}

// Copied with modification from https://github.com/kfrancikowski/react-multiselect-dropdown-bootstrap/blob/master/src/index.js

export const MultiSelect = (props: DropdownMultiselectProps) => {
    const [state, setState] = useState({
        showDropdown: false,
    })

    const ref = useRef(null)
    useClickAway(ref, () => setState({ ...state, showDropdown: false }))

    const isAllSelected = props.selected.length === props.options.length

    const handleChange = (e: any) => {
        if (e.currentTarget === undefined) {
            return
        }

        let currentSelected = [...props.selected]
        let value = e.currentTarget.value

        if (e.currentTarget.checked) {
            currentSelected.push(value)
        } else {
            const index = currentSelected.indexOf(value)
            currentSelected.splice(index, 1)
        }

        props.onChange(currentSelected)
    }

    const handleSelectDeselectAll = () => {
        if (isAllSelected) {
            props.onChange([])
        } else {
            props.onChange(props.options)
        }
    }

    const getPlaceholderValue = () =>
        props.selected.length === 0
            ? props.entityTypePluralName
                ? `No ${props.entityTypePluralName} Selected`
                : 'None selected'
            : isAllSelected
            ? props.entityTypePluralName
                ? `(${props.selected.length}) All ${props.entityTypePluralName} Selected`
                : `All selected (${props.selected.length})`
            : props.selected.length > 1
            ? `${props.selected.length} selected`
            : props.selected[0]

    const handleClick = () => {
        setState({
            showDropdown: !state.showDropdown,
        })
    }

    return (
        <div className='dropdown' ref={ref}>
            <button
                className={classNames(
                    'dropdown-toggle',
                    'form-control',
                    props.buttonClass,
                    styles.dropdownToggle
                )}
                type='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={() => handleClick()}
                title={props.entityTypePluralName ?? props.name}
            >
                <span className={styles.overflowHandler}>{getPlaceholderValue()}</span>
            </button>
            <div
                className={classNames(
                    'dropdown-menu',
                    !!state.showDropdown && 'show',
                    styles.dropdownMenu
                )}
            >
                <div
                    className={classNames(
                        'dropdown-item',
                        styles.dropdownItem,
                        isAllSelected && styles.active
                    )}
                >
                    <div className={classNames('form-check', styles.formCheck, styles.selectAll)}>
                        <input
                            id={`multiselect-${props.name}-all`}
                            className='form-check-input'
                            type='checkbox'
                            onChange={handleSelectDeselectAll}
                            checked={isAllSelected}
                        />
                        <label
                            className='form-check-label'
                            htmlFor={`multiselect-${props.name}-all`}
                        >
                            Select All
                            {props.entityTypePluralName && ` ${props.entityTypePluralName}`}
                        </label>
                    </div>
                </div>

                {props.options.map((option, index) => (
                    <div
                        key={index}
                        className={classNames(
                            'dropdown-item',
                            styles.dropdownItem,
                            props.selected.includes(option) && styles.active
                        )}
                    >
                        <div className={classNames('form-check', styles.formCheck)}>
                            <input
                                value={option}
                                id={`multiselect-${props.name}-${index}`}
                                className='form-check-input'
                                type='checkbox'
                                name={`${props.name}[]`}
                                onChange={(ev) => handleChange(ev)}
                                checked={props.selected.includes(option)}
                            />
                            <label
                                className='form-check-label'
                                htmlFor={`multiselect-${props.name}-${index}`}
                            >
                                {option}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
