import { AppContext } from 'App'
import Moment from 'moment'
import { useContext } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styles from './NavBarStatusIndicator.module.scss'

export const NavBarStatusIndicator = () => {
    const {
        store: { lastDataChange, lastRefreshAttempt, isLoading },
    } = useContext(AppContext)

    return (
        <OverlayTrigger
            placement='bottom'
            overlay={
                <Tooltip id='status-indicator-tooltip'>
                    <div>{lastDataChange ? Moment(lastDataChange).calendar() : 'Never'}</div>
                    <div className='tooltip-heading'>Last checked</div>
                    <div>
                        {lastRefreshAttempt ? Moment(lastRefreshAttempt).calendar() : 'Never'}
                    </div>
                </Tooltip>
            }
        >
            <span className={styles.icon}>
                <i
                    className={
                        isLoading
                            ? 'fa fa-spinner fa-pulse fa-fw fa-2x'
                            : 'fa fa-check-circle-o fa-2x'
                    }
                />
            </span>
        </OverlayTrigger>
    )
}
