import {
    AccountInfo,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser'
import { appInsights } from 'misc/appInsights'

const AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID
const AAD_REDIRECT_URI = process.env.REACT_APP_CLIENT_URL

const AuthConfig = {
    tenantId: 'fc6a7adc-53de-44ad-88fc-af783bbb1d6e',
    clientId: AAD_CLIENT_ID || '0ba8e58d-2c8b-40e7-8287-c86839e647ae',
}

const configMsal = {
    auth: {
        clientId: AuthConfig.clientId,
        authority: `https://login.microsoftonline.com/${AuthConfig.tenantId}`,
        redirectUri: AAD_REDIRECT_URI || 'http://localhost:3000',
    },
    cache: {
        cacheLocation: 'localStorage',
    },
}

const msalApp = new PublicClientApplication(configMsal)

let loggedInUser: AccountInfo | null = null
let loginError: Error | null = null

const apiScope = `${AuthConfig.clientId}/.default`

const loginRequest: RedirectRequest = {
    scopes: [apiScope],
}

const tokenRequest: Omit<SilentRequest, 'account'> = {
    scopes: [apiScope],
}

async function signInUser() {
    /**
     * Logic of this method is as follows:
     *
     * 1. Try to handle a redirect request, in case we got redirected back from AAD,
     *    so that we acquire a fresh set of tokens.
     *    That can have 3 outcomes:
     *      - A successful redirect request is handled, and we get a new account and set of tokens
     *      - A failing redirect request is handled, and we get an exception (think AAD error)
     *      - The current request is not a redirect, and we don't get anything in return
     *
     * 2. If the first step detects a sign-in error, we store the login error to pass it to the main component.
     *
     * 3. If the first step is successful but doesn't return an account, we look for an account stored by MSAL in the cache.
     *
     * 4. Based on the result of the previous steps, we have 3 outcomes:
     *     - We found an account, so we try to silently fetch an access token; or
     *     - We didn't find an account, and we trigger a login redirect; or
     *     - We detected a sign-in error, and we do nothing else.
     *
     *    If silently fetching an access token doesn't work out, we fall back to triggering a login redirect.
     */

    try {
        const handleRedirectResult = await msalApp.handleRedirectPromise()
        if (handleRedirectResult && handleRedirectResult.account) {
            loggedInUser = handleRedirectResult.account
        }
    } catch (e) {
        const loginError = e as Error
        appInsights.trackException({
            exception: loginError,
            properties: {
                reason: 'handling-redirect-request',
            },
        })

        return
    }

    if (loggedInUser === null) {
        const allAccounts = msalApp.getAllAccounts()
        if (!!allAccounts && Array.isArray(allAccounts)) {
            const matchingAccount = allAccounts.find((x) => x.tenantId === AuthConfig.tenantId)
            if (!!matchingAccount) {
                loggedInUser = matchingAccount
            }
        }
    }

    if (loggedInUser !== null) {
        await getAccessToken()
    } else {
        await triggerLoginRedirect(
            new Error(
                'No cached account present, nor did we successfully handle a redirect request.'
            )
        )
    }
}

async function getAccessToken() {
    try {
        const tokenResponse = await msalApp.acquireTokenSilent({
            ...tokenRequest,
            account: loggedInUser!,
        })

        if (!tokenResponse) throw new Error('Unable to get access token.')

        return tokenResponse.accessToken
    } catch (e) {
        await triggerLoginRedirect(e as Error)
    }
}

async function triggerLoginRedirect(error: Error) {
    appInsights.trackException({
        exception: error,
        properties: {
            reason: 'triggering-login-redirect',
        },
    })

    // Flush as we're about to redirect the user
    appInsights.flush()

    await msalApp.loginRedirect(loginRequest)

    // Throwing the error which prompted us to initiate the login redirect
    // as there's a delay between the resolution of the Promise and the user being redirected to AAD,
    // and we don't want the app to think it's all good and initiate an API call.
    throw error
}

async function msalRequest(settings: { url: string }) {
    const token = await getAccessToken()
    const response = await fetch(settings.url, {
        method: 'GET',
        headers: {
            accept: 'application/json',
            authorization: `Bearer ${token}`,
        },
    })

    if (response.ok) {
        return await response.json()
    } else {
        throw new Error(response.statusText)
    }
}

async function msalPostRequest(settings: { url: string }, data: any) {
    const token = await getAccessToken()
    const response = await fetch(settings.url, {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })

    if (response.ok) {
        return await response.json()
    } else {
        throw new Error(response.statusText)
    }
}

async function msalDownloadRequest(settings: {
    url: string
}): Promise<{ fileName?: string; fileContent: Blob }> {
    const token = await getAccessToken()
    const response = await fetch(settings.url, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
        },
    })

    if (response.ok) {
        const fileContent = await response.blob()
        const header = response.headers.get('Content-Disposition')
        const fileNameParts = header
            ?.split(';')
            .find((x) => x.trim().startsWith('filename'))
            ?.split('=')
        const fileName =
            fileNameParts && fileNameParts.length > 1
                ? fileNameParts[1].replace(/(^"|"$)/g, '') // remove wrapping double quotes
                : undefined
        return { fileName, fileContent }
    } else {
        throw new Error(response.statusText)
    }
}

export {
    signInUser,
    loggedInUser,
    loginError,
    getAccessToken,
    msalRequest,
    msalPostRequest,
    msalDownloadRequest,
}
