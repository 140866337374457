import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { HoverableTooltip, Modifiers } from 'components/Shared/HoverableTooltip/HoverableTooltip'
import { Consultant, EngagementEntry, ProjectBudget } from 'domain/models'
import { Tooltip } from 'react-bootstrap'
import styles from './EngagementTooltip.module.scss'
interface IEngagementTooltipProps {
    consultant: Consultant
    engagementEntry: EngagementEntry
    budget: ProjectBudget | undefined
    displayText: string
    openBudgetAdjustmentCalculator: (projectId: number) => void
}

export const EngagementTooltip = ({
    consultant,
    engagementEntry: engagement,
    budget,
    displayText,
    openBudgetAdjustmentCalculator,
}: IEngagementTooltipProps) => {
    const { projectId, clientId } = engagement
    const telstraClientId = 1
    const withUrl = projectId && clientId !== telstraClientId

    const enableTooltipFlip: Modifiers[0] = {
        name: 'flip',
        enabled: true,
    }

    const emptyBudget =
        budget &&
        budget.tcv === 0 &&
        budget.futureBookedFromSlipProjections === 0 &&
        budget.timesheeted === 0

    const remainingPlannedHours =
        consultant &&
        consultant.email &&
        budget?.consultants?.find((c) => c.email === consultant.email)?.plannedHoursRemaining

    return (
        <HoverableTooltip
            placement='top-start'
            modifiers={[enableTooltipFlip]}
            tool={
                <span style={{ width: '100%', height: '100%' }}>
                    {withUrl && (
                        <a
                            href={projectId ? `/project/${projectId}` : ''}
                            target='_blank'
                            rel='noopener noreferrer'
                            style={{ color: engagement.foregroundColor }}
                        >
                            {displayText}
                        </a>
                    )}
                    {!withUrl && (
                        <span style={{ color: engagement.foregroundColor }}>{displayText}</span>
                    )}
                </span>
            }
            tip={
                <Tooltip id='engagement-tooltip' className={styles.tooltip}>
                    <span className={styles.popperConfig}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Client</td>
                                    <td>{engagement.client}</td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{engagement.project}</td>
                                </tr>
                                <tr>
                                    <td>Task</td>
                                    <td>
                                        {engagement.task}
                                        {engagement.pdPlanType} ({engagement.hours})
                                    </td>
                                </tr>
                                {!!engagement.salesRepName && (
                                    <tr>
                                        <td>Sales</td>
                                        <td>{engagement.salesRepName}</td>
                                    </tr>
                                )}
                                {!!engagement.projectCoordinatorName && (
                                    <tr>
                                        <td>Project Coordinator</td>
                                        <td>{engagement.projectCoordinatorName}</td>
                                    </tr>
                                )}
                                {!!budget && (
                                    <tr className={styles.preline}>
                                        <td>
                                            {!emptyBudget ? (
                                                <button
                                                    className={styles.editBudget}
                                                    onClick={() =>
                                                        openBudgetAdjustmentCalculator(projectId)
                                                    }
                                                >
                                                    Budget <i className='fa fa-pencil'></i>
                                                </button>
                                            ) : (
                                                'Budget'
                                            )}
                                        </td>
                                        <td>
                                            <div>
                                                TCV: <CurrencyFormat amount={budget.tcv} />
                                            </div>
                                            <div>
                                                Timesheeted:{' '}
                                                <CurrencyFormat amount={budget.timesheeted} />
                                            </div>
                                            <div>
                                                Future Bookings:{' '}
                                                <CurrencyFormat
                                                    amount={budget.futureBookedFromBookingsByDay}
                                                />
                                            </div>
                                            <div>
                                                Remaining TCV (unallocated budget):{' '}
                                                <CurrencyFormat
                                                    amount={budget.remainingTcvFromBookingsByDay}
                                                />
                                            </div>
                                            {!!remainingPlannedHours && (
                                                <div>
                                                    {`Remaining Planned Hours ${consultant.name}: ${remainingPlannedHours}`}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </span>
                </Tooltip>
            }
        />
    )
}
