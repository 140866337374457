import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { ProjectBudget } from 'domain/models'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './BudgetAdjustmentTable.module.scss'

interface IBudgetAdjustmentTableProps {
    projectBudget: ProjectBudget
    budgetAdjustments: number
    isCalculating: boolean
}

export function BudgetAdjustmentTable(props: IBudgetAdjustmentTableProps) {
    const newFutureBooked =
        props.budgetAdjustments !== 0
            ? props.projectBudget.futureBookedFromBookingsByDay + props.budgetAdjustments
            : '-'
    const newRemainingTcv =
        props.budgetAdjustments !== 0
            ? props.projectBudget.remainingTcvFromBookingsByDay - props.budgetAdjustments
            : '-'
    const newRemainingTcvClassName =
        props.budgetAdjustments > 0 ? `${styles.positive}` : `${styles.negative}`

    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td></td>
                        <td>Current</td>
                        <td>Proposed</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>TCV</td>
                        <td>
                            <CurrencyFormat amount={props.projectBudget.tcv} />
                        </td>
                        <td>
                            <CurrencyFormat amount={props.projectBudget.tcv} />
                        </td>
                    </tr>
                    <tr>
                        <td>Timesheeted</td>
                        <td>
                            <CurrencyFormat amount={props.projectBudget.timesheeted} />
                        </td>
                        <td>
                            <CurrencyFormat amount={props.projectBudget.timesheeted} />
                        </td>
                    </tr>
                    <tr>
                        <td>Future Bookings</td>
                        <td>
                            <CurrencyFormat
                                amount={props.projectBudget.futureBookedFromBookingsByDay}
                            />
                        </td>
                        <td>
                            {props.isCalculating && (
                                <span>
                                    <i className='fa fa-spinner fa-pulse fa-fw'></i>
                                    Loading
                                </span>
                            )}
                            {!props.isCalculating && <CurrencyFormat amount={newFutureBooked} />}
                        </td>
                    </tr>
                    <tr>
                        <td>Remaining TCV</td>
                        <td>
                            <CurrencyFormat
                                amount={props.projectBudget.remainingTcvFromBookingsByDay}
                            />
                        </td>
                        <td>
                            {props.isCalculating && (
                                <span>
                                    <i className='fa fa-spinner fa-pulse fa-fw'></i>
                                    Loading
                                </span>
                            )}
                            {!props.isCalculating && (
                                <CurrencyFormat
                                    amount={newRemainingTcv}
                                    className={newRemainingTcvClassName}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Link className={styles.linkButton}
                to={`/project/${props.projectBudget.projectId}`} target='_blank' >
                Project Details
            </Link>
        </>
    )
}
