import { HoverableTooltip } from 'components/Shared/HoverableTooltip/HoverableTooltip'
import { Popover } from 'react-bootstrap'
import styles from './FilterList.module.scss'

interface IFilterListProps {
    showTerminated: boolean
    toggleTerminated: () => void
    showNonBillable: boolean
    toggleNonBillable: () => void
}

export const FilterList = (props: IFilterListProps) => (
    <HoverableTooltip
        tool={
            <button className={styles.filterButton}>
                <i className='fa fa-regular fa-filter'></i>
                Filter
                <span>{props.showNonBillable || props.showTerminated ? 'On' : 'Off'}</span>
            </button>
        }
        tip={
            <Popover id='filter-list-popover'>
                <div className={styles.filters}>
                    <div className={styles.filter}>
                        <label htmlFor='terminated-checkbox'>Show Terminated</label>
                        <input
                            id='terminated-checkbox'
                            type='checkbox'
                            checked={props.showTerminated}
                            onChange={props.toggleTerminated}
                        ></input>
                    </div>
                    <div className={styles.filter}>
                        <label htmlFor='nonbillable-checkbox'>Show Non-Billable</label>
                        <input
                            id='nonbillable-checkbox'
                            type='checkbox'
                            checked={props.showNonBillable}
                            onChange={props.toggleNonBillable}
                        ></input>
                    </div>
                </div>
            </Popover>
        }
        placement='bottom'
    />
)
