import { AccountInfo } from '@azure/msal-common'
import { AboutView } from 'components/AboutView/AboutView'
import { ErrorView } from 'components/ErrorView/ErrorView'
import { PeepsView } from 'components/PeepsView/PeepsView'
import { ProjectBudgetAuditView } from 'components/ProjectBudgetAuditView/ProjectBudgetAuditView'
import { ProjectView } from 'components/ProjectView/ProjectView'
import { ProjectsView } from 'components/ProjectsView/ProjectsView'
import { SplashView } from 'components/SplashView/SplashView'
import { useFilters } from 'domain/hooks/useFilters'
import { useStore } from 'domain/hooks/useStore'
import { useUiState } from 'domain/hooks/useUiState'
import {
    defaultAppStore,
    defaultFilterState,
    defaultUiState,
    FilterState,
    IAppContext,
    UiState,
} from 'domain/models'
import { createContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useInterval } from 'react-use'

const REFRESH_DATA_INTERVAL_IN_SECONDS = !!process.env.REACT_APP_REFRESH_DATA_INTERVAL_IN_SECONDS
    ? parseInt(process.env.REACT_APP_REFRESH_DATA_INTERVAL_IN_SECONDS, 10)
    : 300

export const AppContext = createContext<IAppContext>({
    store: defaultAppStore,
    filters: defaultFilterState,
    setFilters: (_: FilterState) => {},
    resetFilters: () => {},
    uiState: defaultUiState,
    setUiState: (_: UiState) => {},
    startStoreLoad: () => Promise.resolve(),
})

type AppProps = {
    loggedInUser: AccountInfo | null
    loginError: Error | null
}

export const App = ({ loggedInUser, loginError }: AppProps) => {
    const { store, startStoreLoad, refreshStore } = useStore(loggedInUser)
    const { filters, setFilters, resetFilters } = useFilters(store)
    const { uiState, setUiState } = useUiState()

    // Periodically refreshData...
    useInterval(() => {
        refreshStore()
    }, REFRESH_DATA_INTERVAL_IN_SECONDS * 1000)

    // To avoid error screens on browser tabs left open overnight, only show login error if store is NOT initialized.
    // Expired tokens will automatically trigger a redirect on next refreshData, so there is no need to alarm the user with an error screen in the morning.
    const showLoginError = !store.isInitialized && !loggedInUser

    return (
        <AppContext.Provider
            value={{
                store,
                filters,
                setFilters,
                resetFilters,
                uiState,
                setUiState,
                startStoreLoad,
            }}
        >
            {showLoginError ? (
                <ErrorView error={loginError} isLoginError />
            ) : !!store.error ? (
                <ErrorView error={store.error} />
            ) : (
                <Switch>
                    <Route path='/about'>
                        <AboutView />
                    </Route>
                    <Route path='/projects'>
                        <ProjectsView />
                    </Route>
                    <Route path='/project/budget/audit/:projectId'>
                        <ProjectBudgetAuditView />
                    </Route>
                    <Route path='/project/:projectId'>
                        <ProjectView />
                    </Route>
                    <Route path='/'>
                        <PeepsView />
                    </Route>
                    <Redirect to='/' />
                </Switch>
            )}
        </AppContext.Provider>
    )
}

export const ShowSplashView = (props: { children?: React.ReactNode; showSplashView: boolean }) => {
    return props.showSplashView ? <SplashView /> : <>{props.children}</>
}
