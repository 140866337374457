import { HoverableTooltip } from 'components/Shared/HoverableTooltip/HoverableTooltip'
import { PeepsProject } from 'domain/models'
import { Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './PeepConsultantCell.module.scss'

export const PeepHeaderCell = (project: PeepsProject) => {
    return (
        <div className={styles.projectHeader}>
            <div className={styles.container}>
                <HoverableTooltip
                    tool={<>{project.projectName}</>}
                    tip={
                        <Popover className={styles.tip}>
                            <div className={styles.container}>
                                <Link
                                    to={`/project/budget/audit/${project.projectID}`}
                                    target='_blank'
                                >
                                    <button className={styles.openHelpLink}>Audit</button>
                                </Link>
                            </div>
                        </Popover>
                    }
                />
            </div>
        </div>
    )
}
