import { ReactNode } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './InfoGuide.module.scss'

interface IconGuideProps {
    label: string
    width: number
    height: number
    children: ReactNode
}

export const InfoGuide = ({ label, width, height, children }: IconGuideProps) => (
    <OverlayTrigger
        trigger='click'
        rootClose
        placement='bottom'
        overlay={
            <Popover id={`${label.split(' ').join('-').toLowerCase()}-guide-popover`}>
                <div style={{ width, height }} className={styles.infoGuide}>
                    <div className={styles.banner}>
                        <div className={styles.logo}>{label}</div>
                    </div>
                    <div className={styles.whitespace}></div>
                    <div className={styles.body}>{children}</div>
                </div>
            </Popover>
        }
    >
        <div className={styles.toggle}>{label}</div>
    </OverlayTrigger>
)
