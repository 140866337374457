import { AppContext, ShowSplashView } from 'App'
import { PageLayout } from 'components/Shared/PageLayout/PageLayout'
import { formatDateKey, getPeepsDateRange, getTodayDate } from 'domain/dateHelpers'
import { Column as DataColumn, isConsultant, SideBarType } from 'domain/models'
import { Cell, Column, Table, DataCell } from 'fixed-data-table-2'
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { useWindowSize } from 'react-use'
import { PeepCell } from './PeepCell/PeepCell'
import { PeepConsultantCell } from './PeepConsultantCell'
import './PeepsView.scss'
import {
    findHolidays,
    getHeaderHeight,
    getRowHeight,
    getColumnWidth,
    getScrollLeftPixels,
    makeDefaultColumns,
} from './peepsView.helpers'
import { filterRows } from 'domain/filterRows'

export const PeepsView = () => {
    const { store, filters, uiState, setUiState, startStoreLoad} = useContext(AppContext)
    const { width: viewportWidth, height: viewportHeight } = useWindowSize()
    const todayDateKey = formatDateKey(getTodayDate())
    const dateKeysJson = JSON.stringify(getPeepsDateRange().map(formatDateKey)) // A trick to memoize dateStrings to avoid unnecessary re-renders
    const dateKeys: string[] = useMemo(() => JSON.parse(dateKeysJson), [dateKeysJson])

    useEffect(() => {
        startStoreLoad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const scrollLeft: number = useMemo(
        () => getScrollLeftPixels(dateKeys, todayDateKey),
        [dateKeys, todayDateKey]
    )

    const initialColumns = useMemo(
        () => makeDefaultColumns(dateKeys, todayDateKey),
        [dateKeys, todayDateKey]
    )
    const [columns, setColumns] = useState(initialColumns)
    const rows = filterRows(store.consultants, filters)

    const defaultRowHeight = useMemo(() => {
        return getRowHeight(filters.viewType)
    }, [filters.viewType])

    const minCellConstraints = useMemo((): [number, number] => {
        return [getColumnWidth(false), defaultRowHeight]
    }, [defaultRowHeight])

    const [overriddenRowsHeight, setOverriddenRowsHeight] = useState<{
        [Key: number]: number
    }>([])

    const handleIndividualRowHeight = (index: number, data: ResizeCallbackData) => {
        let localOverriddenRowsHeight = overriddenRowsHeight
        if (
            localOverriddenRowsHeight[index] &&
            localOverriddenRowsHeight[index] === defaultRowHeight
        ) {
            delete localOverriddenRowsHeight[index]
        } else {
            localOverriddenRowsHeight[index] = data.size.height
        }

        setOverriddenRowsHeight(localOverriddenRowsHeight)
    }

    const onCellResize = (
        e: SyntheticEvent,
        data: ResizeCallbackData,
        index: number,
        c: DataColumn
    ) => {
        handleIndividualRowHeight(index, data)
        // Trigger the Redraw of the Grid while setting the column width
        setColumnWidth(data.size.width, c.key)
    }

    const setColumnWidth = (newWidth: number, colKey: string) => {
        setColumns((prevColumns) =>
            prevColumns.map((prevColumn) =>
                prevColumn.key === colKey
                    ? {
                          ...prevColumn,
                          width: newWidth,
                      }
                    : prevColumn
            )
        )
    }

    const getRowHeightGetter = (index: number) => {
        return overriddenRowsHeight[index] ? overriddenRowsHeight[index] : defaultRowHeight
    }

    return (
        <ShowSplashView showSplashView={!store.isInitialized}>
            <PageLayout>
                <Table
                    className={filters.viewType.toLowerCase()}
                    rowsCount={rows.length}
                    width={viewportWidth}
                    height={viewportHeight - uiState.navHeight}
                    scrollLeft={scrollLeft}
                    touchScrollEnabled={true}
                    stopScrollPropagation={true}
                    isColumnResizing={false}
                    headerHeight={getHeaderHeight(filters.viewType)}
                    rowHeight={getRowHeight(filters.viewType)}
                    rowHeightGetter={getRowHeightGetter}
                    onColumnResizeEndCallback={(newColumnWidth: number, columnKey: string) => {
                        setColumnWidth(newColumnWidth, columnKey)
                    }}
                >
                    {columns
                        .filter((c) => c.locked)
                        .map((c) => (
                            <Column
                                key={c.key}
                                columnKey={c.key}
                                header={<DataCell>{c.name}</DataCell>}
                                cell={({ rowIndex: i, width: w, height: h }: any) => (
                                    <Cell width={w} height={h}>
                                        <PeepConsultantCell peepsCell={rows[i]} filters={filters} />
                                    </Cell>
                                )}
                                fixed={true}
                                width={c.width}
                            />
                        ))}
                    {columns
                        .filter((c) => !c.locked)
                        .map((c, i) => (
                            <Column
                                key={c.key}
                                columnKey={c.key}
                                allowCellsRecycling={true}
                                cellClassName={c.cellClass}
                                header={
                                    <DataCell className={c.cellClass}>
                                        {!c.isWeekSpacer && <>{c.key}</>}
                                        <br />
                                        {c.name}
                                    </DataCell>
                                }
                                cell={({ rowIndex: i, width: w, height: h }: any) => {
                                    let isWeekSpacer = c.isWeekSpacer
                                    let isResizable = isConsultant(rows[i].cell) && !isWeekSpacer
                                    return isResizable ? (
                                        <Resizable
                                            minConstraints={minCellConstraints}
                                            width={w}
                                            height={h}
                                            onResize={(e, data) => {
                                                onCellResize(e, data, i, c)
                                            }}
                                        >
                                            <Cell width={w}>
                                                <PeepCell
                                                    cell={rows[i]}
                                                    dateKey={c.key}
                                                    holidays={findHolidays(c.key, store.holidays)}
                                                    isWeekSpacer={isWeekSpacer}
                                                    viewType={filters.viewType}
                                                    isColourBlindMode={uiState.isColourBlindMode}
                                                    showNetSuite={uiState.showNetSuite}
                                                    projectBudgets={store.projectBudgets}
                                                    openBudgetAdjustmentCalculator={(projectId) =>
                                                        setUiState({
                                                            ...uiState,
                                                            activeSideBar: {
                                                                type: SideBarType.ProjectBudgetAdjustor,
                                                                projectId,
                                                            },
                                                        })
                                                    }
                                                    height={h}
                                                />
                                            </Cell>
                                        </Resizable>
                                    ) : (
                                        <Cell width={w}>
                                            <PeepCell
                                                cell={rows[i]}
                                                dateKey={c.key}
                                                holidays={findHolidays(c.key, store.holidays)}
                                                isWeekSpacer={isWeekSpacer}
                                                viewType={filters.viewType}
                                                isColourBlindMode={uiState.isColourBlindMode}
                                                showNetSuite={uiState.showNetSuite}
                                                projectBudgets={store.projectBudgets}
                                                openBudgetAdjustmentCalculator={(projectId) =>
                                                    setUiState({
                                                        ...uiState,
                                                        activeSideBar: {
                                                            type: SideBarType.ProjectBudgetAdjustor,
                                                            projectId,
                                                        },
                                                    })
                                                }
                                                height={h}
                                            />
                                        </Cell>
                                    )
                                }}
                                width={c.width}
                                isResizable={true}
                            />
                        ))}
                </Table>
            </PageLayout>
        </ShowSplashView>
    )
}
