import { AppContext } from 'App'
import { fetchBookableConsultants, fetchGigDetails } from 'domain/budgetService'
import { dateFromIsoString } from 'domain/dateHelpers'
import { BookableConsultant, ProjectBudget } from 'domain/models'
import { useContext, useEffect, useState } from 'react'
import { BudgetAdjustmentCalculator } from './BudgetAdjustmentCalculator/BudgetAdjustmentCalculator'

interface ProjectBudgetAdjustorProps {
    projectId: number
    navHeight: number
}
export interface IBudgetFormState {
    email: string
    name: string
    startDate: Date | undefined
    endDate: Date | undefined
    rate: number | undefined
}

export const ProjectBudgetAdjustor = (props: ProjectBudgetAdjustorProps) => {
    const [projectBudget, setProjectBudget] = useState<ProjectBudget>()
    const [bookableConsultants, setBookableConsultants] = useState<BookableConsultant[]>([])
    const [budgetFormConsultants, setBudgetFormConsultants] = useState<IBudgetFormState[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const { store } = useContext(AppContext)

    useEffect(() => {
        if (!props.projectId) return
        setIsLoading(true)

        Promise.all([fetchGigDetails(props.projectId), fetchBookableConsultants()])
            .then(([data, bookableConsultants] : [ProjectBudget, BookableConsultant[]]) => {

                const consultants: IBudgetFormState[] = data.consultants.map((c) => {
                    const consultant = store.consultants.find(({ email }) => email === c.email)
                    return {
                        projectId: data.projectId,
                        email: c.email,
                        name: consultant?.name ? `${consultant.name} (${consultant.role})` : c.userName,
                        startDate: dateFromIsoString(c.firstDay),
                        endDate: dateFromIsoString(c.lastDay),
                        rate: c.rate,
                    }
                })

                setProjectBudget(data)
                setBudgetFormConsultants(consultants)
                setBookableConsultants(bookableConsultants)
            })
            .catch((e) => {
                console.error(e)
                setIsError(true)
            })
            .finally(() => setIsLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.projectId])

    return (
        <>
            {!isLoading && projectBudget ? (
                <BudgetAdjustmentCalculator
                    projectId={props.projectId}
                    projectName={projectBudget.projectName}
                    projectBudget={projectBudget}
                    bookedConsultants={budgetFormConsultants}
                    bookableConsultants={bookableConsultants}
                />
            ) : (
                <i className='fa fa-spinner fa-pulse fa-fw fa-2x'></i>
            )}
            {isError && <p>An unknown error has occurred. Please contact your administrator.</p>}
        </>
    )
}
