import { AppContext } from 'App'
import { getPeepsEndDate, getTodayDate } from 'domain/dateHelpers'
import { getFilterWhitespace, getVisibleResourceGroups } from 'domain/filterHelpers'
import { ViewType } from 'domain/models'
import { useContext } from 'react'
import { DateRangeSelector } from './DateRangeSelector/DateRangeSelector'
import { FilterList } from './FilterList/FilterList'
import { FilterSearch } from './FilterSearch/FilterSearch'
import { MultiSelect } from './DropdownSelect/MultiSelect/MultiSelect'
import { SingleSelect } from './DropdownSelect/SingleSelect/SingleSelect'
import styles from './NavBarFilters.module.scss'
import { ResetFiltersButton } from './ResetFiltersButton/ResetFiltersButton'

export const NavBarFilters = () => {
    const { store, filters, setFilters, resetFilters } = useContext(AppContext)

    const onSelectViewType = (view: ViewType) => {
        const startDate = getTodayDate()
        const endDate = getPeepsEndDate()

        setFilters({
            ...filters,
            viewType: view,
            startDate,
            endDate,
        })
    }

    return (
        <div className={styles.filters}>
            <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                <div className={styles.filter}>
                    <FilterSearch
                        search={filters.search}
                        onChangeSearch={(search) => setFilters({ ...filters, search })}
                    />
                </div>
                <div className={styles.filter}>
                    <MultiSelect
                        name={'squads'}
                        onChange={(values) => setFilters({ ...filters, squads: values })}
                        options={store.squads.map(({ name }) => name)}
                        selected={filters.squads}
                        entityTypePluralName='Squads'
                    />
                </div>
                <div className={styles.filter}>
                    <MultiSelect
                        name={'resourceGroups'}
                        onChange={(values) => setFilters({ ...filters, resourceGroups: values })}
                        options={getVisibleResourceGroups(store, filters).map((r) => r.name)}
                        selected={filters.resourceGroups}
                        entityTypePluralName='Resource Groups'
                    />
                </div>
                <div className={styles.filter}>
                    <SingleSelect
                        name={'View Type'}
                        onChange={(value) => onSelectViewType(value as ViewType)}
                        options={Object.values(ViewType)}
                        selected={filters.viewType}
                        entityTypePluralName='View Types'
                    />
                </div>
                {getFilterWhitespace(filters.viewType) && (
                    <div className={styles.filter}>
                        <DateRangeSelector
                            viewType={filters.viewType}
                            startDate={filters.startDate}
                            endDate={filters.endDate}
                            onDatesChange={(dates: { startDate: Date; endDate: Date }) =>
                                setFilters({
                                    ...filters,
                                    startDate: dates.startDate,
                                    endDate: dates.endDate,
                                })
                            }
                        />
                    </div>
                )}
                <div className={styles.filter}>
                    <FilterList
                        showTerminated={filters.showTerminated}
                        toggleTerminated={() =>
                            setFilters({
                                ...filters,
                                showTerminated: !filters.showTerminated,
                            })
                        }
                        showNonBillable={filters.showNonBillable}
                        toggleNonBillable={() =>
                            setFilters({
                                ...filters,
                                showNonBillable: !filters.showNonBillable,
                            })
                        }
                    />
                </div>
                <div className={styles.filter}>
                    <ResetFiltersButton resetFilters={resetFilters} />
                </div>
            </form>
        </div>
    )
}
