import { PageLayout } from 'components/Shared/PageLayout/PageLayout'

export const AboutView = () => (
    <PageLayout>
        <div className='container-fluid'>
            <h1>About Purple Peeps</h1>
            <p>This tool allows visualisation of timesheet and booking information from OpenAir.</p>
            <h3>View options</h3>
            <p>
                The tool can be switched between a full view showing all available information and a
                compact view which compresses the layout in order to show more consultants on one
                screen.
            </p>
            <h3>Blocks</h3>
            <p>
                Each block in the table shows the project name followed by the number of hours
                booked or timesheeted. For Purple internal projects, the last part of the task name
                is shown instead for the General or Internal projects (leave, public holidays etc).
            </p>
            <h3>State View</h3>
            <p>
                The view for each state will show where local consultants are working, as well as
                where interstate consultants are working on engagements sold locally.
            </p>
            <h3>Whitespace</h3>
            <p>
                Whitespace is displayed as empty white blocks. Hover over these to see how many
                working hours that consultant has available that day.
            </p>
            <h3>Timesheets</h3>
            <p>
                A tick represents a timesheet the consultant has entered. Faded ticks represent time
                that has been entered but not approved/submitted. If no tick is present, the block
                represents what we think will happen, i.e. the booking in OpenAir.
            </p>
            <h3>Soft bookings</h3>
            <p>
                Soft bookings are shown as lighter coloured blocks. You can also see soft bookings
                by role, prefixed by{' '}
                <i
                    style={{ paddingRight: 5 }}
                    className='fa fa-question-circle'
                    aria-hidden='true'
                />
                .
            </p>
            <h3>Work types</h3>
            <p>
                Work types are visualised using colours. Each block colour is configured within
                OpenAir to represent a specific work type.
            </p>
            <h3>Intersquad bookings</h3>
            <p>
                Intersquad bookings are marked with a{' '}
                <i style={{ paddingRight: 5 }} className='fa fa-plane' aria-hidden='true' /> next to
                the consultant name.
            </p>
            <h3>Overbooked consultants</h3>
            <p>
                A red border will show around days where a consultant is booked for more than their
                work schedule (which most of the time is 8 hours).
            </p>
            <h3>Current day highlight</h3>
            <p>
                If the current day is not a weekend, then the relevant column on the table will be
                highlighted in <span style={{ color: '#0E39FB' }}>blue &trade;</span>.
            </p>
            <h3>Search and filtering</h3>
            <p>
                You can search for anything shown in the table, i.e. look for a specific Project,
                Consultant. You can filter by resource group. Use the categories to search for
                specific items. <strong>co:</strong> &lt;consultant name&gt; <strong>r:</strong>{' '}
                &lt;role name&gt; <strong>p:</strong> &lt;project name&gt; <strong>cl:</strong>{' '}
                &lt;client name&gt; <strong>t:</strong> &lt;task name&gt; You can search for
                multiple items in a category using commas and combine multiple categories with a
                space. For example <strong>co:</strong>
                name1
                <strong>,</strong>
                name2 <strong>r:</strong>
                Senior Developer or <strong>cl:</strong>
                clientname <strong>r:</strong>
                Senior Developer
                <strong>,</strong>
                Lead Consultant
            </p>
            <h3>Public holidays</h3>
            <p>Public holidays are shown next to dates as per the information in OpenAir.</p>
            <h3>Refresh times</h3>
            <p>
                Data is cached locally and continually refreshed in the background. We also display
                the time a change was most recently made in OpenAir that we are aware of. Delays
                here can indicate backend synchronisation issues.
            </p>
            <h3>Hovering</h3>
            <p>By hovering over a block, you can see client, project and task details.</p>
            <h3>ICS Calendar file download</h3>
            <p>
                In the top menu you can subscribe to an ICS file using Outlook that will add all
                your engagement information to your calendar, and keep it updated.
            </p>
        </div>
    </PageLayout>
)
