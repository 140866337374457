import 'bootstrap/dist/css/bootstrap.min.css'
import { AuthWrapper } from 'components/AuthWrapper/AuthWrapper'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import 'font-awesome/css/font-awesome.css'
import WebFont from 'webfontloader'
// ensure index.scss is loaded after vendor CSS files
import './index.scss'

import { createRoot } from 'react-dom/client'

WebFont.load({
    google: {
        families: ['Open Sans:200,300,400,500,600,700,800', 'sans-serif'],
    },
})

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
    <AuthWrapper />
    // <StrictMode><AuthWrapper /></StrictMode>
    // ^ 10/2022 - This causes issues in `fixed-data-table-2`
    // See: https://github.com/schrodinger/fixed-data-table-2/issues/656
    // Hopefully this will be resolve by updates to `fixed-data-table-2` soon, and StrictMode can be re-instated
)
