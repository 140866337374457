export type AppStore = {
    isInitialized: boolean
    isLoading: boolean
    consultants: Consultant[]
    loggedInConsultant: Consultant | null
    projectBudgets: ProjectBudget[]
    holidays: Holidays
    resourceGroups: ResourceGroup[]
    squads: Squad[]
    lastDataChange: Date | null
    lastRefreshAttempt: Date | null
    error?: Error
}

export const defaultAppStore: AppStore = {
    isInitialized: false,
    isLoading: false,
    consultants: [],
    loggedInConsultant: null,
    projectBudgets: [],
    holidays: {},
    resourceGroups: [],
    squads: [],
    lastDataChange: null,
    lastRefreshAttempt: null,
}

export enum ViewType {
    Full = 'Full',
    Compact = 'Compact',
    Project = 'Project',
    ProjectsGrouped = 'Projects (Grouped)',
    Whitespace = 'Whitespace',
    BenchWeek = 'Bench Week',
    BenchMonth = 'Bench Month',
    SoftBookings = 'Soft Bookings',
}

export interface FilterState {
    search: string
    viewType: ViewType
    startDate: Date
    endDate: Date
    showTerminated: boolean
    showNonBillable: boolean
    squads: string[]
    resourceGroups: string[]
}

export const defaultFilterState: FilterState = {
    search: '',
    viewType: ViewType.Full,
    squads: [],
    resourceGroups: [],
    startDate: new Date(),
    endDate: new Date(),
    showTerminated: false,
    showNonBillable: true,
}

export interface UrlQueryFilterState {
    viewType: ViewType
    showTerminated: boolean
    showNonBillable: boolean
    squads: string[]
    resourceGroups: string[]
}

export interface RememberedFilterState {
    viewType: ViewType
}

export enum SideBarType {
    ProjectBudgetAdjustor,
    ProjectBudgetEstimator,
    ProjectBudgetAuditHelp,
    ConsultantInfoHelp,
    RelaseNotes
}

interface ActiveSideBar {
    type: SideBarType
    projectId?: number
}

export interface UiState {
    navHeight: number
    isColourBlindMode: boolean
    showNetSuite: boolean
    showReleaseNotes: boolean
    navIsCollapsed: boolean
    showProjectBudgetAuditTools: boolean
    activeSideBar: ActiveSideBar | null
    releaseNotesVersion: string | null
}

export const defaultUiState: UiState = {
    navHeight: 0,
    isColourBlindMode: false,
    showNetSuite: false,
    showReleaseNotes: false,
    navIsCollapsed: false,
    showProjectBudgetAuditTools: false,
    activeSideBar: null,
    releaseNotesVersion: null
}

export interface RememberedUiState {
    isColourBlindMode: boolean
    showNetSuite: boolean
    navIsCollapsed: boolean
    showProjectBudgetAuditTools: boolean
    releaseNotesVersion: string | null
}

export interface EngagementResponse {
    holidays: Holidays
    userEngagements: Consultant[]
    lastDataChange: Date
    resourceGroups: ResourceGroup[]
    squads: Squad[]
    projectBudgets: ProjectBudget[]
}

export interface IAppContext {
    store: AppStore
    filters: FilterState
    setFilters: (filters: FilterState) => void
    resetFilters: () => void
    uiState: UiState
    setUiState: (uiState: UiState) => void
    startStoreLoad: () => Promise<void>
}

export type Squad = {
    name: string
}

export interface Consultant {
    name: string
    email: string
    role: string
    bookingRole: string
    squad: string
    state: string
    isActive: boolean
    workHoursPerDay: number
    employmentStartDate: string
    employmentEndDate?: string
    engagements: { [key: string]: Engagement }
    resourceGroup: string
    lastSlipDate: string
}

export interface PeepsProject {
    projectString: string
    client: string
    projectName: string
    projectID: number
    isHeaderCell?: boolean
}

export interface PeepsCell {
    cell: Consultant | PeepsProject
}

export function isConsultant(cell: Consultant | PeepsProject): cell is Consultant {
    return (cell as PeepsProject).projectString === undefined
}

// Use on the project budget calculator and adjustor tools
export interface BookableConsultant {
    name: string
    email: string
    role: string
}

// Use on the projects summary page in the AdvancedAutoComplete as the DataSource
export interface ConsultantProjectSearch {
    name: string
    email: string
    rolename: string
    projectname: string
    clientname: string
    taskname: string
    salesname: string
    projectcoordinatorname: string
}

export interface Engagement {
    date: Date
    entries: EngagementEntry[]
    availableHours: number
    isTimesheet: boolean
    isCompleted: boolean
    isAvailable: boolean
}

export interface EngagementEntry {
    backgroundColor: string
    client: string
    clientId: number
    startDate: string
    endDate: string
    foregroundColor: string
    hours: number
    isBillable: boolean
    isHardBooking: boolean
    pdPlanType: string
    programManagerName: string
    programManagerSquad: string
    salesRepSquad: string
    projectCoordinatorSquad: string
    project: string
    projectCoordinatorName: string
    projectId: number
    salesRepName: string
    salesTerritory: string
    source: string
    task: string
    entries: EngagementEntry[]
    isAvailable: any // TODO
    availableHours: any // TODO
    isTimesheet: any // TODO
    totalHours: number
    entryMatchesSearch: boolean
}

export interface ProjectBudget {
    projectId: number
    projectName: string
    clientName: string
    tcv: number
    timesheeted: number
    futureBookedFromSlipProjections: number
    futureBookedFromBookingsByDay: number
    remainingTcvFromSlipProjections: number
    remainingTcvFromBookingsByDay: number
    nonTimeSlips: number
    consultants: ProjectBudgetConsultant[]
}

export interface ProjectBudgetConsultant {
    userName: string
    email: string
    // It's rare but possible to include a consultant with no days (e.g. they could have planned but no booked
    // hours or a slip projection for a day that's no longer booked.)
    firstDay?: string,
    lastDay?: string,
    rate?: number,
    plannedHoursRemaining?: number
}

export interface ProposedBudget {
    userEmail: string
    skippedDays: string[]
    skippedEngagements: string[]
    budgetAdjustmentAmount: number
    message: string
}

export type Holidays = {
    [key: string]: Holiday[]
}

export type ResourceGroup = {
    name: string
    squads: string[]
    isWhiteSpaceGroup?: boolean
}

export type Holiday = any // TODO

export type Column = {
    key: string
    name: string
    width: number
    locked: boolean
    isWeekSpacer: boolean
    cellClass: string
}

export enum SourceToggleSource {
    NetSuite = 'NetSuite',
}

export interface AddConsultantDto {
    name: string
    email: string
    rate: number
    startDate: string
    endDate: string
}

export interface FetchConsultantCostResponse {
    totalCost: number
    skippedDays: string[]
    skippedEngagements: string[]
    message: string
}

export interface ProjectBudgetAuditResponse {
    projectId: number
    projectName: string
    clientName: string
    tcv?: number
    timesheeted?: number
    futureBookedFromSlipProjections?: number
    futureBookedFromBookingsByDay?: number
    remainingTcvFromSlipProjections?: number
    remainingTcvFromBookingsByDay?: number
    nonTimeSlips: number
    nonTimeSlipProjections: number
    warnings: ProjectBudgetWarning[]
    consultants: ProjectBudgetAuditConsultant[]
    nonTimeFees: ProjectBudgetAuditNonTimeFee[]
}

export interface ProjectBudgetAuditConsultant {
    projectId: number
    userId: number
    userName: string
    email: string
    bookingRole: string
    plannedHoursTotal?: number
    plannedHoursRemaining?: number
    timesheeted: number
    timesheetedHours: number
    futureBookedFromSlipProjections: number
    futureBookedFromBookingsByDay: number
    bookedHoursTotal: number
    bookedHoursFuture: number
    lastTimesheetedDate?: string
    includeBookingsAfterDate?: string
    firstDay?: string
    lastDay?: string
    rows: ProjectBudgetAuditConsultantRow[]
}

export interface ProjectBudgetAuditConsultantRow {
    userId: number
    date: string
    rowNumber: number
    timesheetedHoursOnProject?: number
    timesheetApproved?: boolean
    slipHours?: number
    slipRawRate?: number
    slipRawTotal?: number
    slipRate?: number
    slipTotal?: number
    slipStatus?: ProjectBudgetRowStatus
    slipStatusIcon?: ProjectBudgetSeverity
    slipStatusTooltip?: string
    slipProjectionHours?: number
    slipProjectionRawRate?: number
    slipProjectionRawTotal?: number
    slipProjectionRate?: number
    slipProjectionTotal?: number
    slipProjectionStatus?: ProjectBudgetRowStatus
    slipProjectionStatusIcon?: ProjectBudgetSeverity
    slipProjectionStatusTooltip?: string
    bookingRawHours?: number
    bookingHours?: number
    bookingRate?: number
    bookingTotal?: number
    bookingStatus?: ProjectBudgetRowStatus
    bookingStatusIcon?: ProjectBudgetSeverity
    bookingStatusTooltip?: string
}

export interface ProjectBudgetAuditNonTimeFee {
    recordType: string
    date: string
    total: number
    userId: number
    userName: string
    slipType: string
    slipDescription: string
    slipNotes: string
    billingRuleName: string
    billingRuleDescription: string
    billingRuleNotes: string
    taskType: string
    taskName: string
    taskNotes: string
}

export enum ProjectBudgetRowStatus {
    Active = 'Active',
    Excluded = 'Excluded',
}

export interface ProjectBudgetWarning {
    severity: ProjectBudgetSeverity
    text: string
}

export enum ProjectBudgetSeverity {
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error',
}

export interface ConsultantAuditRowId {
    date: Date
    rowNumber: number
}
