import { AppContext } from 'App'
import { navRowHeight } from 'components/Shared/PageLayout/NavBar/NavBar'
import { ProjectBudgetAdjustor } from 'components/Shared/PageLayout/SideBar/ProjectBudgetAdjustor/ProjectBudgetAdjustor'
import { SideBarType } from 'domain/models'
import { useContext } from 'react'
import { ConsultantInfoHelp } from './ConsultantInfoHelp/ConsultantInfoHelp'
import { ProjectBudgetAuditHelp } from './ProjectBudgetAuditHelp/ProjectBudgetAuditHelp'
import { ProjectBudgetEstimator } from './ProjectBudgetEstimator/ProjectBudgetEstimator'
import { ReleaseNotes } from './ReleaseNotes/ReleaseNotes'
import { useMountEffect } from 'domain/hooks/useMountEffect'
import { releaseNotesContent } from './ReleaseNotes/ReleaseNotesModel'

import styles from './SideBar.module.scss'

export const SideBar = () => {
    const { store, uiState, setUiState } = useContext(AppContext)
    const navHeight = uiState.navIsCollapsed ? navRowHeight : uiState.navHeight

    const sideBarIsOpen = uiState.activeSideBar
    
    const getProjectBudget = (projectId: number) =>
        store.projectBudgets.find((value) => value.projectId === projectId)

    const onClose = () => {
        setUiState({
            ...uiState,
            activeSideBar: null,
        })
    }

    useMountEffect(() => {
        let shownotes = uiState.releaseNotesVersion !== null
                        && uiState.releaseNotesVersion !== releaseNotesContent.version

        if(shownotes) {
            setUiState({ ...uiState, 
                showReleaseNotes: !uiState.showReleaseNotes,
                releaseNotesVersion: releaseNotesContent.version,
                activeSideBar: {
                    type: SideBarType.RelaseNotes,
                }, })
        } else if(uiState.releaseNotesVersion === null) {
            setUiState({
                ...uiState,
                releaseNotesVersion: releaseNotesContent.version 
            })
        }
    })

    return sideBarIsOpen ? (
        <div
            className={styles.sideBar}
            style={{ top: navHeight + 1, height: `calc(100vh - ${navHeight}px)` }}
        >
            <button className={styles.closeButton} onClick={onClose}>
                <i className='fa fa-close'></i>Close
            </button>
            {uiState.activeSideBar?.type === SideBarType.ProjectBudgetAdjustor &&
                uiState.activeSideBar?.projectId && (
                    <ProjectBudgetAdjustor
                        navHeight={uiState.navIsCollapsed ? navRowHeight : uiState.navHeight}
                        projectId={uiState.activeSideBar.projectId}
                    />
                )}
            {uiState.activeSideBar?.type === SideBarType.ProjectBudgetEstimator && (
                <ProjectBudgetEstimator />
            )}
            {uiState.activeSideBar?.type === SideBarType.ProjectBudgetAuditHelp && (
                <ProjectBudgetAuditHelp />
            )}
            {uiState.activeSideBar?.type === SideBarType.ConsultantInfoHelp && (
                <ConsultantInfoHelp />
            )}

            {uiState.activeSideBar?.type === SideBarType.RelaseNotes && (
                <ReleaseNotes />
            )}
        </div>
    ) : (
        <></>
    )
}
