import { getTodayDate } from 'domain/dateHelpers'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ConsultantRow } from '../ProjectBudgetEstimator'
import styles from './ProjectBudgetTable.module.scss'
import { EditConsultantRowForm } from './components/EditConsultantRow/EditConsultantRowForm'
import { BookableConsultant } from 'domain/models'

interface IProjectBudgetTableProps {
    consultantRows: ConsultantRow[]
    bookableConsultants: BookableConsultant[]
    setConsultantRows: (consultantRows: React.SetStateAction<ConsultantRow[]>) => void
}

export const ProjectBudgetTable = ({
    consultantRows,
    bookableConsultants,
    setConsultantRows,
}: IProjectBudgetTableProps) => {
    const onDelete = (row: ConsultantRow) => {
        setConsultantRows((rs) => rs.filter((r, i) => r.itemKey !== row.itemKey))
    }

    const onChange = (row: ConsultantRow) => {
        setConsultantRows((rs) => rs.map((r, i) => (r.itemKey === row.itemKey ? row : r)))
    }

    return (
        <>
            <table className={styles.projectBudgetTable}>
                <thead>
                    <tr>
                        <td className={styles.colDelete}></td>
                        <td>Consultant</td>
                        <td className={styles.dateColumn}>Start Date</td>
                        <td className={styles.dateColumn}>End Date</td>
                        <td className={styles.rateColumn}>Rate</td>
                        <td className={styles.smallColumn}>Total Cost</td>
                    </tr>
                </thead>
                <tbody>
                    {consultantRows.map((consultantRow, i) => (
                        <React.Fragment key={consultantRow.itemKey}>
                            {
                                <EditConsultantRowForm
                                    onDelete={onDelete}
                                    onChange={onChange}
                                    consultantRow={consultantRow}
                                    bookableConsultants={bookableConsultants}
                                />
                            }
                        </React.Fragment>
                    ))}
                    {consultantRows.length === 0 && (
                        <tr className={styles.noConsultant}>
                            <td colSpan={6}>No consultants have been added</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div>
                <button
                    className={styles.addConsultant}
                    onClick={() => {
                        setConsultantRows((rs) => rs.concat(createNewConsultantRow()))
                    }}
                >
                    <i className='fa fa-plus'></i> Add consultant
                </button>
            </div>
        </>
    )
}
function createNewConsultantRow(): ConsultantRow {
    return {
        name: '',
        email: '',
        startDate: getTodayDate(),
        endDate: getTodayDate(),
        rate: 0,
        totalCost: 0,
        skippedDays: [],
        skippedEngagements: [],
        message: '',
        itemKey: uuidv4(),
    }
}
