import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './BudgetInfoPopover.module.scss'

interface IBudgetInfoPopoverProps {
    title: string
    skippedDays?: string[]
    skippedEngagements?: string[]
    message?: string
}

export const BudgetInfoPopover = (props: IBudgetInfoPopoverProps) => {
    return (
        <div className={styles.skippedDaysInfo}>
            <OverlayTrigger
                trigger='click'
                rootClose
                placement='bottom'
                overlay={
                    <Popover id='skippedDays' className={styles.popover} title={props.title}>
                        <div className={styles.popoverContent}>
                            {props.skippedDays && props.skippedDays.length > 0 && (
                                <>
                                    <p>Skipped Days:</p>
                                    <ul>
                                        {props.skippedDays.map((s, i) => (
                                            <li key={i}>{s}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {props.skippedEngagements && props.skippedEngagements.length > 0 && (
                                <>
                                    <p>Engagements that need to be considered:</p>
                                    <ul>
                                        {props.skippedEngagements.map((s, i) => (
                                            <li key={i}>{s}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {props.message && <p>{props.message}</p>}
                        </div>
                    </Popover>
                }
            >
                {props.message ? (
                    <button className={styles.errorBtn}>
                        <i className='fa fa-exclamation-triangle'></i>
                    </button>
                ) : (
                    <button className={styles.popoverBtn}>
                        <i className='fa fa-info'></i>
                    </button>
                )}
            </OverlayTrigger>
        </div>
    )
}
