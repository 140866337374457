import { useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './AdvancedAutoComplete.module.scss'

interface IAdvancedAutoCompleteProps<T extends object> {
    placeholder?: string
    dataSource: T[]
    name: string
    valueField: string
    labelField: string
    defaultValue: T | undefined | null
    disabled?: boolean | undefined
    onChange: (option: T | undefined) => void
    autoFocus?: boolean
}

export const AdvancedAutoComplete = <T extends object>(props: IAdvancedAutoCompleteProps<T>) => {
    const getTranslatedValueByItem = <T extends object>(item: T, replacementValue: string) => {
        let result: string = replacementValue.toLowerCase()
        for (const [key, val] of Object.entries(item)) {
            result = result.replace(`[${key.toLowerCase()}]`, val)
        }
        return result
    }

    const getDefaultIndex = () => {
        let result = -1

        if (props.defaultValue) {
            const defaultValue = getTranslatedValueByItem(props.defaultValue, props.valueField)
            result = props.dataSource.findIndex(
                (value) => getTranslatedValueByItem(value, props.valueField) === defaultValue
            )
        }
        return result
    }

    const selectedItemLabel = <T extends object>(item: T | undefined | null) => {
        if (!item) return ''
        return getTranslatedValueByItem(item, props.labelField)
    }

    const [activeIndex, setActiveIndex] = useState<number>(getDefaultIndex)
    const [filtered, setFiltered] = useState<T[]>([])
    const [isShow, setIsShow] = useState<boolean>(false)
    const [inputLabel, setInputLabel] = useState<string>(selectedItemLabel(props.defaultValue))

    const isSearchValueMatch = <T extends object>(item: T, searchValue: string) => {
        if (!searchValue) return true

        // Check if the user entered multiple OR criteria
        // For Example, Tom,r:Consultant which mean to search role of consultant or label start with tom
        var splitValues = searchValue.split(',')

        if (splitValues.length > 0) {
            return isSearchValuesMatch(item, splitValues)
        } else {
            return isSearchValuesMatch(item, [searchValue])
        }
    }

    const isSearchValuesMatch = <T extends object>(item: T, searchValues: string[]) => {
        let result = false

        for (const value of searchValues) {
            const splitKeyValue = value.split(':', 2)

            if (splitKeyValue.length === 2) {
                // Search based on the Name/Alias:SearchValue

                const propertyKey = splitKeyValue[0]
                const propertyValue = splitKeyValue[1]

                if (!propertyValue) return result

                for (const [key, val] of Object.entries(item)) {
                    var keyAliases = getPropertyKeyAliases(key)

                    if (
                        val &&
                        keyAliases.find((keyAlias) => keyAlias === propertyKey.toLowerCase())
                    ) {
                        result = val.toLowerCase().indexOf(propertyValue.toLowerCase()) > -1
                    }
                }
            } else {
                // Search on the Label
                const labelValue = getTranslatedValueByItem(item, props.labelField)
                result = labelValue.toLowerCase().indexOf(value.toLowerCase()) > -1
            }
            if (result) break
        }

        return result
    }

    const getPropertyKeyAliases = (propertyKey: string) => {
        const results = [propertyKey.toLowerCase()]
        let propertyAlias: string = ''

        for (let index = 0; index < propertyKey.length; index++) {
            if (index === 0 || propertyKey[index] === propertyKey[index].toUpperCase()) {
                propertyAlias = propertyAlias + propertyKey[index]
            }
        }
        results.push(propertyAlias.toLowerCase())
        return results
    }

    const handleOnChange = (e: React.BaseSyntheticEvent) => {
        const input = e.currentTarget.value

        const newFilteredSuggestions = props.dataSource.filter((suggestion) =>
            isSearchValueMatch(suggestion, input)
        )
        setActiveIndex(-1)
        setFiltered(newFilteredSuggestions)
        setIsShow(input)
        setInputLabel(input)
    }

    const handleOnSelect = (option: T) => {
        setInputLabel(selectedItemLabel(option))
        setActiveIndex(-1)
        setFiltered([])
        setIsShow(false)
        props.onChange(option)
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        // We replaced the native event with the synthetic keyboard event
        const key = e.code

        switch (key) {
            case 'Enter':
                handleOnSelect(filtered[activeIndex])
                break
            case 'ArrowUp':
                return activeIndex === 0 ? null : setActiveIndex(activeIndex - 1)
            case 'ArrowDown':
                return activeIndex - 1 === filtered.length ? null : setActiveIndex(activeIndex + 1)
        }
    }

    const availableCriteriaToSearch = () => {
        var firstDataSourceValue = props.dataSource.find((value, index) => index === 0)
        if (!firstDataSourceValue) return <></>

        return Object.entries(firstDataSourceValue).map(([key, val]) => {
            const keyAliases = getPropertyKeyAliases(key)
            return <li key={key}> {keyAliases.join(' or ').toLowerCase()} </li>
        })
    }

    return (
        <OverlayTrigger
            placement='bottom'
            overlay={
                <Popover id='filter-search-popover' className={styles.popover}>
                    <div className={styles.searchSuggestions}>
                        <p>
                            Try using the following prefixes to refine your search. Use comma
                            seperations to include multiple values filter (OR).
                        </p>
                        <p>
                            e.g <strong>name:</strong>name1,name2 <strong>r:</strong>senior
                            consultant
                        </p>
                        <p>The following fields are available as search criteria:</p>
                        <ul>{availableCriteriaToSearch()}</ul>
                    </div>
                </Popover>
            }
            show={!isShow && !props.disabled && !inputLabel}
        >
            <div className={styles.autoComplete}>
                <input
                    type='text'
                    name={props.name}
                    placeholder={props.placeholder}
                    onChange={handleOnChange}
                    onKeyDown={onKeyDown}
                    value={inputLabel}
                    disabled={props.disabled}
                    autoComplete={'off'}
                    autoFocus={!!props.autoFocus}
                />
                {isShow &&
                    (filtered.length ? (
                        <ul className={styles.suggestionsList}>
                            {filtered.map((option, index) => (
                                <li
                                    className={index === activeIndex ? `${styles.active}` : ''}
                                    key={index}
                                    onClick={() => handleOnSelect(option)}
                                >
                                    {selectedItemLabel(option)}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.noAutoComplete}>
                            <em>No options</em>
                        </div>
                    ))}
            </div>
        </OverlayTrigger>
    )
}
