import { dateFromDateKey } from 'domain/dateHelpers'
import { Column, Holiday, Holidays, ViewType } from 'domain/models'

export const getHeaderHeight = (viewType: ViewType): number => 60

export const getRowHeight = (viewType: ViewType) => {
    switch (viewType) {
        case ViewType.Full:
        case ViewType.SoftBookings:
            return 100
        case ViewType.ProjectsGrouped:
            return 60
        default:
            return 40
    }
}

export const getColumnWidth = (isLocked: boolean) => {
    if (isLocked) {
        return 120
    }
    return 80
}

export const makeDefaultColumns = (dateKeys: string[], todayDateKey: string): Column[] => {
    const dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
    const columns: Column[] = [
        {
            key: 'consultant',
            name: 'Consultant',
            width: getColumnWidth(true),
            locked: true,
            cellClass: '',
            isWeekSpacer: false,
        },
    ].concat(
        dateKeys.flatMap((dateKey, index) => {
            var date = dateFromDateKey(dateKey)
            var dayNumber = date.getDay()
            var columns = []

            const column: Column = {
                key: dateKey,
                name: dayLabels[dayNumber],
                width: getColumnWidth(false),
                locked: false,
                cellClass:
                    (dateKey === todayDateKey && 'is-today') ||
                    (dateKeys[index + 1] === todayDateKey && 'is-previous-day') ||
                    '',
                isWeekSpacer: false,
            }
            columns.push(column)

            if (dayNumber === 5) {
                const blank: Column = {
                    key: dateKey + '-weekSpacer',
                    name: '',
                    width: 5,
                    locked: false,
                    cellClass: 'weekSpacer',
                    isWeekSpacer: true,
                }
                columns.push(blank)
            }

            return columns
        })
    )
    return columns
}

export const findHolidays = (dateKey: string, holidays: Holidays): Holiday[] => {
    return holidays[dateKey] || []
}

export const getScrollLeftPixels = (dateStrings: string[], todayDateString: string): number => {
    const index = dateStrings.findIndex((dateString) => dateString === todayDateString)
    return index * getColumnWidth(false) - 2
}
