import classNames from 'classnames'
import { SourceToggleSource } from 'domain/models'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styles from './SourceToggle.module.scss'

interface SourceToggleProps {
    source: SourceToggleSource
    showSource: boolean
    toggleShowSource: () => void
}

export const SourceToggle = ({ source, showSource, toggleShowSource }: SourceToggleProps) => (
    <OverlayTrigger
        placement='bottom'
        overlay={
            <Tooltip id={`${source.toString().toLowerCase()}-tooltip`}>
                <div>
                    {source.toString()} data is {showSource ? 'SHOWING' : 'HIDDEN'}
                </div>
            </Tooltip>
        }
    >
        <div
            className={classNames(styles.sourceToggle, !showSource && styles.off)}
            onClick={toggleShowSource}
        >
            <div
                className={classNames(
                    styles.icon,
                    source === SourceToggleSource.NetSuite && styles.netSuiteIcon
                )}
            ></div>
        </div>
    </OverlayTrigger>
)
