import classNames from 'classnames'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { ProjectBudgetAuditNonTimeFee } from 'domain/models'
import { dateFromIsoString, formatDateShort } from 'domain/dateHelpers'
import { Table } from 'react-bootstrap'
import styles from './ProjectBudgetAuditView.module.scss'
interface ProjectBudgetNonTimeFeesProps {
    data: ProjectBudgetAuditNonTimeFee[]
}

export const ProjectBudgetNonTimeFeesTable = ({ data }: ProjectBudgetNonTimeFeesProps) => {
    const formatBudgetValue = (budgetField: number | undefined) => {
        // Default to $0 when undefined
        return <CurrencyFormat amount={budgetField || 0} />
    }

    return (
        <Table className={classNames(styles.budgetTotals)} striped bordered hover>
            <thead>
                <tr>
                    <th>Record Type</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>User</th>
                    <th>Slip Type</th>
                    <th>Slip Description</th>
                    <th>Slip Notes</th>
                    <th>Billing Rule Name</th>
                    <th>Billing Rule Description</th>
                    <th>Billing Rule Notes</th>
                    <th>Task Type</th>
                    <th>Task Name</th>
                    <th>Task Notes</th>
                </tr>
            </thead>
            <tbody className={styles.borderNone}>
                {data.map((fee, index) => (
                    <tr key={index}>
                        <td>{fee.recordType}</td>
                        <td>{formatDateShort(dateFromIsoString(fee.date))}</td>
                        <td>{formatBudgetValue(fee.total)}</td>
                        <td>{fee.userName}</td>
                        <td>{fee.slipType}</td>
                        <td>{fee.slipDescription}</td>
                        <td>{fee.slipNotes}</td>
                        <td>{fee.billingRuleName}</td>
                        <td>{fee.billingRuleDescription}</td>
                        <td>{fee.billingRuleNotes}</td>
                        <td>{fee.taskType}</td>
                        <td>{fee.taskName}</td>
                        <td>{fee.taskNotes}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}
