import classNames from 'classnames'
import { formatDateIso } from 'domain/dateHelpers'
import { ViewType } from 'domain/models'
import moment from 'moment'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import styles from './DateRangeSelector.module.scss'

type DateRangeSelectorProps = {
    viewType: ViewType
    startDate: Date
    endDate: Date
    onDatesChange: (dates: { startDate: Date; endDate: Date }) => void
}

export const DateRangeSelector = ({
    viewType,
    startDate,
    endDate,
    onDatesChange,
}: DateRangeSelectorProps) => (
    <OverlayTrigger
        trigger='click'
        placement='bottom'
        rootClose
        overlay={
            <Popover id='date-range-selector-popover'>
                <div className={styles.tooltip}>
                    <h4>{viewType.toString()}</h4>
                    <label htmlFor='startDate'>Start date</label>
                    <input
                        id='startDate'
                        type='date'
                        name='startDate'
                        value={formatDateIso(startDate)}
                        required
                        pattern='\d{4}-\d{2}-\d{2}'
                        onChange={(e) =>
                            onDatesChange({
                                startDate: moment(e.target.value).toDate(),
                                endDate,
                            })
                        }
                    />
                    <br />
                    <label htmlFor='endDate'>End date</label>
                    <input
                        id='endDate'
                        type='date'
                        name='endDate'
                        value={formatDateIso(endDate)}
                        required
                        pattern='\d{4}-\d{2}-\d{2}'
                        onChange={(e) =>
                            onDatesChange({
                                startDate,
                                endDate: moment(e.target.value).toDate(),
                            })
                        }
                    />
                </div>
            </Popover>
        }
    >
        <button type='button' className={styles.toggle}>
            <i className={classNames(styles.icon, 'fa', 'fa-regular', 'fa-calendar')}></i>
        </button>
    </OverlayTrigger>
)
