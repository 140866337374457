import {
    formatDateShort,
    formatDateTimeForDto,
    formatDateIso,
    getTodayDate,
    dateFromIsoString,
} from 'domain/dateHelpers'
import { fetchGigDuration, fetchGigEndDate } from 'domain/peepsViewService'
import moment from 'moment'
import { useState } from 'react'
import { OverlayTrigger, Popover, Tab, Tabs } from 'react-bootstrap'
import styles from './GigPlanningCalculator.module.scss'

enum TimeUnit {
    days = 'days',
    hours = 'hours',
}
export interface GigPlanningCalculatorProps {
    userEmail: string
    linkText: string
}

interface GigPlanningCalculatorState {
    startDate: Date
    endDate: Date
    unitType: TimeUnit
    duration: number
    includePartialDays: boolean
    skippedDays: any[]
    message: string
}

const defaultState: GigPlanningCalculatorState = {
    startDate: getTodayDate(),
    endDate: getTodayDate(),
    unitType: TimeUnit.days,
    duration: 1,
    includePartialDays: false,
    skippedDays: [],
    message: '',
}

export interface EndDateCalculatorDto {
    startDate: string
    userEmail: string
    durationInHours: number
    includePartialDays: boolean
}

export interface DurationCalculatorDto {
    startDate: string
    endDate: string
    userEmail: string
    includePartialDays: boolean
}

export const GigPlanningCalculator = (props: GigPlanningCalculatorProps) => {
    const [key, setKey] = useState<string>('endDateCalc')
    const [isCalculating, setIsCalculating] = useState<boolean>(false)

    const [state, setState] = useState<GigPlanningCalculatorState>(defaultState)

    const calculateEndDate = async () => {
        const duration = state.unitType === 'days' ? state.duration * 8 : state.duration
        const request: EndDateCalculatorDto = {
            startDate: formatDateTimeForDto(state.startDate),
            userEmail: props.userEmail,
            durationInHours: duration,
            includePartialDays: state.includePartialDays,
        }

        setIsCalculating(true)
        await fetchGigEndDate(request)
            .then((res) => {
                setState((prev) => ({
                    ...prev,
                    endDate: dateFromIsoString(res.endDate),
                    skippedDays: res.skippedDays,
                    message: res.message,
                }))
            })
            .catch((err) => {
                console.debug(err)
            })
        setIsCalculating(false)
    }

    const calculateDuration = async () => {
        const request: DurationCalculatorDto = {
            startDate: formatDateTimeForDto(state.startDate),
            endDate: formatDateTimeForDto(state.endDate),
            userEmail: props.userEmail,
            includePartialDays: state.includePartialDays,
        }

        setIsCalculating(true)
        fetchGigDuration(request)
            .then((res) => {
                setState((prev) => ({
                    ...prev,
                    duration: res.duration,
                    skippedDays: res.skippedDays,
                    message: res.message,
                }))
            })
            .catch((err) => {
                console.debug(err)
            })
            .finally(() => setIsCalculating(false))
    }

    return (
        <OverlayTrigger
            trigger='click'
            placement='right'
            rootClose
            overlay={
                <Popover id='gig-planning-popover'>
                    <section id={styles.gigPlanningCalculator}>
                        <h1>Project Date Forecaster</h1>
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => {
                                const key = k ?? 'endDateCalc'
                                const isEndDateCalc = key === 'endDateCalc'
                                const unitType = isEndDateCalc ? TimeUnit.days : TimeUnit.hours
                                const duration = isEndDateCalc ? 1 : 8
                                setKey(key)
                                setState({
                                    ...defaultState,
                                    unitType,
                                    duration,
                                })
                            }}
                        >
                            <Tab eventKey='endDateCalc' title='Calculate End Date'>
                                <div className={styles.endDateContainer}>
                                    <label htmlFor='gig-calculator-start-date-picker'>
                                        Start Date
                                    </label>

                                    <input
                                        id='gig-calculator-start-date-picker'
                                        type='date'
                                        name='startDate'
                                        value={formatDateIso(state.startDate)}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            if (value) {
                                                setState((prev) => ({
                                                    ...prev,
                                                    startDate: dateFromIsoString(value),
                                                }))
                                            }
                                        }}
                                    />

                                    <label htmlFor='gig-calculator-duration'>Duration</label>
                                    <div className={styles.formGroup}>
                                        <input
                                            id='gig-calculator-duration'
                                            placeholder='duration'
                                            type='number'
                                            value={state.duration?.toString()}
                                            onChange={(e: any) =>
                                                setState((prev) => ({
                                                    ...prev,
                                                    duration: e.target.value,
                                                }))
                                            }
                                            required
                                        ></input>

                                        <select
                                            value={state.unitType}
                                            onChange={(e: any) =>
                                                setState((prev) => ({
                                                    ...prev,
                                                    unitType: e.target.value,
                                                }))
                                            }
                                        >
                                            <option value={TimeUnit.days.toString()}>
                                                {TimeUnit.days.toString()}
                                            </option>
                                            <option value={TimeUnit.hours.toString()}>
                                                {TimeUnit.hours.toString()}
                                            </option>
                                        </select>
                                    </div>

                                    <div className={styles.formGroup}>
                                        <input
                                            type='checkbox'
                                            id={styles.partialDays}
                                            checked={state.includePartialDays}
                                            onChange={(e: any) =>
                                                setState({
                                                    ...state,
                                                    includePartialDays: e.target.checked,
                                                })
                                            }
                                        ></input>
                                        <label htmlFor='partialDays'>Include partial days</label>
                                    </div>

                                    <button
                                        className={styles.calcBtn}
                                        onClick={calculateEndDate}
                                        disabled={isCalculating}
                                    >
                                        {!isCalculating ? (
                                            <>
                                                <i className='fa fa-calculator'></i>
                                                <span>Calculate</span>
                                            </>
                                        ) : (
                                            <>
                                                <i className='fa fa-spinner fa-pulse fa-fw'></i>
                                                <span>Calculating</span>
                                            </>
                                        )}
                                    </button>

                                    {state.endDate && (
                                        <label className='mb-2'>
                                            End date: {formatDateShort(state.endDate)}
                                        </label>
                                    )}
                                    {state.message}
                                    {state.skippedDays.length > 0 && (
                                        <div>
                                            <label>Skipped days: </label>
                                            <div className={styles.skippedDays}>
                                                <ul>
                                                    {state.skippedDays.map((d) => (
                                                        <li key={d}>{d}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey='durationCalc' title='Calculate Duration'>
                                <div className={styles.durationContainer}>
                                    <label htmlFor='gig-calculator-start-date-picker'>
                                        Start Date
                                    </label>
                                    <input
                                        id='gig-calculator-start-date-picker'
                                        type='date'
                                        name='startDate'
                                        value={formatDateIso(state.startDate)}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            if (value) {
                                                const startDate = dateFromIsoString(value)
                                                const isAfterEndDate = moment(startDate).isAfter(
                                                    state.endDate
                                                )

                                                setState((prev) => ({
                                                    ...prev,
                                                    startDate,
                                                    endDate: isAfterEndDate
                                                        ? startDate
                                                        : prev.endDate,
                                                }))
                                            }
                                        }}
                                    />

                                    <label htmlFor='gig-calculator-end-date-picker'>End Date</label>
                                    <input
                                        id='gig-calculator-end-date-picker'
                                        type='date'
                                        name='endDate'
                                        value={formatDateIso(state.endDate)}
                                        onChange={(e) => {
                                            const value = e.target.value
                                            if (value) {
                                                setState((prev) => ({
                                                    ...prev,
                                                    endDate: dateFromIsoString(value),
                                                }))
                                            }
                                        }}
                                    />

                                    <div className={styles.formGroup}>
                                        <input
                                            id={styles.partialDays}
                                            type='checkbox'
                                            checked={state.includePartialDays}
                                            onChange={(e: any) =>
                                                setState((prev) => ({
                                                    ...prev,
                                                    includePartialDays: e.target.checked,
                                                }))
                                            }
                                        ></input>
                                        <label htmlFor='partialDays'>Include partial days</label>
                                    </div>

                                    <button
                                        className={styles.calcBtn}
                                        onClick={calculateDuration}
                                        disabled={isCalculating}
                                    >
                                        {!isCalculating ? (
                                            <>
                                                <i className='fa fa-calculator'></i>
                                                <span>Calculate</span>
                                            </>
                                        ) : (
                                            <>
                                                <i className='fa fa-spinner fa-pulse fa-fw'></i>
                                                <span>Calculating</span>
                                            </>
                                        )}
                                    </button>

                                    {state.duration !== undefined && (
                                        <label className='mb-2'>
                                            Duration: {state.duration} hours ({state.duration / 8}{' '}
                                            days)
                                        </label>
                                    )}
                                    {state.message}
                                    {state.skippedDays.length > 0 && (
                                        <div>
                                            <label>Skipped days: </label>
                                            <div className={styles.skippedDays}>
                                                <ul>
                                                    {state.skippedDays.map((d) => (
                                                        <li key={d}>{d}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </section>
                </Popover>
            }
        >
            <button className={styles.consultantButton}>{props.linkText}</button>
        </OverlayTrigger>
    )
}
