import classNames from 'classnames'
import { InfoGuide } from '../InfoGuide'
import styles from './NavBarIconGuide.module.scss'

export const NavBarIconGuide = () => (
    <InfoGuide label='Icons' width={480} height={220}>
        <div className={styles.guide}>
            <div className={styles.column}>
                <i className='fa fa-check-square'>
                    <span>Timesheet entered and approved</span>
                </i>
                <i className='fa fa-plane'>
                    <span>Intersquad bookings</span>
                </i>
                <i className='fa fa-exclamation-circle'>
                    <span>Overbooked Consultant</span>
                </i>
            </div>
            <div className={styles.column}>
                <i className='fa fa-check-square unapproved'>
                    <span>Timesheet entered but not approved</span>
                </i>
                <i className='fa fa-calendar-check-o'>
                    <span>Planned</span>
                </i>
                <i className='fa fa-question-circle'>
                    <span>Soft bookings by role</span>
                </i>
            </div>
        </div>
        <div className={styles.colorblindHeader}>
            <p>COLOURBLIND FRIENDLY ICONS</p>
            <p>
                (Enable <span className='fa fa-eye'></span> colourblind friendly mode){' '}
            </p>
        </div>
        <div className={classNames(styles.guide, styles.colorblindGuide)}>
            <div className={styles.row}>
                <i className='fa fa-square'>
                    <span>Hard booking</span>
                </i>
                <i className='fa fa-play'>
                    <span>Soft booking</span>
                </i>
                <i className='fa fa-circle'>
                    <span>Leave</span>
                </i>
                <i className='fa fa-star'>
                    <span>Sales</span>
                </i>
            </div>
        </div>
    </InfoGuide>
)
