import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { useDebounce } from 'react-use'
import styles from './FilterSearch.module.scss'

interface FilterSearchProps {
    search: string
    onChangeSearch: (search: string) => void
}

export const FilterSearch = ({ search, onChangeSearch }: FilterSearchProps) => {
    const [searchDebounce, setSearchDebounce] = useState<string>(search)

    useEffect(() => {
        if (search !== searchDebounce) {
            setSearchDebounce(search)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    useDebounce(
        () => {
            if (searchDebounce !== search) {
                onChangeSearch(searchDebounce)
            }
        },
        300,
        [searchDebounce]
    )

    return (
        <OverlayTrigger
            placement='bottom'
            overlay={
                <Popover id='filter-search-popover' className={styles.popvoer}>
                    <div className={styles.suggestions}>
                        <p>
                            Try using the following prefixes to refine your search. Combine multiple
                            categories with a space. Use comma separators to include multiple
                            values.
                        </p>
                        <p>
                            e.g <strong>co:</strong>name1,name2 <strong>r:</strong>senior consultant
                        </p>
                        <ul>
                            <li>
                                <strong>co:</strong> <em>&lt;consultant name&gt;</em>
                            </li>
                            <li>
                                <strong>r:</strong> <em>&lt;role name&gt;</em>
                            </li>
                            <li>
                                <strong>pr:</strong> <em>&lt;project name&gt;</em>
                            </li>
                            <li>
                                <strong>cl:</strong> <em>&lt;client name&gt;</em>
                            </li>
                            <li>
                                <strong>t:</strong> <em>&lt;task name&gt;</em>
                            </li>
                            <li>
                                <strong>s:</strong> <em>&lt;sales rep name&gt;</em>
                            </li>
                            <li>
                                <strong>pc:</strong> <em>&lt;project coordinator name&gt;</em>
                            </li>
                            <li>
                                <strong>pm:</strong> <em>&lt;program manager name&gt;</em>
                            </li>
                        </ul>
                    </div>
                </Popover>
            }
        >
            <div className={styles.filterSearch}>
                <input
                    className={classNames(styles.input, 'form-control')}
                    autoComplete='off'
                    type='text'
                    value={searchDebounce}
                    placeholder='Search'
                    onChange={(e) => setSearchDebounce(e.target.value)}
                    onKeyDown={(e: any) => {
                        if (e.which === 27) {
                            e.target.blur()
                        }
                    }}
                />
                {!!searchDebounce && (
                    <button className={styles.clearSearch} type='button' onClick={() => onChangeSearch('')}>
                        <span>x</span>
                    </button>
                )}
            </div>
        </OverlayTrigger>
    )
}
