import classNames from 'classnames'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { ProjectBudgetAuditResponse, ProjectBudgetSeverity } from 'domain/models'
import { dateFromIsoString, formatDateShort } from 'domain/dateHelpers'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import styles from '../ProjectBudgetAuditView.module.scss'
import { getProjectBudgetSeverityIcon } from 'domain/auditHelper'
import { useCallback } from 'react'

interface ProjectBudgetTotalsProps {
    projectBudgetAudit: ProjectBudgetAuditResponse
}

interface SubtotalsProps {
    name: string
    lastTimesheetedDate?: Date
    includeBookingsAfterDate?: Date
    plannedHoursTotal?: number
    plannedHoursRemaining?: number
    timesheeted: number
    timesheetedHours: number
    futureBookingsBySlipsTotal: number
    futureBookingsByDayTotal: number
    bookedHoursTotal: number
    bookedHoursFuture: number
    firstDay?: Date
    lastDay?: Date
}

export const ProjectBudgetTotals = ({ projectBudgetAudit }: ProjectBudgetTotalsProps) => {
    const formatBudgetValue = (budgetField: number | undefined) => {
        // Default to $0 when undefined
        return <CurrencyFormat amount={budgetField || 0} />
    }

    const consultantSubTotals = projectBudgetAudit.consultants.map(
        (consultantBudget): SubtotalsProps => {
            return {
                name: consultantBudget.userName,
                lastTimesheetedDate: dateFromIsoString(consultantBudget.lastTimesheetedDate),
                includeBookingsAfterDate: dateFromIsoString(
                    consultantBudget.includeBookingsAfterDate
                ),
                plannedHoursTotal: consultantBudget.plannedHoursTotal,
                plannedHoursRemaining: consultantBudget.plannedHoursRemaining,
                timesheeted: consultantBudget.timesheeted,
                timesheetedHours: consultantBudget.timesheetedHours,
                futureBookingsByDayTotal: consultantBudget.futureBookedFromBookingsByDay,
                futureBookingsBySlipsTotal: consultantBudget.futureBookedFromSlipProjections,
                bookedHoursTotal: consultantBudget.bookedHoursTotal,
                bookedHoursFuture: consultantBudget.bookedHoursFuture,
                firstDay: dateFromIsoString(consultantBudget.firstDay),
                lastDay: dateFromIsoString(consultantBudget.lastDay),
            }
        }
    )

    const BudgetCallouts = () => {
        return (
            <h5>
                Note:
                {projectBudgetAudit.warnings.length > 0 && (
                    <span className={classNames(styles.projectWarningsList)}>
                        {projectBudgetAudit.warnings.map((warning, index) => (
                            <li key={index}>
                                <span>{getProjectBudgetSeverityIcon(warning.severity)}</span>
                                <span>{warning.text}</span>
                            </li>
                        ))}
                    </span>
                )}
            </h5>
        )
    }

    const RemainingTcvOverallWarning = useCallback(() => {
        if (projectBudgetAudit.warnings.length === 0)
            return (null)

        const NumericSeverties = {
            [ProjectBudgetSeverity.Info]: 1,
            [ProjectBudgetSeverity.Warning] : 2,
            [ProjectBudgetSeverity.Error]: 3
        }
        const maxWarning = projectBudgetAudit.warnings
            .reduce((prev, next) => NumericSeverties[next.severity] > NumericSeverties[prev.severity] ? next : prev)

        const tooltip = (
            <Tooltip>See Notes Below</Tooltip>
        )

        return (
            <OverlayTrigger overlay={tooltip}>
                <span className={classNames(styles.remainingTcvIcon)}>{getProjectBudgetSeverityIcon(maxWarning.severity)}</span>
            </OverlayTrigger>
        )
    }, [projectBudgetAudit.warnings])

    const ProjectTotalsTable = () => {
        const projectTCV = projectBudgetAudit?.tcv
        const projectRemainingTCV = projectBudgetAudit?.remainingTcvFromBookingsByDay
        const projectNonTimeSlips = projectBudgetAudit?.nonTimeSlips
        const projectNonTimeSlipProjections = projectBudgetAudit?.nonTimeSlipProjections
        const emptyCell = <>&nbsp;</>
        const conLength = consultantSubTotals.length

        return (
            <Table className={classNames(styles.budgetTotals)} striped bordered hover>
                <thead>
                    <tr>
                        <th>Project Totals</th>
                        <th>{emptyCell}</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <th key={index}>{consultant.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className={styles.borderNone}>
                    <tr>
                        <td className={styles.tcv}>{formatBudgetValue(projectTCV)}</td>
                        <th>TCV</th>
                        <td colSpan={conLength}>{emptyCell}</td>
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>Planned Hours</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>{consultant.plannedHoursTotal}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>
                            Booked Hours
                            <br />
                            (Total)
                        </th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>{consultant.bookedHoursTotal}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>First Day</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>
                                {!!consultant.firstDay
                                    ? formatDateShort(consultant.firstDay)
                                    : 'n/a'}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>Last Day</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>
                                {!!consultant.lastDay ? formatDateShort(consultant.lastDay) : 'n/a'}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>
                            Last Submitted
                            <br />
                            Timesheet
                        </th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>
                                {!!consultant.lastTimesheetedDate
                                    ? formatDateShort(consultant.lastTimesheetedDate)
                                    : 'n/a'}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>
                            Timesheets Processed
                            <br />
                            Up To
                        </th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>
                                {!!consultant.includeBookingsAfterDate
                                    ? formatDateShort(consultant.includeBookingsAfterDate)
                                    : 'n/a'}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>Timesheeted Hours</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>{consultant.timesheetedHours}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>{formatBudgetValue(projectBudgetAudit?.timesheeted)}</td>
                        <th>
                            Timesheeted Amount
                            <br />
                            (Slips)
                        </th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>{formatBudgetValue(consultant.timesheeted)}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>Future Booking Hours</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>{consultant.bookedHoursFuture}</td>
                        ))}
                    </tr>
                    <tr>
                        <td>
                            {formatBudgetValue(projectBudgetAudit?.futureBookedFromBookingsByDay)}
                        </td>
                        <th>
                            Future Bookings Amount
                            <br />
                            (Bookings By Day)
                        </th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>
                                {formatBudgetValue(consultant.futureBookingsByDayTotal)}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>
                            {formatBudgetValue(projectBudgetAudit?.futureBookedFromSlipProjections)}
                        </td>
                        <th>
                            Future Bookings Amount
                            <br />
                            (Slip Projections)
                        </th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>
                                {formatBudgetValue(consultant.futureBookingsBySlipsTotal)}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        <td>{emptyCell}</td>
                        <th>Remaining Planned Hours</th>
                        {consultantSubTotals.map((consultant, index) => (
                            <td key={index}>{consultant.plannedHoursRemaining}</td>
                        ))}
                    </tr>
                    <tr>
                        <td
                            className={classNames(
                                styles.tcv,
                                !!projectRemainingTCV &&
                                    projectRemainingTCV < 0 &&
                                    styles.negativeTCV
                            )}
                        >
                            {formatBudgetValue(projectRemainingTCV)}
                            <RemainingTcvOverallWarning />
                        </td>
                        <th>Remaining TCV</th>
                        <td colSpan={conLength}>{emptyCell}</td>
                    </tr>
                    <tr>
                        <td>{formatBudgetValue(projectNonTimeSlips)}</td>
                        <th>Non-Time Slips</th>
                        <td colSpan={conLength}>{emptyCell}</td>
                    </tr>
                    <tr>
                        <td>{formatBudgetValue(projectNonTimeSlipProjections)}</td>
                        <th>Non-Time SlipProjections</th>
                        <td colSpan={conLength}>{emptyCell}</td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    return (
        <div>
            <ProjectTotalsTable />
            <BudgetCallouts />
        </div>
    )
}
