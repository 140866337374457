interface ReleaseNotesModel {
  version: string
  content: string // Markdown content
}

const notes = `
---
### 2023.11.0

#### Added
- PBI5324: Show 'remaining hours' as part of project budgets
- PBI5345: Export project hours for burn-up chart budgets
- PBI5456: Integration tests for budget calculations
- PBI5994: Support billing rate override in budget calcs for project yet to start
- PBI6056: Round bookings from 7.6 to 8 hours (configurable per squad)
- PBI6124: Add project summary view for team leads to report budgets/schedules to clients
- PBI6127: Add reset button to project budget calculator
- PBI6134: Add an option to show only rows with soft bookings

#### Improvements and BugFixes
- PBI5474: Remove 'budget is accurate' flag
- PBI6055: Improve audit view to help debug budget calc bugs
- PBI6122: Improve time to load of projects view
- PBI6123: Speedup audit & new project summary view
- PBI6129: Update burn-up chart template to streamline project hours update from peeps
- PBI6135: Put a delay on the tooltip appearing to avoid it getting in the way
- PBI6178: Allow project hours export beginning before project start date
- BUG5354: Login redirect URI incorrect if triggered on project audit page
- BUG5462: Project Budget Calculator calculations never complete (intermittently)
- BUG5473: Project budgets incorrect due to duplicated consultants
- BUG5938: Audit view export data incorrect
- BUG5939: Project budgets incorrect due to missing slips / last submitted timesheet date
- BUG5982: Weekend work not shown in project budget audit
- BUG5985: OutOfMemory showing audit view for large projects
- BUG5986: Timeout loading audit view for some projects
- BUG5995: User booking tooltip truncated at top of screen
- BUG5996: Incorrect default rates used - NAS not Purple
- BUG5997: Leave not shown in Peeps for some (IoT) consultants
- BUG6007: Projects view column sort doesn't work
- BUG6017: Enter' key clears search input
- BUG6119: Bookings not shown in Peeps for some (IoT) consultants
- BUG6120: Projects view filters don't work
- BUG6125: Budget audit view query timeout for some projects
- BUG6132: Incorrect default rates used - not updated with 2023 rate rise
- BUG6133: Resizing peeps cells fails
- BUG6139: Intermittent project budget query timeouts (mostly for devs)
- BUG6140: Project Budget Calculator bug consultant named 'Search Consultants'
- BUG6143: Budget editor no financials on project summary view
- BUG6177: Project hours export incorrect historical hours
- BUG6182: Project Audit View Reloads every minute
- BUG6202: Budgets/export incorrect if timesheets submitted, not yet approved, but slips generated

---
### 2023.10.0

#### Added
- Added what's new sidebar.

#### Improvements and BugFixes
- BUG PURTECHOPS-607: Fix peeps hidden by incorrect resource group filtering

---
`;

export const releaseNotesContent: ReleaseNotesModel = {
  content: notes,
  version: "2023.11.0"
}