import classNames from 'classnames'
import { CurrencyFormat } from 'components/Shared/CurrencyFormat/CurrencyFormat'
import { ProjectBudgetAuditResponse, ProjectBudgetSeverity } from 'domain/models'
import { dateFromIsoString, formatDateShort } from 'domain/dateHelpers'
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import styles from '../ProjectView.module.scss'
import { getProjectBudgetSeverityIcon } from 'domain/auditHelper'
import { useCallback, useMemo } from 'react'

interface ProjectBudgetTotalsProps {
    projectBudgetAudit: ProjectBudgetAuditResponse
    openSidePanel: () => void
}

interface SubtotalsProps {
    name: string
    lastTimesheetedDate?: Date
    includeBookingsAfterDate?: Date
    plannedHoursTotal?: number
    plannedHoursRemaining?: number
    timesheeted: number
    timesheetedHours: number
    futureBookingsBySlipsTotal: number
    futureBookingsByDayTotal: number
    bookedHoursTotal: number
    bookedHoursFuture: number
    firstDay?: Date
    lastDay?: Date
}

export const ProjectBudgetTotals = ({
    projectBudgetAudit,
    openSidePanel,
}: ProjectBudgetTotalsProps) => {
    const formatBudgetValue = (budgetField: number | undefined) => {
        // Default to $0 when undefined
        return <CurrencyFormat amount={budgetField || 0} />
    }

    const consultantSubTotals = useMemo(
        () =>
            projectBudgetAudit.consultants.map((consultantBudget): SubtotalsProps => {
                return {
                    name: consultantBudget.userName,
                    lastTimesheetedDate: dateFromIsoString(consultantBudget.lastTimesheetedDate),
                    includeBookingsAfterDate: dateFromIsoString(
                        consultantBudget.includeBookingsAfterDate
                    ),
                    plannedHoursTotal: consultantBudget.plannedHoursTotal,
                    plannedHoursRemaining: consultantBudget.plannedHoursRemaining,
                    timesheeted: consultantBudget.timesheeted,
                    timesheetedHours: consultantBudget.timesheetedHours,
                    futureBookingsByDayTotal: consultantBudget.futureBookedFromBookingsByDay,
                    futureBookingsBySlipsTotal: consultantBudget.futureBookedFromSlipProjections,
                    bookedHoursTotal: consultantBudget.bookedHoursTotal,
                    bookedHoursFuture: consultantBudget.bookedHoursFuture,
                    firstDay: dateFromIsoString(consultantBudget.firstDay),
                    lastDay: dateFromIsoString(consultantBudget.lastDay),
                }
            }),
        [projectBudgetAudit.consultants]
    )

    const BudgetCallouts = useCallback(() => {
        return (
            <div>
                <h5>Note:</h5>
                {projectBudgetAudit.warnings.length > 0 && (
                    <h6 className={classNames(styles.projectWarningsList)}>
                        {projectBudgetAudit.warnings.map((warning, index) => (
                            <li key={index}>
                                <span>{getProjectBudgetSeverityIcon(warning.severity)}</span>
                                <span>{warning.text}</span>
                            </li>
                        ))}
                    </h6>
                )}
            </div>
        )
    }, [projectBudgetAudit.warnings])

    const RemainingTcvOverallWarning = useCallback(() => {
        if (projectBudgetAudit.warnings.length === 0)
            return (null)

        const NumericSeverties = {
            [ProjectBudgetSeverity.Info]: 1,
            [ProjectBudgetSeverity.Warning] : 2,
            [ProjectBudgetSeverity.Error]: 3
        }
        const maxWarning = projectBudgetAudit.warnings
            .reduce((prev, next) => NumericSeverties[next.severity] > NumericSeverties[prev.severity] ? next : prev)

        const tooltip = (
            <Tooltip>See Notes Below</Tooltip>
        )

        return (
            <OverlayTrigger overlay={tooltip}>
                <span className={classNames(styles.remainingTcvIcon)}>{getProjectBudgetSeverityIcon(maxWarning.severity)}</span>
            </OverlayTrigger>
        )
    }, [projectBudgetAudit.warnings])

    const ProjectTotalsTable = () => {
        return (
            <Table className={classNames(styles.budgetTotals)} striped bordered hover>
                <thead>
                    <tr>
                        <th>Consultant</th>
                        <th>First Day</th>
                        <th>Last Day</th>
                        <th>Planned Hours</th>
                        <th>Remaining Hours</th>
                        <th>Processed Up To</th>
                        <th>Timesheeted Hours</th>
                        <th>Timesheeted Amount</th>
                        <th>Future Booking Hours</th>
                        <th>Future Booking Amount</th>
                    </tr>
                </thead>
                <tbody className={styles.borderNone}>
                    {consultantSubTotals.map((consultant, index) => (
                        <tr key={consultant.name}>
                            <th>{consultant.name}</th>
                            <td>
                                {!!consultant.firstDay
                                    ? formatDateShort(consultant.firstDay)
                                    : 'n/a'}
                            </td>
                            <td>
                                {!!consultant.lastDay ? formatDateShort(consultant.lastDay) : 'n/a'}
                            </td>
                            <td>{consultant.plannedHoursTotal}</td>
                            <td>{consultant.plannedHoursRemaining}</td>
                            <td>
                                {!!consultant.includeBookingsAfterDate
                                    ? formatDateShort(consultant.includeBookingsAfterDate)
                                    : 'n/a'}
                            </td>
                            <td>{consultant.timesheetedHours}</td>
                            <td>{formatBudgetValue(consultant.timesheeted)}</td>
                            <td>{consultant.bookedHoursFuture}</td>
                            <td>{formatBudgetValue(consultant.futureBookingsByDayTotal)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )
    }

    const ProjectOverviewTable = useCallback(() => {
        const projectTCV = projectBudgetAudit?.tcv
        const projectRemainingTCV = projectBudgetAudit?.remainingTcvFromBookingsByDay
        const projectNonTimeSlips = projectBudgetAudit?.nonTimeSlips

        return (
            <Table className={classNames(styles.budgetTotals)} striped bordered hover>
                <thead>
                    <tr>
                        <th>TCV</th>
                        <th>Timesheeted Amount</th>
                        <th>Future Booking Amount</th>
                        <th>Remaining TCV</th>
                        <th>Non-Time Slips</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className={styles.borderNone}>
                    <tr>
                        <td className={styles.tcv}>{formatBudgetValue(projectTCV)}</td>
                        <td>{formatBudgetValue(projectBudgetAudit?.timesheeted)}</td>
                        <td>
                            {formatBudgetValue(projectBudgetAudit?.futureBookedFromBookingsByDay)}
                        </td>
                        <td
                            className={classNames(
                                styles.tcv,
                                !!projectRemainingTCV &&
                                    projectRemainingTCV < 0 &&
                                    styles.negativeTCV
                            )}
                        >
                            {formatBudgetValue(projectRemainingTCV)}
                            <RemainingTcvOverallWarning />
                        </td>
                        <td>{formatBudgetValue(projectNonTimeSlips)}</td>
                        <td>
                            <i
                                className={`fa fa-pencil ${styles.clickable}`}
                                onClick={openSidePanel}
                            ></i>
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }, [
        projectBudgetAudit.tcv,
        projectBudgetAudit.remainingTcvFromBookingsByDay,
        projectBudgetAudit.nonTimeSlips,
        projectBudgetAudit.timesheeted,
        projectBudgetAudit.futureBookedFromBookingsByDay,
        RemainingTcvOverallWarning,
        openSidePanel
    ])

    return (
        <div>
            <ProjectOverviewTable />
            <ProjectTotalsTable />
            <BudgetCallouts />
        </div>
    )
}
