import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import styles from '../DropdownSelect.module.scss'

interface DropdownSingleSelectProps {
    name: string
    options: string[]
    selected: string
    onChange: (selected: string) => void
    buttonClass?: string
    entityTypePluralName?: string
}

export const SingleSelect = (props: DropdownSingleSelectProps) => {
    const [state, setState] = useState({
        showDropdown: false,
    })

    const ref = useRef(null)
    useClickAway(ref, () => setState({ ...state, showDropdown: false }))

    const handleChange = (e: any) => {
        if (e.currentTarget === undefined) {
            return
        }
        showDropdown(false)
        props.onChange(e.currentTarget.value)
    }

    const showDropdown = (showDropdown: boolean = !state.showDropdown) => {
        setState({
            showDropdown: showDropdown,
        })
    }

    return (
        <div className='dropdown' ref={ref}>
            <button
                className={classNames(
                    'dropdown-toggle',
                    'form-control',
                    props.buttonClass,
                    styles.dropdownToggle
                )}
                type='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={() => showDropdown()}
                title={props.entityTypePluralName ?? props.name}
            >
                <span className={styles.overflowHandler}>{props.selected.toString()}</span>
            </button>
            <div
                className={classNames(
                    'dropdown-menu',
                    !!state.showDropdown && 'show',
                    styles.dropdownMenu
                )}
            >
                {/* Display a non-clickable item for the menu header first */}
                <input
                    value={props.name}
                    id={`SingleSelect-${props.name}`}
                    className={classNames('form-check-input', styles.hideInput)}
                    type='radio'
                    name={`${props.name}[]`}
                    disabled={true}
                />
                <label
                    className={classNames('dropdown-item', styles.dropdownItem, 'disabled')}
                    htmlFor={`SingleSelect-${props.name}`}
                >
                    <div className={classNames('form-check', styles.formCheck, styles.noPadding)}>
                        <div className='form-check-label'>{props.name}</div>
                    </div>
                </label>
                {props.options.map((option, index) => (
                    <div key={index}>
                        <input
                            value={option}
                            id={`SingleSelect-${props.name}-${index}`}
                            className={classNames('form-check-input', styles.hideInput)}
                            type='radio'
                            name={`${props.name}[]`}
                            onChange={(ev) => handleChange(ev)}
                            onClick={() => showDropdown(false)}
                            checked={props.selected === option}
                        />
                        <label
                            className={classNames(
                                'dropdown-item',
                                styles.dropdownItem,
                                props.selected === option && styles.active
                            )}
                            htmlFor={`SingleSelect-${props.name}-${index}`}
                        >
                            <div
                                className={classNames(
                                    'form-check',
                                    styles.formCheck,
                                    styles.noPadding
                                )}
                            >
                                <div className='form-check-label'>{option}</div>
                            </div>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}
