import { getTodayDate } from 'domain/dateHelpers'
import { BookableConsultant, ProjectBudget, ProposedBudget } from 'domain/models'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { IBudgetFormState } from '../ProjectBudgetAdjustor'
import styles from './BudgetAdjustmentCalculator.module.scss'
import { BudgetAdjustmentTable } from './BudgetAdjustmentTable/BudgetAdjustmentTable'
import { BudgetConsultantTable } from './BudgetConsultantTable/BudgetConsultantTable'
import { IEditBudgetFormState } from './BudgetConsultantTable/components/BudgetConsultantRow/EditBudgetConsultantRowForm'

interface IBudgetAdjustmentCalculatorProps {
    projectId: number
    projectName: string
    projectBudget: ProjectBudget
    bookedConsultants: IBudgetFormState[]
    bookableConsultants: BookableConsultant[]
}

export const BudgetAdjustmentCalculator = (props: IBudgetAdjustmentCalculatorProps) => {
    const defaultConsultantDetails = props.bookedConsultants.map((a, i) =>
        createNewEditBudgetFormState(a)
    )
    const [consultantDetails, setConsultantDetails] =
        useState<IEditBudgetFormState[]>(defaultConsultantDetails)

    function createNewEditBudgetFormState(row: IBudgetFormState): IEditBudgetFormState {
        return {
            ...row,
            projectId: props.projectId,
            proposedEndDate: row.endDate,
            proposedBudget: undefined,
            ItemKey: uuidv4(),
            isCalculating: false,
        }
    }

    const createNewConsultantRow: IEditBudgetFormState = {
        projectId: props.projectId,
        proposedEndDate: getTodayDate(),
        proposedBudget: undefined,
        email: '',
        name: '',
        startDate: getTodayDate(),
        endDate: undefined,
        isCalculating: false,
        ItemKey: uuidv4(),
        rate: 0,
    }

    const onBudgetAdjustReset = () => {
        setConsultantDetails(defaultConsultantDetails)
    }

    const onBudgetConsultantChange = (row: IEditBudgetFormState) => {
        setConsultantDetails((rs) =>
            rs.map((item, i) => (item.ItemKey === row.ItemKey ? row : item))
        )
    }
    const onBudgetConsultantDelete = (itemKey: string) => {
        setConsultantDetails((rs) => rs.filter((item, i) => item.ItemKey !== itemKey))
    }
    const onBudgetConsultantAddNew = () => {
        setConsultantDetails((rs) => [...rs, createNewConsultantRow])
    }

    const calculateBudgetAdjustments = (budgets: IEditBudgetFormState[]) => {
        return budgets
            .map((a) => a.proposedBudget)
            .filter((b): b is ProposedBudget => !!b)
            .map((b) => b.budgetAdjustmentAmount)
            .reduce((prev, next) => prev + next, 0)
    }

    const isCalculatingBudgetAdjustments = (budgets: IEditBudgetFormState[]) => {
        return budgets.some((a) => a.isCalculating)
    }

    return (
        <div className={styles.budgetAdjustments}>
            <div className={styles.title}>
                <h4>
                    Project Budget Calculator - <span>{props.projectName}</span>
                </h4>
            </div>

            {consultantDetails && (
                <BudgetConsultantTable
                    consultantDetails={consultantDetails}
                    onBudgetConsultantChange={onBudgetConsultantChange}
                    onDeleteRow={onBudgetConsultantDelete}
                    bookableConsultants={props.bookableConsultants}
                />
            )}

            <button
                className={styles.addConsultant}
                onClick={onBudgetConsultantAddNew}
                aria-label='Add Consultant'
            >
                <i className='fa fa-plus'></i> Add consultant
            </button>

            <button type='button' className={styles.resetBtn} onClick={onBudgetAdjustReset}>
                Reset
            </button>
            {props.projectBudget && (
                <>
                    <p>Project Budget</p>
                    <BudgetAdjustmentTable
                        projectBudget={props.projectBudget}
                        budgetAdjustments={calculateBudgetAdjustments(consultantDetails)}
                        isCalculating={isCalculatingBudgetAdjustments(consultantDetails)}
                    />
                </>
            )}
        </div>
    )
}
