import classNames from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styles from './ReleaseNotesToggle.module.scss'

type ReleaseNotesToggleProps = {
    isReleaseNotesShown: boolean
    toggleShowReleaseNotes: () => void
}

export const ReleaseNotesToggle = (
    { isReleaseNotesShown, toggleShowReleaseNotes,}: ReleaseNotesToggleProps) => (
        <OverlayTrigger
        placement='bottom'
        overlay={
            <Tooltip id='colour-blind-tooltip'>
                <div>Show What's new</div>
            </Tooltip>
        }
    >
        <div
            className={classNames(styles.releaseNotesToggle, isReleaseNotesShown && styles.on)}
            onClick={toggleShowReleaseNotes}
        >
            <div
               className={classNames(
                styles.icon,
                'fa fa-file-text-o'
            )}
            ></div>
        </div>
    </OverlayTrigger>
)
