import moment from 'moment/moment'
import {
    getPeepsEndDate,
    getPeepsStartDate,
    getTodayDate,
    getWorkingDaysInRange,
} from './dateHelpers'
import { getFilterWhitespace, getFilterBench, getFilterSoftBookingsOnly } from './filterHelpers'
import {
    Consultant,
    Engagement,
    EngagementEntry,
    FilterState,
    PeepsCell,
    PeepsProject,
    ViewType,
} from './models'

// TODO: use actual Consultant & ResourceGroup types from models.ts
// BACKGROUND: When this app was refactored from JS to TS (Mar 2022), some TypeScript errors
// emerged in this file when we added types for Consultant & ResourceGroup, but since the code
// continued to work as expected, this temporary workaround was made so as to not block
// other development. But alas now we have run out of time, and so these `any`s remain.
// A developer reading this in the future might like to tidy this up by using the actual types ;)
type ResourceGroup = any

export const searchFields: any = {
    any: 'Any field',
    consultant: 'Consultant name',
    client: 'Client name',
    project: 'Project name',
    task: 'Task name',
    role: 'Consultant role',
    salesrep: 'Sales rep name',
    pc: 'Project coordinator name',
    pm: 'Program manager name',
}

export const filterRows = (consultants: Consultant[], filters: FilterState): PeepsCell[] => {
    const {
        search,
        resourceGroups,
        squads,
        viewType,
        startDate,
        endDate,
        showTerminated,
        showNonBillable,
    } = filters

    if (getFilterSoftBookingsOnly(viewType)) {
        return consultants
            .filter((c) =>
                Object.keys(c.engagements).some((key) =>
                    c.engagements[key].entries.some(
                        (e) => !e.isHardBooking && e.programManagerSquad !== 'Unassigned'
                    )
                )
            )
            .filter((c) =>
                Object.keys(c.engagements).some((key) => c.engagements[key].entries.length > 0)
            )
            .filter(applyResourceGroupFilter(resourceGroups))
            .filter(applySquadFilter(squads))
            .filter(applyTextFilter(search))
            .map(getTextFilter(search))
            .map((p) => createCellArray(p))
    }

    if (getFilterWhitespace(viewType)) {
        consultants = consultants.filter(hasWhiteSpace(startDate, endDate))
    }

    if (getFilterBench(viewType)) {
        const isBenchWeek = viewType === ViewType.BenchWeek
        const isBenchMonth = viewType === ViewType.BenchMonth
        const overrideEndDate = isBenchWeek
            ? moment(startDate).add(6, 'day').toDate()
            : isBenchMonth
            ? moment(startDate).add(1, 'month').add(-1, 'day').toDate()
            : endDate

        consultants = consultants.filter(hasBench(startDate, overrideEndDate))
    }

    if (!showTerminated) {
        consultants = consultants.filter(
            (c) =>
                c.isActive &&
                (!c.employmentEndDate || moment(c.employmentEndDate).isAfter(moment()))
        )
    }

    if (!showNonBillable) {
        const nonBillableRoles = ['Principal Consultant']
        consultants = consultants.filter((c) => !nonBillableRoles.includes(c.role))
    }

    let consultantRows = consultants
        .filter(applyResourceGroupFilter(resourceGroups))
        .filter(applySquadFilter(squads))
        .filter(applyTextFilter(search))
        .map(getTextFilter(search))

    // Keep the original project view code for existing use
    if (viewType === ViewType.Project) {
        let allConsultants = splitUnassignedConsultants(consultantRows)
        return allConsultants.map(createCellArray)
    }

    // The new Projects Grouped View Type
    if (viewType === ViewType.ProjectsGrouped) {
        return formatProjectsGroupedView(consultantRows)
    }
    return consultantRows.map(createCellArray)
}

const getTextFilter = (filterSearch: any) => {
    if (!filterSearch) return (consultant: Consultant) => consultant

    let tokens = tokenizeSearch(filterSearch)
    return (consultant: Consultant) => {
        // If user is not searching for a specific client/project then we don't need to highlight matched entries.
        if (
            tokens.every(
                (t) =>
                    !![searchFields.any, searchFields.consultant, searchFields.role].find(
                        (x) => x === t.field
                    )
            )
        ) {
            return consultant
        }
        let res: Consultant = {
            ...consultant,
            engagements: {},
        }

        Object.keys(consultant.engagements).forEach((key) => {
            let original = consultant.engagements[key]
            res.engagements[key] = {
                ...original,
                entries: original.entries.map((e: EngagementEntry) => {
                    let entryMatchesSearch = tokens.some((t) => {
                        if (t.field === searchFields.client || t.field === searchFields.any) {
                            if (referenceContainsAnyMatch(e.client, t.values)) return true
                        }
                        if (t.field === searchFields.project || t.field === searchFields.any) {
                            if (referenceContainsAnyMatch(e.project, t.values)) return true
                        }
                        if (t.field === searchFields.task || t.field === searchFields.any) {
                            if (referenceContainsAnyMatch(e.task, t.values)) return true
                        }
                        if (t.field === searchFields.salesrep || t.field === searchFields.any) {
                            if (referenceContainsAnyMatch(e.salesRepName, t.values)) return true
                        }
                        if (t.field === searchFields.pc || t.field === searchFields.any) {
                            if (referenceContainsAnyMatch(e.projectCoordinatorName, t.values))
                                return true
                        }
                        if (t.field === searchFields.pm || t.field === searchFields.any) {
                            if (referenceContainsAnyMatch(e.programManagerName, t.values))
                                return true
                        }
                        return false
                    })
                    return {
                        ...e,
                        entryMatchesSearch,
                    }
                }),
            }
        })
        return res
    }
}

const referenceStartsWithMatch = (reference: any, values: any[]): boolean => {
    if (!reference) {
        return false
    }

    if (values.some((value) => value && reference.toLowerCase().startsWith(value.toLowerCase())))
        return true
    else return false
}

const referenceContainsAnyMatch = (reference: any, values: any[]): boolean => {
    const normalizeReferenceForComparison = (str: string): string =>
        str
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .toLowerCase()

    if (!reference) {
        return false
    }
    if (
        values.some(
            (value) =>
                value &&
                normalizeReferenceForComparison(reference).indexOf(
                    normalizeReferenceForComparison(value)
                ) !== -1
        )
    )
        return true
    else return false
}

export const tokenizeSearch = (filterSearch: any) => {
    if (!filterSearch) return []
    if (filterSearch.indexOf(':') === -1) {
        return [
            {
                values: filterSearch.trim().split(','),
                field: searchFields.any,
            },
        ]
    }

    let filterArray = filterSearch.split('')

    let tokens: any[] = []
    while (filterArray.length) {
        let value = consumeUntil(':')(filterArray).trim()
        if (filterArray.length === 0) {
            // no field name
            if (value.length === 0) return tokens
            return [
                ...tokens,
                {
                    values: value.split(',').map((v) => v.trim()),
                    field: searchFields.any,
                },
            ]
        }
        let fieldPrefix = consumeUntil(' ')(filterArray)
        if (value.length === 0) continue
        let values = value.split(',').map((v) => v.trim())
        tokens = [...tokens, ...createTokenForFieldPrefix(fieldPrefix, values)]
    }
    return tokens
}

const createTokenForFieldPrefix = (prefix: string, values: any[]) => {
    return Object.keys(searchFields)
        .filter((f) => f.startsWith(prefix.toLowerCase()))
        .map((f) => ({
            field: searchFields[f],
            values,
        }))
}

const consumeUntil = (char: string) => (array: any[]) => {
    let res = ''
    let cur = null
    while (array.length && (cur = array.pop()) !== char) {
        res = cur + res
    }
    return res
}

const applyResourceGroupFilter = (resourceGroups: ResourceGroup[]) => (consultant: Consultant) =>
    resourceGroups.some((r) => r === consultant.resourceGroup)

const applySquadFilter = (filterSquads: string[]) => (consultant: Consultant) => {
    if (filterSquads && filterSquads.some((s) => s === consultant.squad)) return true

    const engagementEntries = Object.values(consultant.engagements).reduce<EngagementEntry[]>(
        (acc, engagement) => acc.concat(engagement.entries || []),
        []
    )

    const projectCoordinatorSquads = engagementEntries
        .map((entry) => entry.projectCoordinatorSquad)
        .filter((entry, i, arr) => arr.indexOf(entry) === i) // distinct

    const programManagerSquads = engagementEntries
        .map((entry) => entry.programManagerSquad)
        .filter((entry, i, arr) => arr.indexOf(entry) === i) // distinct

    const hasFilteredSquad = (squads: string[]): boolean =>
        squads.reduce<boolean>((acc, squad) => acc || filterSquads.includes(squad), false)

    return hasFilteredSquad(projectCoordinatorSquads) || hasFilteredSquad(programManagerSquads)
}

const applyTextFilter = (filterSearch: any) => (consultant: Consultant) => {
    if (!filterSearch) return (consultant: Consultant) => consultant

    let tokens = tokenizeSearch(filterSearch)

    let isMatch = tokens.every((t) => {
        if (t.field === searchFields.consultant || t.field === searchFields.any) {
            if (referenceContainsAnyMatch(consultant.name, t.values)) return true
        }
        if (t.field === searchFields.role || t.field === searchFields.any) {
            if (referenceStartsWithMatch(consultant.role, t.values)) return true
        }
        return Object.keys(consultant.engagements).some(
            (date) =>
                consultant.engagements[date] &&
                consultant.engagements[date].entries.some((engagement: EngagementEntry) => {
                    if (t.field === searchFields.client || t.field === searchFields.any) {
                        if (referenceContainsAnyMatch(engagement.client, t.values)) return true
                    }
                    if (t.field === searchFields.project || t.field === searchFields.any) {
                        if (referenceContainsAnyMatch(engagement.project, t.values)) return true
                    }
                    if (t.field === searchFields.task || t.field === searchFields.any) {
                        if (referenceContainsAnyMatch(engagement.task, t.values)) return true
                    }
                    if (t.field === searchFields.salesrep || t.field === searchFields.any) {
                        if (referenceContainsAnyMatch(engagement.salesRepName, t.values))
                            return true
                    }
                    if (t.field === searchFields.pc || t.field === searchFields.any) {
                        if (referenceContainsAnyMatch(engagement.projectCoordinatorName, t.values))
                            return true
                    }
                    if (t.field === searchFields.pm || t.field === searchFields.any) {
                        if (referenceContainsAnyMatch(engagement.programManagerName, t.values))
                            return true
                    }
                    return false
                })
        )
    })

    if (!isMatch) return false

    return isMatch
}

const hasWhiteSpace = (startDate: Date, endDate: Date) => (consultant: Consultant) => {
    const dateFormatString = 'D/M/YYYY'
    const eDate = moment(endDate)
    const sDate = moment(startDate)
    let isAvailable = false

    while (sDate.isSameOrBefore(eDate, 'day')) {
        const datekey = sDate.format(dateFormatString)
        const engagement = consultant.engagements[datekey]
        if (engagement && (engagement.availableHours > 0 || engagement.isAvailable)) {
            isAvailable = true
            break
        }

        sDate.add(1, 'days')
    }

    if (isAvailable) {
        return true
    }

    return false
}

const hasBench = (startDate: Date, endDate: Date) => (consultant: Consultant) => {
    if (hasWhiteSpace(startDate, endDate)(consultant)) return true
    return false
}

/**
 * Remap the Consultant array as PeepsCell array
 */
const createCellArray = (consultant: Consultant): PeepsCell => ({ cell: consultant })

/**
 * Un-group and split unassigned consultants (original project view)
 */
const splitUnassignedConsultants = (consultants: Consultant[]): Consultant[] => {
    let consultantsByProjects = consultants
        .reduce<Consultant[]>((acc, consultant) => {
            // If the consultant isn't 'unassigned', do nothing
            if (consultant.resourceGroup !== 'unassigned') {
                return acc.concat(consultant)
            }
            // Make a list of all unique projectIds unassigned consultants are booked on
            let uniqueProjectIds: number[] = Object.keys(consultant.engagements)
                .reduce<number[]>((acc, date) => {
                    let projectIds: number[] = consultant.engagements[date].entries
                        .map(({ projectId }) => projectId)
                        .filter(
                            (projectId) => !!projectId && ![4, 6].includes(projectId) // Filter out empty, "Internal" (4) and "Leave" (6))
                        )
                    return acc.concat(projectIds)
                }, [])
                .filter((projectId, i, arr) => projectId && arr.indexOf(projectId) === i) // Distinct

            // Split unassigned consultants into multiple consultants
            let consultantRowsPerProject: Consultant[] = uniqueProjectIds.map((projectId) => {
                let engagements = Object.keys(consultant.engagements).reduce<{
                    [key: string]: Engagement
                }>((acc, date) => {
                    let entries = (consultant.engagements[date]?.entries || []).filter(
                        (entry) => entry.projectId === projectId
                    )
                    let engagement: Engagement = {
                        ...consultant.engagements[date],
                        entries,
                    }
                    acc[date] = engagement
                    return acc
                }, {})
                return { ...consultant, engagements }
            })
            return acc.concat(consultantRowsPerProject)
        }, [])
        .sort(applyProjectSort())
        .filter((consultant) => {
            return getNextProject(consultant).projectString !== ''
        })

    return consultantsByProjects
}

/**
 * Modify the consultant rows for project view
 */
const formatProjectsGroupedView = (consultants: Consultant[]): PeepsCell[] => {
    let allConsultants = splitConsultants(consultants)
    let projectRows = addProjectHeaderRows(allConsultants)
    return projectRows
}

/**
 * Un-group and split all consultants, one row for each project they are booked
 */
const splitConsultants = (consultants: Consultant[]): Consultant[] => {
    let consultantsByProjects = consultants
        .reduce<Consultant[]>((acc, consultant) => {
            // Make a list of all unique projectIds
            let uniqueProjectIds: number[] = Object.keys(consultant.engagements)
                .reduce<number[]>((acc, date) => {
                    let projectIds: number[] = consultant.engagements[date].entries
                        .map(({ projectId }) => projectId)
                        .filter(
                            (projectId) => !!projectId && ![4, 6].includes(projectId) // Filter out empty, "Internal" (4) and "Leave" (6))
                        )
                    return acc.concat(projectIds)
                }, [])
                .filter((projectId, i, arr) => projectId && arr.indexOf(projectId) === i) // Distinct

            // Split each consultants into multiple consultants
            let consultantRowsPerProject: Consultant[] = uniqueProjectIds.map((projectId) => {
                let engagements = Object.keys(consultant.engagements).reduce<{
                    [key: string]: Engagement
                }>((acc, date) => {
                    let entries = (consultant.engagements[date]?.entries || []).filter(
                        (entry) => entry.projectId === projectId
                    )
                    let engagement: Engagement = {
                        ...consultant.engagements[date],
                        entries,
                    }
                    acc[date] = engagement
                    return acc
                }, {})
                return { ...consultant, engagements }
            })
            return acc.concat(consultantRowsPerProject)
        }, [])
        .sort(applyProjectSort())
        .filter((consultant) => {
            return getNextProject(consultant).projectString !== ''
        })

    return consultantsByProjects
}

/**
 * Given a set of consultants, insert a new header row before
 * grouped projects and return as array
 */
const addProjectHeaderRows = (rows: Consultant[]): PeepsCell[] => {
    let projects = uniqueProjects(rows)
    let projectRows = rows.map(createCellArray)

    let counter = 0
    projects.forEach((uniqueProject) => {
        projectRows.splice(counter, 0, { cell: generatePeepsProject(true, uniqueProject.project) })
        counter += uniqueProject.count + 1
    })

    return projectRows
}

/**
 * Returns each unique Project and a count of how many rows the project is on
 */
const uniqueProjects = (rows: Consultant[]) => {
    let allProjects = rows.map((consultant) => getNextProject(consultant))

    let uniqueProjects = allProjects.filter(
        (project, i, arr) => arr.map((x) => x.projectID).indexOf(project.projectID) === i
    )

    let projectCount = uniqueProjects.map((project) => {
        let count = allProjects.filter((x) => x.projectID === project.projectID).length
        return { project, count }
    })

    return projectCount
}

/**
 * Group and sort consultants by project
 */
const applyProjectSort = () => (a: any, b: any) => {
    let pa = getNextProject(a)
    let pb = getNextProject(b)
    let paString = !!pa && pa.projectString
    let pbString = !!pb && pb.projectString
    let isEqual = !!paString && !!pbString && paString === pbString
    return isEqual ? 0 : paString > pbString ? 1 : -1
}

/**
 * Create new object of type peeps project
 * using the project props or creating an empty object
 */
const generatePeepsProject = (isHeader: boolean, project?: PeepsProject): PeepsProject => {
    if (!!project) {
        return {
            projectString: project.projectString,
            client: project.client,
            projectName: project.projectName,
            projectID: project.projectID,
            isHeaderCell: isHeader,
        }
    }

    return {
        projectString: '',
        client: '',
        projectName: '',
        projectID: 0,
        isHeaderCell: isHeader,
    }
}

/**
 * Gets the next project for a consultant within range - first searching future, then past
 */
export const getNextProject = (consultant: Consultant): PeepsProject => {
    const pastDates = getWorkingDaysInRange(getPeepsStartDate(), getTodayDate()).reverse()
    const futureDates = getWorkingDaysInRange(getTodayDate(), getPeepsEndDate())

    // Find next future project within range
    for (let i = 0; i < futureDates.length; i++) {
        let p = getProjectForDate(consultant, futureDates[i])
        if (!!p.projectString) return p
    }

    // Find next previous project within range
    for (let i = 0; i < pastDates.length; i++) {
        let p = getProjectForDate(consultant, pastDates[i])
        if (!!p.projectString) return p
    }

    // Return empty project if none found
    return generatePeepsProject(false)
}

/**
 * Returns the engagement's project information for specific date
 * Ignores empty, leave and pd bookings
 */
const getProjectForDate = (consultant: Consultant, date: Date): PeepsProject => {
    let key = moment(date).format('D/M/YYYY')
    let engagements = consultant.engagements[key]

    if (!!engagements && !!engagements.entries && engagements.entries.length > 0) {
        let { client, project, projectId } = engagements.entries[0]
        if (project !== 'Leave' && project !== 'Professional Development' && project !== '') {
            let projectString = [client, project, projectId].join(' - ')
            return generatePeepsProject(false, {
                projectString: projectString,
                client: client,
                projectName: project,
                projectID: projectId,
            })
        }
    }

    return generatePeepsProject(false)
}
