import {
    AppStore,
    Consultant,
    ConsultantProjectSearch,
    FilterState,
    ResourceGroup,
    ViewType,
} from './models'

export const getDefaultSelectedSquads = ({ loggedInConsultant, squads }: AppStore): string[] =>
    loggedInConsultant?.squad ? [loggedInConsultant.squad] : squads.map(({ name }) => name)

export const getFilterWhitespace = (viewType: ViewType): boolean => viewType === ViewType.Whitespace

export const getFilterBench = (viewType: ViewType): boolean =>
    viewType === ViewType.BenchWeek || viewType === ViewType.BenchMonth

export const getFilterSoftBookingsOnly = (viewType: ViewType): boolean =>
    viewType === ViewType.SoftBookings

export const getVisibleResourceGroups = (store: AppStore, filters: FilterState): ResourceGroup[] =>
    getFilterWhitespace(filters.viewType)
        ? store.resourceGroups.filter((r) => r.isWhiteSpaceGroup)
        : store.resourceGroups.filter((resourceGroup) =>
              filters.squads.length > 0
                  ? !!resourceGroup.squads?.reduce<boolean>(
                        (acc, squad) => acc || filters.squads.includes(squad),
                        false
                    )
                  : true
          )

export const getAutoSelectedResourceGroups = (store: AppStore, filters: FilterState): string[] =>
    getVisibleResourceGroups(store, filters)
        .filter(() => filters.squads.length > 0)
        .filter(
            (r) =>
                !['Sales', 'Marketing', 'Finance', 'People', 'Delivery'].some((f) =>
                    r.name.startsWith(f)
                )
        )
        .map((r) => r.name)

export const getConsultantSearchDataSource = (validConsultants: Consultant[]) => {
    var results = validConsultants.map((consul) => {
        return convertConsultantToConsultantSearch(consul)
    })

    return results
}

function convertConsultantToConsultantSearch(consul: Consultant): ConsultantProjectSearch {
    const engagements = Object.entries(consul.engagements)
        .map(([Key, value]) => value.entries)
        .filter((value) => value)
        .flat()

    return {
        name: consul.name,
        email: consul.email,
        rolename: consul.role,
        projectname: engagements
            .map((value) => value.project)
            .filter(distinct)
            .join(';'),
        clientname: engagements
            .map((value) => value.client)
            .filter(distinct)
            .join(';'),
        taskname: engagements
            .map((value) => value.task)
            .filter(distinct)
            .join(';'),
        salesname: engagements
            .map((value) => value.salesRepName)
            .filter(distinct)
            .join(';'),
        projectcoordinatorname: engagements
            .map((value) => value.projectCoordinatorName)
            .filter(distinct)
            .join(';'),
    }
}

const distinct = <T extends string>(value: T, index: number, array: T[]) => {
    return value && array.indexOf(value) === index
}
