import { HoverableTooltip } from 'components/Shared/HoverableTooltip/HoverableTooltip'
import { Popover } from 'react-bootstrap'
import styles from './ProjectTableMenu.module.scss'

interface ProjectTableMenuProps {
    showProjectBudgetAuditTools: boolean
    toggleShowProjectBudgetAuditTools: () => void
}

export const ProjectTableMenu = ({
    showProjectBudgetAuditTools,
    toggleShowProjectBudgetAuditTools,
}: ProjectTableMenuProps) => (
    <HoverableTooltip
        placement='bottom'
        tool={
            <button className={styles.filterButton}>
                <i className='fa fa-regular fa-bars'></i>
            </button>
        }
        tip={
            <Popover id='project-table-menu-popover'>
                <div className={styles.filters}>
                    <div className={styles.filter}>
                        <label htmlFor='terminated-checkbox'>Budget Audit Mode</label>
                        <input
                            id='terminated-checkbox'
                            type='checkbox'
                            checked={showProjectBudgetAuditTools}
                            onChange={toggleShowProjectBudgetAuditTools}
                        ></input>
                    </div>
                </div>
            </Popover>
        }
    />
)
