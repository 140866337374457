import classnames from 'classnames'
import { dateFromDateKey, dateFromIsoString } from 'domain/dateHelpers'
import {
    Consultant,
    EngagementEntry,
    Holiday,
    isConsultant,
    PeepsCell,
    ProjectBudget,
    SourceToggleSource,
    ViewType,
} from 'domain/models'
import { createEvent } from 'ics'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { EngagementTooltip } from './EnagagementTooltip/EnagagementTooltip'
import './PeepCell.scss'

export interface PeepCellProps {
    cell: PeepsCell
    holidays: Holiday[]
    dateKey: string
    viewType: ViewType
    projectBudgets: ProjectBudget[]
    showNetSuite: boolean
    isColourBlindMode: boolean
    isWeekSpacer: boolean
    height: number
    openBudgetAdjustmentCalculator: (projectId: number) => void
}

export const PeepCell = (props: PeepCellProps) => {
    const consultant = props.cell.cell
    const date = dateFromDateKey(props.dateKey)

    // If the cell is not a consultant type, display as project header cell
    if (!isConsultant(consultant)) {
        return (
            <div
                className='projectHeader'
                style={{
                    height: props.height,
                }}
            />
        )
    }

    const isFullViewType = props.viewType === ViewType.Full

    const getICS = (consultant: Consultant, engagementEntry: EngagementEntry) => {
        const title = `Engagement: ${consultant.name} - ${engagementEntry.project}`
        const description = consultant.engagements[props.dateKey].isTimesheet
            ? `${engagementEntry.project} - ${engagementEntry.task}\\n\\nTimesheet entry\\n\\nAppointment downloaded from PurplePeeps`
            : `${engagementEntry.project} - ${engagementEntry.task}\\n\\nEngagement start: ${engagementEntry.startDate}\\n\\nEngagement end: ${engagementEntry.endDate}\\n\\nAppointment downloaded from PurplePeeps`
        const location = engagementEntry.client

        const startYear = moment(date).year()
        const startMonth = moment(date).month() + 1
        const startDay = moment(date).date()

        const endDate = moment(date).add(1, 'day').toDate()
        const endYear = moment(endDate).year()
        const endMonth = moment(endDate).month() + 1
        const endDay = moment(endDate).date()

        const event = createEvent({
            start: [startYear, startMonth, startDay],
            end: [endYear, endMonth, endDay],
            location: location,
            description: description,
            title: title,
        })

        if (!event.error) {
            downloadICS(title, event.value)
        } else {
            console.error('There was an error creating the iCal event', event.error)
        }
    }

    const downloadICS = (title: string, contents: any) => {
        if (window.Blob) {
            const blob = new window.Blob([contents])
            saveAs(blob, `${title}.ics`)
        } else {
            console.error('window.Blob is unavailable (to download ICS file)')
        }
    }

    const getDisplayText = (engagementEntry: EngagementEntry) => {
        let task = engagementEntry.project
        if (
            (engagementEntry.project === 'Internal' || engagementEntry.project === 'General') &&
            engagementEntry.task &&
            engagementEntry.task.indexOf(' - ') >= 0
        ) {
            task = engagementEntry.task.split(' - ')[1]
        } else if (
            engagementEntry.client === 'Telstra' &&
            engagementEntry.project === 'Internal' &&
            engagementEntry.task
        ) {
            task = engagementEntry.task
        }
        return engagementEntry.isHardBooking
            ? task + ' (' + engagementEntry.hours + ')'
            : '(' + engagementEntry.hours + ') ' + task
    }

    const holiday =
        props.holidays && props.holidays.length > 0
            ? props.holidays.filter(
                  (holiday) => !holiday.territory || holiday.territory === consultant.state
              )[0]
            : null

    if (holiday) {
        const isFullHolidayName = isFullViewType || holiday.name.length <= 17
        const formattedHolidayName = isFullHolidayName
            ? holiday.name
            : holiday.name.substring(0, 16) + '...'
        return (
            <div
                className='booking-container booking-container-holiday'
                style={{
                    height: props.height,
                }}
            >
                <div title={!isFullHolidayName ? holiday.name : ''} className='holiday'>
                    <div className='holiday subText'>{formattedHolidayName}</div>
                    <div className='holiday-state'>{holiday.territory}</div>
                </div>
            </div>
        )
    }

    const engagements = consultant.engagements[props.dateKey]

    const afterEmploymentEndDate =
        consultant.employmentEndDate &&
        moment(dateFromIsoString(consultant.employmentEndDate)).isBefore(date)

    const isUnavailable = !props.isWeekSpacer && (!engagements || afterEmploymentEndDate)

    if (!engagements) {
        return (
            <div
                className={classnames({
                    unavailable: isUnavailable,
                })}
                style={{
                    height: props.height,
                }}
            />
        )
    }

    const hasAvailableHours =
        engagements && !engagements.entries[0] && engagements.availableHours > 0

    const availableHoursText = hasAvailableHours
        ? 'Available Hours: ' + engagements.availableHours
        : ''
    const totalHours = engagements.entries.reduce((sum, current) => sum + current.hours, 0)
    const isOverBooked =
        consultant.name && !engagements.isTimesheet && totalHours > consultant.workHoursPerDay

    const isEngagementNetSuite = (engagementEntry: EngagementEntry) => {
        return engagementEntry.source.toLowerCase() === SourceToggleSource.NetSuite.toLowerCase()
    }

    const engagementWithoutNetSuite = engagements.entries.filter(
        (engagementEntry: EngagementEntry, i: number) => !isEngagementNetSuite(engagementEntry)
    )
    const visibleEntriesLength = props.showNetSuite
        ? engagements.entries.length
        : engagementWithoutNetSuite.length

    const cellHeight = Math.round(
        props.height / (visibleEntriesLength > 0 ? visibleEntriesLength : 1)
    )

    return (
        <div
            title={availableHoursText}
            className={classnames('booking-container', {
                overbooked: isOverBooked,
                unavailable: isUnavailable,
            })}
            style={{
                flexDirection: isFullViewType ? 'column' : 'row',
            }}
        >
            {engagements.entries.map((engagementEntry: EngagementEntry, i: number) => (
                <div
                    key={i}
                    className={classnames('booking', engagementEntry.source.toLowerCase(), {
                        'soft-booking': !engagementEntry.isHardBooking,
                        'booking-dense': engagements.entries.length >= (isFullViewType ? 4 : 2),
                        'not-in-search': engagementEntry.entryMatchesSearch === false,
                        'hide-netsuite':
                            isEngagementNetSuite(engagementEntry) && !props.showNetSuite,
                    })}
                    style={{
                        backgroundColor: engagementEntry.backgroundColor,
                        color: engagementEntry.foregroundColor,
                        height: isFullViewType ? cellHeight : props.height,
                    }}
                    onDoubleClick={() => getICS(consultant, engagementEntry)}
                >
                    {props.isColourBlindMode && (
                        <div className='color-blind-icon'>
                            <span style={{ color: engagementEntry.foregroundColor }}>
                                {engagementEntry.isHardBooking &&
                                    engagementEntry.project !== 'Sales' &&
                                    engagementEntry.project !== 'Leave' && (
                                        <i className='fa fa-square' aria-hidden='true' />
                                    )}
                                {!engagementEntry.isHardBooking &&
                                    engagementEntry.project !== 'Sales' &&
                                    engagementEntry.project !== 'Leave' && (
                                        <i className='fa fa-play' aria-hidden='true' />
                                    )}
                                {engagementEntry.project === 'Sales' && (
                                    <i className='fa fa-star' aria-hidden='true' />
                                )}
                                {engagementEntry.project === 'Leave' && (
                                    <i className='fa fa-circle' aria-hidden='true' />
                                )}
                            </span>
                        </div>
                    )}
                    <div>
                        <i
                            style={{ paddingRight: 5 }}
                            className='source-icon'
                            title={engagementEntry.source}
                            aria-hidden='true'
                        >
                            &nbsp;
                        </i>
                        {engagements.isTimesheet && (
                            <i
                                style={{ paddingRight: 5 }}
                                className={classnames('fa fa-check-square', {
                                    unapproved: !engagements.isCompleted,
                                })}
                                aria-hidden='true'
                            />
                        )}
                        {engagementEntry.pdPlanType === ' [Planned]' && (
                            <i
                                style={{ paddingRight: 5 }}
                                className={classnames('fa fa-calendar-check-o')}
                                aria-hidden='true'
                            />
                        )}
                        {consultant.squad &&
                            engagementEntry.programManagerSquad &&
                            consultant.squad !== 'Unassigned' &&
                            engagementEntry.programManagerSquad !== 'Unassigned' &&
                            consultant.squad !== engagementEntry.programManagerSquad &&
                            engagementEntry.client !== 'Readify Limited' &&
                            !(
                                engagementEntry.client === 'Telstra' &&
                                engagementEntry.project === 'Internal'
                            ) && (
                                <i
                                    style={{ paddingRight: 5 }}
                                    className='fa fa-plane'
                                    aria-hidden='true'
                                />
                            )}
                        <EngagementTooltip
                            consultant={consultant}
                            engagementEntry={engagementEntry}
                            budget={props.projectBudgets?.find(
                                (p) => p.projectId === engagementEntry.projectId
                            )}
                            displayText={getDisplayText(engagementEntry)}
                            openBudgetAdjustmentCalculator={props.openBudgetAdjustmentCalculator}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}
